<template>
  <div v-if="display" class="toastBG">
    {{ message }}
  </div>
</template>

<script>
export default {
    props: {
        message: { type: String, required: true },
        display: { type: Boolean, required: true }
    },
}
</script>


<style lang="scss" scoped>
    @import '@/assets/scss/variable.scss'; 
    @import '@/assets/scss/component.scss'; 

</style>