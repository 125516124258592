<template>
  <div v-if="qscData" class="flex_col scroll">
    <!-- QSC점검표 -->
    <QSCStoreInfo :data="qscData.store"></QSCStoreInfo>
    <QSCInspectionLists :data="qscData"></QSCInspectionLists>
  </div>
</template>

<script>
import QSCStoreInfo from '@/components/qsc/QSCStoreInfo.vue';
import QSCInspectionLists from '@/components/qsc/QSCInspectionLists.vue';
import { getInspectionQAList, getInspectionResultDetail } from '@/api/StoreAPI';

export default {
  components: {
    QSCStoreInfo,
    QSCInspectionLists,
  },
  data() {
    return {
      id: this.$route.params.id,
      storeId: this.$route.params.storeId,
      reportTemplateId: this.$route.params.reportTemplateId,
      qscData: null,
    };
  },
  computed: {
    params() {
      let parameter = Object.entries({
        storeId: this.storeId,
        reportTemplateId: this.reportTemplateId,
      })
        .map((e) => e.join('='))
        .join('&');
      return parameter;
    },
  },
  methods: {
    getQSCReult() {
      getInspectionResultDetail(this.id)
        .then((res) => {
          if (res.data.isSuccessful) {
            // console.log("점검결과",res.data.result);
            this.qscData = res.data.result;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getQuestionList() {
      getInspectionQAList(this.params)
        .then((res) => {
          if (res.data.isSuccessful) {
            // console.log("점검표불러오기",res.data.result);
            this.qscData = res.data.result;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    // console.log(this.$route)
    if (this.$route.name === 'qsc-inspection') {
      this.getQuestionList();
    } else {
      this.getQSCReult();
      window.scrollTo(0, 0);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
@import '@/assets/scss/qsc.scss';
</style>
