<template>
  <div class="flex_col scroll">
    <!-- 알림 있을 경우 -->
    <template v-if="alarmList">
      <div style="padding: 16px 0">
        <div
          v-for="data in alarmList"
          :key="data.id"
          class="flex_row wrap"
          @click="checkAlarm(data)"
        >
          <div class="imageWrap">
            <div v-if="data.isCheck === 0" class="noRead"></div>
            <img src="@/assets/notification.svg" alt="" width="24px" />
          </div>
          <div class="flex_col" style="width: 100%">
            <div class="flex_row flex_between" style="margin-bottom: 4px">
              <div class="fontStyle1" :class="{ read: data.isCheck === 1 }">
                {{ data.title }}
              </div>
              <div class="fontStyle3">{{ data.createAt.slice(0, 10) }}</div>
            </div>
            <div class="fontStyle2" :class="{ read: data.isCheck === 1 }">
              {{ data.content }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 알림 없을 경우 -->
    <template v-else>
      <div class="flex_col" style="margin-top: 143px; align-items: center">
        <img src="@/assets/alarm.svg" alt="" style="width: 32px" />
        <div class="errorInfoFont1">새로운 알림이 없습니다.</div>
      </div>
    </template>
  </div>
</template>

<script>
import { getAlarmList, putIsAlarm } from "@/api/DocumentAPI";

export default {
  name: "alarm-page",
  components: {},
  props: {},
  data() {
    return {
      isRead: false,
      curPage: 1,
      alarmList: null,
    };
  },
  methods: {
    getAlarm() {
      getAlarmList(this.curPage)
        .then((res) => {
          if (res.data.isSuccessful) {
            if (res.data.result.length > 0) {
              this.alarmList = res.data.result;
            }
            // console.log(res.data.result)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkAlarm(data) {
      putIsAlarm(data.id)
        .then((res) => {
          if (res.data.isSuccessful) {
            // console.log(res.data)
            if (data.title === "미점검자 안내") {
              this.$router.push({
                path: `/inspection/report`,
              });
            } else if (data.title === "위생교육 미등록자 안내") {
              this.$router.push({
                path: `/learning`,
              });
            } else if (data.title === "산업안전교육 미등록자 안내") {
              this.$router.push({
                path: `/learning`,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getAlarm();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
.alarmTitle {
  padding: 12px 16px;
  color: $black100;
  font-size: $fs20;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -2%;
  margin-bottom: 8px;
}
.fontStyle1 {
  color: $black100;
  font-size: $fs14;
  font-weight: 600;
  line-height: 16.8px;
  letter-spacing: -2%;
}
.fontStyle2 {
  color: $black70;
  font-size: $fs14;
  font-weight: 500;
  line-height: 20.72px;
  letter-spacing: -1.3%;
}
.fontStyle3 {
  color: $black32;
  font-size: $fs10;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -2%;
}
.wrap {
  border-bottom: 1px solid $black8;
  padding: 16px;
  align-items: flex-start;
}
.imageWrap {
  position: relative;
  margin-right: 12px;
}
.noRead {
  position: absolute;
  background-color: $accent1;
  width: 8px;
  height: 8px;
  left: 18px;
  border-radius: 100%;
}
.read {
  color: $black32;
}
</style>
