<template>
  <div id="page">
    <DetailHeader />
    <Rview />
  </div>
</template>

<script>
import DetailHeader from "@/components/global/DetailHeader";
import Rview from "@/components/routerView/Index";

export default {
  name: "DetailLayout",
  components: { Rview, DetailHeader },
};
</script>
<style scoped>
#page {
  display: flex;
  flex-direction: column;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  /* overflow-y: hidden; */
}
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  #page {
    max-height: -webkit-fill-available;
  }
}
@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  #page {
    height: 100vh;
    max-height: calc(100vh);
    box-sizing: border-box;
  }
}
</style>
