<template>
  <div v-if="data && data.length > 0" class="flex_col" style="gap: 8px">
    <div
      v-for="noti in data"
      :key="noti.id"
      class="mainNotiList"
      @click="
        $router.push({
          path: `/notice/${noti.id}`,
          params: {
            id: noti.id,
          },
        })
      "
    >
      <div class="flex_row flex_between">
        <div class="titleStyle">
          {{ noti.title }}
        </div>
        <img
          src="@/assets/arrow3.svg"
          alt="move"
          style="width: 20px; height: 20px; margin-left: 4px"
        />
      </div>
      <div class="dateStyle">
        {{ noti.updateAt.slice(0, 16) }}
        {{ noti.updateAt.slice(19) }}
      </div>
    </div>
  </div>
  <div v-else class="errorInfoFont1" style="padding: 16px">
    등록된 공지사항이 없습니다.
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Array, require: true },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
.mainNotiList {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
}
.titleStyle {
  color: $black100;
  font-size: $fs16;
  font-weight: 500;
  letter-spacing: -0.013em;
}
.dateStyle {
  color: $black32;
  font-size: $fs10;
  font-weight: 400;
  letter-spacing: -2%;
  margin-top: 4px;
}
</style>
