<template>
  <div class="flex_col" style="width: 100%; padding: 16px 16px 80px 16px">
    <div class="flex_row flex_between" style="gap: 12px; margin-bottom: 2px">
      <div style="width: 100%; position: relative">
        <input
          class="searchInputStyle"
          type="text"
          placeholder="지점명, 점검표 이름, 점검자 이름"
          :value="keyword"
          @input="updateInput($event.target.value)"
        />
        <img
          src="@/assets/search.svg"
          alt="search"
          style="width: 16px; position: absolute; top: 10px; left: 16px"
        />
        <img
          v-if="keyword.length > 0"
          src="@/assets/remove.svg"
          alt="search"
          style="width: 18px; position: absolute; top: 10px; right: 16px"
          @click="keyword = ''"
        />
      </div>
      <img
        src="@/assets/filter.svg"
        alt="filter"
        width="37px"
        height="37px"
        @click="isFilter = !isFilter"
      />
    </div>
    <div
      v-if="
        storeFilter &&
        (storeFilter.startDate !== '' ||
          storeFilter.endDate !== '' ||
          storeFilter.type.length !== 0 ||
          storeFilter.grade.length !== 0)
      "
      class="flex_row_slide"
      style="margin-top: 16px"
    >
      <div
        class="tagStyle"
        v-if="
          storeFilter.startDate.length !== 0 && storeFilter.endDate.length !== 0
        "
        style="min-height: 33px; max-height: 33px"
      >
        {{ storeFilter.startDate }} ~ {{ storeFilter.endDate }}
        <img
          src="@/assets/close5.svg"
          alt=""
          style="width: 16px; margin-left: 4px"
          @click="deleteFilter('date')"
        />
      </div>
      <div
        v-if="storeFilter.type.length > 0"
        class="tagStyle"
        style="min-height: 33px; max-height: 33px"
      >
        {{
          storeFilter.type.length > 1
            ? storeFilter.type[0] + ' 외 1'
            : storeFilter.type[0]
        }}
        <img
          src="@/assets/close5.svg"
          alt=""
          style="width: 16px; margin-left: 4px"
          @click="deleteFilter(index, 'type')"
        />
      </div>
      <div
        v-if="storeFilter.grade.length > 0"
        class="tagStyle"
        style="min-height: 33px; max-height: 33px"
      >
        {{
          storeFilter.grade.length === 1
            ? storeFilter.grade[0]
            : storeFilter.grade[0] +
              ' 외 ' +
              Number(storeFilter.grade.length - 1)
        }}
        <img
          src="@/assets/close5.svg"
          alt=""
          style="width: 16px; margin-left: 4px"
          @click="deleteFilter(index, 'grade')"
        />
      </div>
    </div>
    <div
      class="flex_row flex_between"
      style="margin-top: 16px; margin-bottom: 16px"
    >
      <div class="resultInfoFont">
        총 {{ totalCnt }}개 리포트가 조회되었습니다.
      </div>
      <div
        class="flex_row"
        style="align-items: center"
        @click="isArray = !isArray"
      >
        <div class="filterSelectFont">{{ orderBy }}</div>
        <img src="@/assets/arrowdown.svg" alt="" style="width: 16px" />
      </div>
    </div>
    <div
      v-if="listData.length === 0"
      class="flex_col"
      style="width: 100%; height: 100%; align-items: center"
    >
      <div class="flex_col" style="margin-top: 143px; align-items: center">
        <img src="@/assets/error-not.svg" alt="" style="width: 32px" />
        <div class="errorInfoFont1">검색결과가 없습니다.</div>
        <div class="errorInfoFont2">
          검색어를 정확하게 입력했는지 확인해주세요.
        </div>
      </div>
    </div>
    <div v-else>
      <InspectionList :data="listData"></InspectionList>
    </div>
    <BottomOptionFilter2
      :filterState="isFilter"
      :startDate="startDate"
      :endDate="endDate"
      :qscType="type"
      :qscGrade="categoryGrade"
      @changeDisplay="getFilterState"
      @selectFilter="getSelectedFilterData"
    ></BottomOptionFilter2>
    <BottomOptionList
      type="정렬2"
      :displayState="isArray"
      @changeOption="updateArray"
      @changeDisplay="getArrayState"
    ></BottomOptionList>
  </div>
</template>

<script>
import InspectionList from '@/components/inspection/InspectionList.vue';
import BottomOptionFilter2 from '@/components/global/option/BottomOptionFilter2.vue';
import BottomOptionList from '@/components/global/option/BottomOptionList.vue';
import { getInspectionList } from '@/api/StoreAPI';

export default {
  name: 'store-list',
  components: {
    InspectionList,
    BottomOptionFilter2,
    BottomOptionList,
  },
  props: {},
  data() {
    return {
      isArray: false,
      isFilter: false,
      curPage: 1,
      lastPage: 1,
      keyword: '',
      startDate: '',
      endDate: '',
      categoryType: '',
      type: [],
      categoryGrade: [],
      totalCnt: 0,
      listData: [],
      selectedFilterData: [],
    };
  },
  computed: {
    params() {
      let parameter = Object.entries({
        curPage: this.curPage,
        keyword: this.keyword,
        orderBy: this.orderBy,
        isShow: '1',
        categoryDateStart: this.startDate,
        categoryDateEnd: this.endDate,
        categoryType: this.categoryType,
      })
        .map((e) => e.join('='))
        .join('&');
      if (this.categoryGrade.length > 0) {
        for (var i = 0; i < this.categoryGrade.length; i++) {
          parameter = `${parameter}&categoryGrade=${this.categoryGrade[i]}`;
        }
        return parameter;
      } else {
        parameter = `${parameter}&categoryGrade=`;
        return parameter;
      }
    },
    orderBy() {
      return this.$store.getters.getDocFilterData;
    },
    storeFilter() {
      return this.$store.getters.getInspectionFilter;
    },
  },
  methods: {
    updateInput(value) {
      this.keyword = value;
    },
    getSelectedFilterData(startDate, endDate, type, grade) {
      this.startDate = startDate;
      this.endDate = endDate;
      this.type = type;
      if (type.length === 1) {
        if (type[0] === '본사 점검표') {
          this.categoryType = 1;
        } else {
          this.categoryType = 0;
        }
      } else {
        this.categoryType = '';
      }
      this.categoryGrade = grade;
      this.$store.dispatch('setInspectionFilter', {
        startDate: startDate,
        endDate: endDate,
        type: type,
        grade: grade,
      });
      this.getReportList();
    },
    getFilterState(value) {
      this.isFilter = value;
    },
    getReportList() {
      // TODO 무한스크롤 필요 BE 변경 필요
      getInspectionList(this.params)
        .then((res) => {
          // console.log("InspectionList",res.data.result)
          this.listData = res.data.result.list;
          this.totalCnt = res.data.result.totalCnt;
          this.lastPage = Math.ceil(Number(res.data.result.totalCnt) / 10);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getArrayState(value) {
      this.isArray = value;
    },
    updateArray(value) {
      this.$store.dispatch('updateDocFilter', value);
      this.orderBy = value;
      this.isArray = false;
    },
    deleteFilter(index, type) {
      if (type === 'type') {
        this.type = [];
        // this.type.splice(index,1)
      } else if (type === 'grade') {
        // this.categoryGrade.splice(index,1)
        this.categoryGrade = [];
      } else {
        this.startDate = '';
        this.endDate = '';
      }
      if (this.type.length === 1) {
        if (this.type[0] === '본사 점검표') {
          this.categoryType = 1;
        } else {
          this.categoryType = 0;
        }
      } else {
        this.categoryType = '';
      }
      this.$store.dispatch('setInspectionFilter', {
        startDate: this.startDate,
        endDate: this.endDate,
        type: this.type,
        grade: this.categoryGrade,
      });
      this.getReportList();
    },
    updateStoreFilterData() {
      this.$store.dispatch('setInspectionFilter', {
        startDate: this.startDate,
        endDate: this.endDate,
        type: this.type,
        grade: this.categoryGrade,
      });
      this.getReportList();
    },
  },
  created() {
    if (this.$route.hash) {
      let hash = decodeURI(this.$route.hash);
      if (hash.slice(2) === '등급') {
        this.categoryGrade = [hash.slice(1)];
        this.selectedFilterData = [hash.slice(1)];
      } else {
        this.keyword = hash.slice(1);
      }
    }
    if (this.storeFilter) {
      this.startDate = this.storeFilter.startDate;
      this.endDate = this.storeFilter.endDate;
      this.type = this.storeFilter.type;
      if (this.storeFilter.type.length === 1) {
        if (this.storeFilter.type[0] === '본사 점검표') {
          this.categoryType = 1;
        } else {
          this.categoryType = 0;
        }
      } else {
        this.categoryType = '';
      }
      this.categoryGrade = this.storeFilter.grade;
    }
    this.getReportList();
  },
  watch: {
    curPage: {
      handler: function () {
        this.getReportList();
      },
    },
    orderBy: {
      handler: function () {
        this.getReportList();
      },
    },
    keyword: {
      handler: function () {
        this.getReportList();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.fontStyle2 {
  color: $black28;
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-top: 8px;
}
.infoStyle {
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 8px 16px 8px 10px;
  color: $black100;
  font-size: $fs10;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -2%;
  margin-top: auto;
  margin-bottom: 60px;
}
.tagStyle {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-width: fit-content;
  height: 33px;
  padding: 6px 10px;
  color: $accent1;
  font-size: $fs14;
  font-weight: 500;
  border-radius: 100px;
  border: none;
  background-color: $accent2;
}
</style>
