<template>
  <div class="BG">
    <div class="bottomOptionBG">
        <div class="handle"></div>
        <!-- <div class="weeks"></div> -->
      <Calendar
      trim-weeks
        class="calenderStyle"
        v-model="currentDate"
        :attributes="attrs"
        :select-attribute="attrs"
        :masks="masks"
        @dayclick="onDayClick"
        >
      </Calendar>
      <button v-if="$route.name!=='inspection-list'" class="baseBtn fill" @click="onSubmitAction">적용</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Calendar } from 'v-calendar';

export default {
  components: {
    Calendar,
  },
  props: {
    isDateView: { type: Boolean, required: true, default: false },
    workAt: { type: String, required: true, default: '' },
  },
  data (){
  return {
    attrs: [
        {
          key: 'selectDay',
          // dot: {
          //   color: 'red'
          // },
          // highlight: {
          //   color: 'blue',
          //   fillMode: 'light',
          // },
          content: 'blue',
          dates: new Date(this.currentDate),
        },
        {
          key: 'sunday',
          content: 'pink', 
          dates: {
            start: new Date(2022, 0, 1),  
            end: new Date(2050, 0, 1),  
            weekdays: [1]
          },
        },
      ],
  }
},
  watch: {
    workAt: {
        //handler: function(바뀐값,바뀌기전 값)
        handler: function () {
          this.currentDate = this.workAt
        }
      },
    },
  
  setup(props,{emit}){
    const currentDate = ref(props.workAt)
    const isDisplay = ref(props.isDateView)
    const onDayClick = (date) => {
      currentDate.value = date.id
      emit('selectedDate', currentDate.value)
    }
    const onSubmitAction = () => {
      emit('changeDate')
    }
    // const attrs = reactive([
    //     {
    //       key: 'selectDay',
    //       highlight: {
    //         color: 'red',
    //         fillMode: 'outline',
    //       },
    //       dates: new Date(currentDate.value),
    //     },
    //     {
    //       key: 'sunday',
    //       content: 'pink', 
    //       dates: {
    //         start: new Date(2022, 0, 1),  
    //         end: new Date(2050, 0, 1),  
    //         weekdays: [1]
    //       },
    //     },
    //   ])
    const masks = ref({
        title: 'YYYY년 MM월',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
        dayPopover: 'WWW, MMM D, YYYY',
        data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
      })
    return{
      currentDate,
      isDisplay,
      // attrs,
      masks,
      onDayClick,
      onSubmitAction,
    }
  }
}
</script>


<style lang="scss" scoped>
  @import '@/assets/scss/variable.scss';
  @import '@/assets/scss/component.scss';
  .calenderStyle {
    margin: auto;
    border: none;
    width: 100%;
  }
  .weeks {
    color: tan;
    width: calc(100% - 15%);
    height: 30px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 72px;
    background-color: $accent2;
    z-index: 5;
  }
  .vc-header {
    margin-bottom: 20px !important;
  }
  .vc-container {
    --blue-900: rgba(115,0,1,1);
    --red-900: rgba(115,0,1,1);
    --pink-900: rgba(251,94,90,1);
    --gray-500: rgba(0,0,0,1);
    --font-semibold: 500;
    --font-medium: 400;
    --font-bold: 400;
  }
  .vc-container:focus, .vc-container :focus {
    border: 1px solid $burgundy !important;
    background-color: white !important;
    color: $burgundy;
  }
</style>