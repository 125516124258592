<template>
  <div v-if="storeData" class="flex_col scroll">
    <div class="flex_col storeInfoCard">
      <div
        class="flex_row"
        style="margin-bottom: 4px; gap: 8px; align-items: center"
      >
        <span class="fontStyle1">{{ storeData.topInfo.storeName }}</span>
        <span class="fontStyle2">{{ storeData.topInfo.storeCode }}</span>
      </div>
      <div class="personStyle">{{ storeData.topInfo.storeManagerName }}</div>
      <p class="fontStyle3" style="margin-bottom: 20px">
        {{ storeData.topInfo.storeAddr }}
      </p>
      <div class="flex_row flex_between" style="margin-bottom: 20px">
        <div class="flex_row" style="gap: 6px">
          <div class="tagStyle">{{ storeData.topInfo.storeLine }}</div>
          <div class="tagStyle">{{ storeData.topInfo.storeTeam }}</div>
        </div>
        <!-- <div class="baseBtn line callBtn" @click="isCall=!isCall">
                    <img src="@/assets/call-icon.svg" alt="call" style="width:14px;margin-right:5px"/>
                    통화하기
                </div>
                <BottomOptionCall :display="isCall" :number="storeData.topInfo.storeTel" @changeDisplay="getCallDisplay"></BottomOptionCall> -->
      </div>
      <button
        type="submit"
        class="baseBtn fill"
        @click.prevent="
          $router.push({
            path: `/inspection/report`,
            hash: `#${storeData.topInfo.storeName}`,
          })
        "
      >
        리포트 이동
      </button>
    </div>
    <StoreOptionButton
      type="detail"
      :selected="isParam"
      @changeOption="getOptionState"
    ></StoreOptionButton>
    <!-- option에 따라 아래 항목 render -->
    <StoreDetailQSC
      v-if="isParam === 'qsc'"
      :data="storeData.qscInfo"
    ></StoreDetailQSC>
    <StoreCleanEdu
      v-else-if="isParam === 'clean'"
      :data="storeData.hygieneInfo"
    ></StoreCleanEdu>
    <StoreSafetyEdu
      v-else-if="isParam === 'safety'"
      :data="storeData.safetyInfo"
    ></StoreSafetyEdu>
    <StoreFeedback
      v-else-if="isParam === 'feedback'"
      :data="storeData.feedbackInfo"
    ></StoreFeedback>
  </div>
</template>

<script>
// import ButtonBasic from "@/components/global/inputs/ButtonBasic.vue";
import StoreOptionButton from '@/components/global/option/StoreOptionButton.vue';
import StoreDetailQSC from '@/components/store/StoreDetailQSC.vue';
// import BottomOptionCall from '@/components/global/option/BottomOptionCall.vue'
import StoreCleanEdu from '@/components/store/StoreCleanEdu.vue';
import StoreSafetyEdu from '@/components/store/StoreSafetyEdu.vue';
import StoreFeedback from '@/components/store/StoreFeedback.vue';
import { getStoreDetail } from '@/api/StoreAPI';

export default {
  name: 'store-detail',
  components: {
    // BottomOptionCall,
    // ButtonBasic,
    StoreOptionButton,
    StoreDetailQSC,
    StoreCleanEdu,
    StoreSafetyEdu,
    StoreFeedback,
  },
  props: {},
  data() {
    return {
      id: null,
      storeName: null,
      storeData: null,
      isCall: false,
      isParam: 'qsc',
    };
  },
  methods: {
    getCallDisplay(value) {
      this.isCall = value;
    },
    getOptionState(value) {
      this.isParam = value;
    },
    getDetailData() {
      getStoreDetail(this.id)
        .then((res) => {
          if (res.data.isSuccessful) {
            // console.log("storeDetailComp",res.data.result)
            this.storeData = res.data.result;
            this.storeNmae = res.data.result.topInfo.storeName;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    dataList: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        this.storeList = this.dataList;
      },
    },
  },
  created() {
    this.id = this.$route.params.id;
    // console.log(this.id)
    this.getDetailData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.storeInfoCard {
  padding: 18px 16px;
  background-color: $white100;
  margin-bottom: 8px;
}
.fontStyle1 {
  color: $black100;
  font-size: $fs16;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: -1.3%;
}
.fontStyle2 {
  color: $black32;
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -1.3%;
}
.fontStyle3 {
  color: $black70;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.personStyle {
  color: $black100;
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-bottom: 8px;
}
.tagStyle {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid $black8;
  color: $black32;
  padding: 6px 10px;
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.callBtn {
  width: fit-content;
  height: fit-content;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: $burgundy;
  padding: 6px 10px;
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -1.3%;
  border: 1px solid $burgundy;
}
</style>
