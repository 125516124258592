<template>
  <div
    v-if="state"
    class="BG"
    @click.prevent="
      changeDisplay();
      resetData();
    "
  >
    <div class="bottomOptionBG">
      <div class="handle"></div>
      <div class="flex_col">
        <div class="flex_row flex_between" style="margin-bottom: 16px">
          <h3 class="fontStyle1">필터</h3>
          <div class="fontStyle2" @click.stop="resetData">초기화</div>
        </div>
        <div>
          <h4 class="fontStyle3" style="margin-bottom: 10px">소속</h4>
          <div
            class="flex_row"
            style="flex-wrap: wrap; gap: 8px; margin-bottom: 28px"
          >
            <div
              v-for="data in filterData"
              :key="data.id"
              class="tagStyle"
              :class="{
                tagActive: selected.findIndex((v) => v.id === data.id) > -1,
              }"
              @click.stop="selectFilter(data)"
            >
              {{ data.line }} {{ data.team }}
            </div>
          </div>
        </div>
        <ButtonBasic
          text="적용"
          type="fill"
          :isDisable="false"
          :clickEvent="submitClick"
        ></ButtonBasic>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBasic from '../inputs/ButtonBasic.vue';

export default {
  props: {
    filterState: { type: Boolean, required: true },
    filterList: { type: Array, required: false },
    selectedFilter: { type: Array, required: false },
  },
  components: {
    ButtonBasic,
  },
  data() {
    return {
      state: this.filterState,
      filterData: this.filterList,
      selected: this.selectedFilter,
      isDateView: false,
      filterStartDate: this.startDate,
      filterEndDate: this.endDate,
    };
  },
  methods: {
    changeDisplay() {
      this.state = false;
      this.$emit('changeDisplay', false);
    },
    resetData() {
      this.selected = [];
    },
    selectFilter(data) {
      let arr = [...this.selected];
      if (arr.length === 0) {
        arr.push(data);
      } else {
        if (arr.findIndex((v) => v.id === data.id) > -1) {
          arr.splice(
            arr.findIndex((v) => v.id === data.id),
            1
          );
        } else {
          arr.push(data);
        }
      }
      this.selected = arr;
    },
    submitClick() {
      this.$emit('selectFilter', [...this.selected]);
    },
  },
  watch: {
    filterList: {
      handler: function () {
        this.filterData = this.filterList;
      },
    },
    filterState: {
      handler: function () {
        this.state = this.filterState;
        if (this.filterState) {
          this.selected = [...this.selectedFilter];
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.fontStyle1 {
  font-size: $fs20;
  font-weight: 600;
  line-height: 26.4px;
  color: $black100;
  letter-spacing: -1.3%;
  margin: 0;
}
.fontStyle2 {
  font-size: $fs13;
  font-weight: 600;
  line-height: 15.6px;
  color: $accent1;
  letter-spacing: -2%;
  padding: 5px 0;
}
.fontStyle3 {
  font-size: $fs16;
  font-weight: 500;
  line-height: 19px;
  color: $black100;
  letter-spacing: -0.013em;
}
.tagStyle {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 6px 10px;
  color: $black80;
  font-size: $fs14;
  font-weight: 500;
  border-radius: 100px;
  border: 1px solid $black8;
}
.tagActive {
  display: flex;
  align-items: center;
  color: $accent1;
  border: 1px solid $accent2;
  background-color: $accent2;
}
</style>
