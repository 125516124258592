var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex_col scroll",attrs:{"id":"scroll"},on:{"scroll":function($event){return _vm.scrollHandler()}}},[(_vm.getData.length > 0)?_c('div',{staticStyle:{"padding-bottom":"150px","height":"fit-content"}},_vm._l((_vm.getData),function(data){return _c('div',{key:data.id,staticClass:"flex_row flex_between wrap",on:{"click":function($event){return _vm.$router.push({
          path: `/notice/${data.id}`,
          params: {
            id: data.id,
          },
        })}}},[_c('div',{staticClass:"flex_col",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"flex_row flex_between"},[_c('div',{staticClass:"fontStyle1"},[_vm._v(_vm._s(data.title))]),_c('img',{attrs:{"src":require("@/assets/arrow3.svg"),"alt":"","width":"20px"}})]),_c('div',{staticClass:"fontStyle2"},[_vm._v(" "+_vm._s(data.updateAt.slice(0, 16))+_vm._s(data.updateAt.slice(19))+" ")])])])}),0):_c('div',[_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex_col",staticStyle:{"margin-top":"143px","align-items":"center"}},[_c('img',{staticStyle:{"width":"32px"},attrs:{"src":require("@/assets/error-not.svg"),"alt":""}}),_c('div',{staticClass:"errorInfoFont1"},[_vm._v("공지사항이 없습니다.")])])
}]

export { render, staticRenderFns }