import Axios from 'axios';

Axios.defaults.baseURL = 'https://qscfs.bongroup.co.kr/api/app/';
// Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');

const instance = Axios.create({
  withCredentials: true,
  connection: 'keep-alive',
});
instance.interceptors.request.use((config) => {
  config.headers.Authorization = localStorage.getItem('token');
  config.headers['Access-Control-Allow-Headers'] = '*';
  config.headers['Access-Control-Allow-Origin'] = '*'; // CORS 설정(모든 리소스 허용)
  return config;
});
instance.interceptors.response.use((response) => {
  if (!response.data.isSuccessful) {
    if (
      response.data.msg === 'JWT이 없습니다.' ||
      response.data.msg === '잘못된 JWT 서명입니다.' ||
      response.data.msg === '만료된 JWT 입니다.' ||
      response.data.msg === '지원되지 않는 JWT 입니다.' ||
      response.data.msg === '신뢰할 수 없는 JWT입니다.'
    ) {
      alert(`${response.data.msg} 다시 로그인해주세요.`);
      const fcmToken = localStorage.getItem('fcmToken');
      localStorage.clear();
      if (fcmToken) {
        localStorage.setItem('fcmToken', fcmToken);
      }
      window.location.replace('/login-page');
    } else {
      return response;
    }
  } else {
    return response;
  }
});

export default instance;
