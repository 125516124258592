<template>
  <div v-if="this.detailData">
    <div class="flex_col scroll" style="padding:16px">
      <div style="padding-bottom:24px">
        <BottomDropDown type="카테고리를" name="certificate" disabled :value="category"></BottomDropDown>
      </div>
      <BasicInput title="제목" placeholder="예) 2022년 11월 본그룹본사점 영양사식품위생교육" :propValue="detailData.title" disabled></BasicInput>
      <BasicInput title="교육이수자" placeholder="예) 김본죽" :propValue="detailData.student" disabled></BasicInput>
      <div class="titleFont">수료일</div>
      <div class="relative">
        <div class="basicInputStyle disabled" type="date" :placeholder="placeholder"></div>
        <div class="completeAt">{{ this.detailData.completeAt }}</div>
      </div>
      <div v-if="this.detailData.fileLinks" class="flex_col">
        <div class="titleFont">첨부 파일</div>
        <div v-if="this.detailData.fileLinks.length > 0">
          <div v-for="file in this.detailData.fileLinks" :key="file.id" class="basicInputStyle" style="justify-content: space-between;align-items: center;margin-bottom:8px">
            <div class="ellipsis">{{ file.fileName }}</div>
            <div class="flex_row" style="gap:8px">
              <a :href="file.fileLink" download="">
                  <img src="@/assets/load.svg" alt="download" style="width:16px;padding-left:16px"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottomFixedBtn" @click="isShowAlert=true">
      삭제하기
    </div>
    <CommonAlertTwoBtn :state="isShowAlert" @changeDisplay="getAlertDisplayState" @confirmAction1="confirmAction1" @confirmAction2="confirmAction2">
        <template #title>
          {{ alertTitle }}
        </template>
        <template #content1>
          {{ alertContent1 }}
        </template>
        <template #content2>
          {{ alertContent2 }}
        </template>
        <template #btn1>
          {{ alertBtn1 }}
        </template>
        <template #btn2>
          {{ alertBtn2 }}
        </template>
      </CommonAlertTwoBtn>
      <ToastMsg :message="toastMassage" :display="toastDisplay"></ToastMsg>
  </div>
</template>
 
<script>
  import BottomDropDown from "@/components/global/option/BottomDropDown.vue"
  import BasicInput from "@/components/global/inputs/BasicInput.vue"
  import { getCertificateDetail, delCertificate } from "@/api/EducationAPI"
  import CommonAlertTwoBtn from "@/components/global/alert/CommonAlertTwoBtn.vue"
  import ToastMsg from "@/components/global/alert/ToastMsg.vue"

  export default {
    name: 'certificate-add-page',
    components: {
      BottomDropDown,
      BasicInput,
      CommonAlertTwoBtn,
      ToastMsg,
    },
    data(){
      return {
        id: null,
        detailData: null,
        category: null,
        isShowAlert: false,
        alertTitle:'교육일지 삭제',
        alertContent1:'한번 삭제된 교육일지는 복구할 수 없습니다.',
        alertContent2:'정말로 삭제하시겠습니까?',
        alertBtn1:'취소',
        alertBtn2:'삭제',
        toastDisplay: false,
        toastMassage: '삭제되었습니다'
      }
    },
    methods: {
      getDetail(id){
        getCertificateDetail(id)
        .then((res)=>{
          if(res.data.isSuccessful){
            console.log(res.data.result)
            this.detailData = res.data.result
            this.category = res.data.result.category
            console.log(this.category)
          }
        })
        .catch((err)=>{
            console.log(err)
        })
      },
      getAlertDisplayState(value){
          this.isShowAlert = value
      },
      confirmAction1(){
          this.isShowAlert = false;
      },
      confirmAction2(){
        delCertificate(this.id)
          .then((res)=>{
            console.log(res.data)
            if(res.data.isSuccessful){
                this.toastDisplay = true
                this.toastMassage = "삭제되었습니다"
                setTimeout(() => {
                  this.toastDisplay = false
                  this.toastMassage = ""
                  this.$router.replace({name:'certificate-list'})
                }, 1000);
            } else {
                console.log("에러")
            }
          })
          .catch((err)=>{
              console.log(err)
          })
          .finally(()=>{
            this.optionState = null;
          })
        },
    },
    created(){
      this.id = this.$route.params.id
      this.getDetail(this.id)
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variable.scss';
  @import '@/assets/scss/component.scss';
  .calenderIcon {
    width: 18px;
    position: absolute;
    top: 12px;
    right: 16px;
  }
  .titleFont {
    font-size: $fs12;
    font-weight: 500;
    line-height: 14.4px;
    color: $black70;
    letter-spacing: -1.3%;
    margin-bottom: 8px;
  }
  .fontStyle1 {
    font-size: $fs10;
    font-weight: 400;
    line-height: 12px;
    color: $black50;
    letter-spacing: -2%;
  }
  .loadBtn {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    font-size: $fs13;
    font-weight: 600;
    line-height: 15.6px;
    color: $black100;
    letter-spacing: -2%;
    border: 1px solid $black8;
    border-radius: 6px;
  }
  .bottomFixedBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $black8;
    height: 52px;
    width: 100%;
    color: $error;
    font-size: $fs16;
    font-weight: 500;
    line-height: 19.2px;
    letter-spacing: -1.3%;
    background-color: $white100;
    position: fixed;
    bottom: 0;
  }
  .completeAt {
    position: absolute;
    top: 12px;
    left: 16px;
    color: $black100;
    font-size: $fs14;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -1.3%;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;  /* 말줄임 적용 */
  }
  .disabled {
    background-color: rgba(0, 0, 0, 0.04);
  }
</style>