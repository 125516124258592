import Vue from 'vue';
import Router from 'vue-router';

import DefaultLayout from '@/layout/DefaultLayout';
import Home from '@/views/index/MainPage';
import StoreLists from '@/views/store/StoreLists';
import MyStoreLists from '@/views/store/MyStoreLists';
import InspectionLists from '@/views/inspection/InspectionLists';
import Notice from '@/views/index/NoticePage';
import NoticeDetail from '@/views/index/NoticeDetail';
import Alarm from '@/views/index/AlarmPage';
import DocumentList from '@/views/index/DocumentList';
import DocumentDetail from '@/views/index/DocumentDetail';

import DetailLayout from '@/layout/DetailLayout';
import PasswordFind from '@/views/login/PasswordFind';
import PasswordResetting from '@/views/login/PasswordResetting';
import PasswordChange from '@/views/login/PasswordChange';
import MySchedule from '@/views/schedule/MySchedule';
import MyScheduleDetail from '@/views/schedule/MyScheduleDetail';
import MyScheduleEdit from '@/views/schedule/MyScheduleEdit';
import StoreDetail from '@/views/store/StoreDetail';
import InspectionAdd from '@/views/inspection/InspectionAdd';
import InspectionTemporary from '@/views/inspection/InspectionTemporary';
import QSCInspection from '@/views/inspection/QSCInspection';
import LearningLists from '@/views/education/LearningLists';
import LearningAddPage from '@/views/education/LearningAddPage';
import LearningDetail from '@/views/education/LearningDetail';
import CertificateLists from '@/views/education/CertificateLists';
import CertificateAddPage from '@/views/education/CertificateAddPage';
import CertificateDetail from '@/views/education/CertificateDetail';

// import EmptyLayout from "@/layout/EmptyLayout";
// import Splash from "@/views/login/Splash";
import Login from '@/views/login/Login';
import PrivacyPolicy from '@/views/login/PrivacyPolicy';

import store from '@/stores/store';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'defaultLayout',
    redirect: '/login-page',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'main-page',
        meta: {
          title: '메인',
          middleware: 'auth',
        },
        component: Home,
      },
      {
        path: '/store',
        name: 'store-list',
        meta: {
          title: '지점',
          middleware: 'auth',
        },
        component: StoreLists,
      },
      {
        path: '/my-store',
        name: 'my-store-list',
        meta: {
          title: '내 지점',
          middleware: 'auth',
        },
        component: MyStoreLists,
      },
      {
        path: '/inspection/dashboard',
        name: 'inspection-list-dashboard',
        meta: {
          title: '점검',
          middleware: 'auth',
        },
        component: InspectionLists,
      },
      {
        path: '/inspection/report',
        name: 'inspection-list-report',
        meta: {
          title: '점검',
          middleware: 'auth',
        },
        component: InspectionLists,
      },
      {
        path: '/document',
        name: 'document-list',
        meta: {
          title: '문서',
          middleware: 'auth',
        },
        component: DocumentList,
      },
      {
        path: '/notice',
        name: 'notice-list',
        meta: {
          title: '공지사항',
        },
        component: Notice,
      },
      {
        path: '/my-schedule',
        name: 'my-schedule',
        meta: {
          title: '나의 일정',
          middleware: 'auth',
        },
        component: MySchedule,
      },
      {
        path: '/certificate',
        name: 'certificate-list',
        meta: {
          title: '수료증',
          middleware: 'auth',
        },
        component: CertificateLists,
      },
      {
        path: '/learning',
        name: 'learning-list',
        meta: {
          title: '본러닝',
          middleware: 'auth',
        },
        component: LearningLists,
      },
    ],
  },
  {
    path: '/',
    name: 'detailLayout',
    redirect: '/',
    component: DetailLayout,
    children: [
      {
        path: '/password-find',
        name: 'password-find',
        component: PasswordFind,
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        component: PasswordResetting,
      },
      {
        path: '/password-change',
        name: 'password-change',
        component: PasswordChange,
      },
      {
        path: '/my-schedule/add',
        name: 'my-schedule-add',
        meta: {
          title: '등록하기',
          middleware: 'auth',
        },
        component: MyScheduleEdit,
      },
      {
        path: '/my-schedule/:id',
        name: 'my-schedule-detail',
        meta: {
          title: '상세 조회',
          middleware: 'auth',
        },
        component: MyScheduleDetail,
      },
      {
        path: '/my-schedule/edit/:id',
        name: 'my-schedule-edit',
        meta: {
          title: '수정하기',
          middleware: 'auth',
        },
        component: MyScheduleEdit,
      },
      {
        path: '/store/:id',
        name: 'store-detail',
        meta: {
          title: '지점 상세정보',
          middleware: 'auth',
        },
        component: StoreDetail,
      },
      {
        path: '/inspection/add',
        name: 'inspaction-add-page',
        meta: {
          // 팀장인경우 : QSC 점검하기
          // 점장인경우 : QSC 자가점검하기
          title: 'QSC 점검하기',
          middleware: 'auth',
        },
        component: InspectionAdd,
      },
      {
        path: '/inspection/temporary',
        name: 'inspaction-temporary-list',
        meta: {
          title: '임시저장 불러오기',
          middleware: 'auth',
        },
        component: InspectionTemporary,
      },
      {
        path: '/qsc',
        name: 'qsc-inspection',
        meta: {
          // 팀장인경우 : QSC 점검하기
          // 점장인경우 : QSC 자가점검하기
          title: 'QSC 점검하기',
          middleware: 'auth',
        },
        component: QSCInspection,
      },
      {
        path: '/qsc/edit/:id',
        name: 'qsc-inspection-edit',
        meta: {
          title: '수정하기',
          middleware: 'auth',
        },
        component: QSCInspection,
      },
      {
        path: '/qsc/temporary/:id',
        name: 'qsc-temporary-edit',
        meta: {
          title: 'QSC 점검하기',
          middleware: 'auth',
        },
        component: QSCInspection,
      },
      {
        path: '/qsc/detail/:id',
        name: 'qsc-inspection-detail',
        meta: {
          title: 'QSC 점검 결과',
          middleware: 'auth',
        },
        component: QSCInspection,
      },
      {
        path: '/qsc/feedback/:id',
        name: 'qsc-inspection-feedback',
        meta: {
          title: '피드백 작성하기',
          middleware: 'auth',
        },
        component: QSCInspection,
      },
      {
        path: '/learning/add',
        name: 'learning-add-page',
        meta: {
          title: '등록하기',
          middleware: 'auth',
        },
        component: LearningAddPage,
      },
      {
        path: '/learning/edit/:id',
        name: 'learning-edit-page',
        meta: {
          title: '수정하기',
          middleware: 'auth',
        },
        component: LearningAddPage,
      },
      {
        path: '/learning/detail/:id',
        name: 'learning-detail',
        meta: {
          title: '상세 조회',
          middleware: 'auth',
        },
        component: LearningDetail,
      },
      {
        path: '/certificate/add',
        name: 'certificate-add-page',
        meta: {
          title: '등록하기',
          middleware: 'auth',
        },
        component: CertificateAddPage,
      },
      {
        path: '/certificate/edit/:id',
        name: 'certificate-edit-page',
        meta: {
          title: '수정하기',
          middleware: 'auth',
        },
        component: CertificateAddPage,
      },
      {
        path: '/certificate/detail/:id',
        name: 'certificate-detail',
        meta: {
          title: '상세 조회',
          middleware: 'auth',
        },
        component: CertificateDetail,
      },
      {
        path: '/document/:id',
        name: 'document-detail',
        meta: {
          title: '상세 조회',
          middleware: 'auth',
        },
        component: DocumentDetail,
      },
      {
        path: '/notice/:id',
        name: 'notice-detail',
        meta: {
          title: '상세 조회',
        },
        component: NoticeDetail,
      },
      {
        path: '/alarm',
        name: 'alarm-list',
        meta: {
          title: '알림',
          middleware: 'auth',
        },
        component: Alarm,
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layout/EmptyLayout.vue'),
    children: [
      {
        path: '/login-page',
        name: 'login-page',
        component: Login,
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layout/EmptyLayout.vue'),
    children: [
      {
        path: '/privacypolicy',
        name: 'privacypolicy',
        component: PrivacyPolicy,
      },
    ],
  },
  // {
  //   path: "/",
  //   name: "emptyLayout",
  //   redirect: "/",
  //   component: EmptyLayout,
  //   children: [
  //     {
  //       path: "/splash-page",
  //       name: "splash-page",
  //       component: Splash,
  //     },
  //     {
  //       path: "/login-page",
  //       name: "login-page",
  //       component: Login,
  //     },
  //   ],
  // },
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.middleware == 'auth') {
    if (localStorage.getItem('token')) {
      next();
    } else {
      alert('로그인 정보가 없습니다.');
      next({ name: 'login-page' });
    }
  } else {
    next();
  }

  // console.log(to)
  // console.log(from)

  if (from.name === 'my-schedule-detail') {
    next();
  } else if (to.name === 'my-schedule-detail') {
    next();
  } else {
    store.dispatch('resetSelectDate');
    next();
  }

  if (from.name === 'store-detail') {
    next();
  } else if (to.name === 'store-detail') {
    next();
  } else {
    store.dispatch('resetStoreFilter');
    store.dispatch('resetStoreSelectFilter');
    next();
  }

  if (from.name === 'document-detail') {
    next();
  } else if (to.name === 'document-detail') {
    next();
  } else if (from.name === 'qsc-inspection-detail') {
    next();
  } else if (to.name === 'qsc-inspection-detail') {
    next();
  } else {
    store.dispatch('resetDocFilter');
    store.dispatch('resetDocCategory');
    next();
  }

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
});
export default router;
