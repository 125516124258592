<template>
  <div
    v-if="reportData"
    class="flex_col"
    style="width: 100%; padding: 16px 16px 80px 16px"
  >
    <div class="flex_row flex_center reportInfo">
      <div class="flex_col flex_center" style="margin-right: 25px">
        <div class="fontStyle1">{{ month }}월 점검 마감</div>
        <div class="fontStyle2 accent">D-{{ reportData?.deadLine }}</div>
      </div>
      |
      <div class="flex_col flex_center" style="margin-left: 25px">
        <div class="fontStyle1">최근 등급/점수</div>
        <div class="fontStyle2">
          <span :class="score">{{ reportData?.list[0].rank.slice(0, 1) }}</span>
          /{{ reportData?.list[0].total_score }}
        </div>
      </div>
    </div>
    <InspectionListManager :data="reportData?.list"></InspectionListManager>
  </div>
  <div
    v-else
    class="flex_col"
    style="width: 100%; height: 100%; align-items: center"
  >
    <div class="flex_col" style="margin-top: 143px; align-items: center">
      <img src="@/assets/error-not.svg" alt="" style="width: 32px" />
      <div class="errorInfoFont1" style="color: rgba(0, 0, 0, 0.32)">
        점검내역이 없습니다.
      </div>
    </div>
  </div>
</template>

<script>
import InspectionListManager from '@/components/inspection/InspectionListManager';
import { getInspectionList } from '@/api/StoreAPI';

export default {
  name: 'store-list',
  components: {
    InspectionListManager,
  },
  props: {},
  data() {
    return {
      reportData: null,
      curPage: 1,
      keyword: '',
      orderBy: '최신순',
      startDate: '',
      endDate: '',
      categoryType: '',
      categoryGrade: '',
      month: new Date().getMonth() + 1,
    };
  },
  computed: {
    score() {
      let propRank = this.reportData?.list[0].rank;
      if (propRank === 'A등급') {
        return 'classA';
      } else if (propRank === 'B등급') {
        return 'classB';
      } else if (propRank === 'C등급') {
        return 'classC';
      } else if (propRank === 'D등급') {
        return 'classD';
      } else {
        return 'classS';
      }
    },
    params() {
      let parameter = Object.entries({
        curPage: this.curPage,
        keyword: this.category,
        orderBy: this.orderBy,
        isShow: '1',
        categoryDateStart: this.startDate,
        categoryDateEnd: this.endDate,
        categoryType: this.categoryType,
        categoryGrade: this.categoryGrade,
      })
        .map((e) => e.join('='))
        .join('&');
      return parameter;
    },
  },
  methods: {
    getReportData() {
      getInspectionList(this.params)
        .then((res) => {
          // console.log(res.data.result)
          this.reportData = res.data.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getReportData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.fontStyle1 {
  color: $black50;
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-bottom: 4px;
}
.fontStyle2 {
  color: $black100;
  font-size: $fs24;
  font-weight: 600;
  line-height: 28.8px;
  letter-spacing: -1.3%;
}
.accent {
  color: $accent1;
}
.classA {
  color: $Aclass;
  background-color: none;
}
.classB {
  color: $Bclass;
  background-color: none;
}
.classC {
  color: $Cclass;
  background-color: none;
}
.classD {
  color: $Dclass;
  background-color: none;
}
.classS {
  color: $Sclass;
  background-color: none;
}
.reportInfo {
  padding: 12px;
  color: $black8;
  margin-bottom: 20px;
}
</style>
