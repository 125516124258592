<template>
  <div v-if="storeList" class="flex_col" style="width: 100%; gap: 12px">
    <div v-for="store in storeList" :key="store.id" class="flex_col">
      <div
        class="flex_col listCard"
        @click.prevent="
          $router.push({
            path: `/store/${store.id}`,
            params: {
              id: store.id,
            },
          })
        "
      >
        <div class="flex_row" style="align-items: center; margin-bottom: 8px">
          <h3 class="titleStyle">{{ store.name }}</h3>
          <h4 class="codeStyle">{{ store.code }}</h4>
        </div>
        <div class="personStyle">{{ store.managerName }}</div>
        <div class="adrressStyle">{{ store.addr }}</div>
        <div class="flex_row flex_between">
          <div class="flex_row" style="gap: 6px">
            <div class="tagStyle">{{ store.line }}</div>
            <div class="tagStyle">{{ store.team }}</div>
          </div>
          <!-- <div class="callBtn" @click.stop="settingCall(store.tel)">
            <img
              src="@/assets/call-icon.svg"
              alt="call"
              style="width: 14px; margin-right: 5px"
            />
            통화하기
          </div> -->
        </div>
      </div>
    </div>
    <!-- <BottomOptionCall
      :display="isCall"
      :number="tel"
      @changeDisplay="getCallDisplay"
    ></BottomOptionCall> -->
  </div>
</template>

<script>
// import BottomOptionCall from "@/components/global/option/BottomOptionCall.vue";
export default {
  props: {
    dataList: { type: Array, require: true },
  },
  components: {
    // BottomOptionCall,
  },
  data() {
    return {
      storeList: this.dataList,
      isCall: false,
      tel: "",
    };
  },
  methods: {
    getCallDisplay(value) {
      this.isCall = value;
    },
    settingCall(tel) {
      this.tel = tel;
      this.isCall = !this.isCall;
    },
  },
  watch: {
    dataList: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        this.storeList = this.dataList;
      },
    },
  },
  created() {
    // console.log(this.storeList)
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
.listCard {
  padding: 20px;
  background-color: $white100;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
}
.dayFont {
  color: $black50;
  font-weight: 400;
  font-size: $fs12;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-bottom: 4px;
}
.titleStyle {
  color: $black100;
  font-size: $fs18;
  font-weight: 600;
  line-height: 21.6px;
  letter-spacing: -2%;
  margin: 0;
}
.personStyle {
  color: $black100;
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-bottom: 8px;
}
.adrressStyle {
  color: $black50;
  font-size: $fs14;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-bottom: 24px;
}
.tagStyle {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid $black8;
  color: $black32;
  padding: 6px 10px;
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.codeStyle {
  color: $black32;
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -1.3%;
  margin-left: 8px;
}
.callBtn {
  width: fit-content;
  height: fit-content;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: $burgundy;
  padding: 6px 10px;
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -1.3%;
  border: 1px solid $burgundy;
}
</style>
