<template>
  <div class="flex_col flex_center">
    <div class="baseTagRound" :class="gradeColor">{{ grade.slice(0, 1) }}</div>
    <div class="scoreFont">
      <span class="scoreBlack">{{ score }}점</span> /200점
    </div>
  </div>
</template>

<script>
export default {
  props: {
    score: {
      type: Number,
      require: true,
    },
    grade: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      scoreData: this.rank,
    };
  },
  computed: {
    gradeColor() {
      let propRank = this.grade;
      // console.log("grade", this.grade);
      if (propRank === "A등급") {
        return "scoreA";
      } else if (propRank === "B등급") {
        return "scoreB";
      } else if (propRank === "C등급") {
        return "scoreC";
      } else if (propRank === "D등급") {
        return "scoreD";
      } else {
        return "scoreS";
      }
    },
  },
  watch: {
    grade: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        // console.log("grade",this.grade)
        this.scoreData = this.grade;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
.scoreFont {
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  color: $black50;
  margin-top: 6px;
}
.scoreBlack {
  color: $black100;
}
</style>
