<template>
  <div class="flex_col" v-if="data">
    <div
      class="flex_row flex_between"
      style="padding: 16px; border-bottom: 1px solid rgba(0, 0, 0, 0.08)"
    >
      <div class="flex_col">
        <div
          class="dayFont"
          v-if="this.$route.name === 'qsc-inspection-detail'"
        >
          {{ data.reportType }}
        </div>
        <div class="dayFont" v-else>{{ today() }} {{ data.type }}</div>
        <div class="personFont" style="margin-bottom: 4px">
          {{ data.templateTitle }}
        </div>
        <div class="personFont">{{ data.storeManager }}</div>
        <!-- scoreList 수정하기/결과/피드백 화면에서만 출력 -->
        <ScoreList
          v-if="this.$route.name !== 'qsc-inspection'"
          :data="data"
          style="margin-top: 12px; margin-bottom: 0"
        ></ScoreList>
      </div>
      <!-- scoreTag 결과 화면에서만 출력 -->
      <ScoreTag
        v-if="this.$route.name === 'qsc-inspection-detail'"
        :score="data.totalScore"
        :grade="data.rank"
      ></ScoreTag>
    </div>
    <div>
      <template
        v-if="
          this.$route.name === 'qsc-inspection-detail' ||
          this.$route.name === 'qsc-inspection-feedback'
        "
      >
        <!-- 점수 수정이 불가한 경우 -->
        <QSCInspectionListResult :data="data"></QSCInspectionListResult>
      </template>
      <template v-else>
        <!-- 점수 수정이 가능한 경우 -->
        <QSCInspectionList :data="data"></QSCInspectionList>
      </template>
    </div>
  </div>
</template>

<script>
import QSCInspectionListResult from "@/components/qsc/QSCInspectionListResult.vue";
import QSCInspectionList from "@/components/qsc/QSCInspectionList.vue";
import ScoreList from "@/components/inspection/ScoreList.vue";
import ScoreTag from "@/components/inspection/ScoreTag.vue";
import returnToday from "@/functions/returnToday";

export default {
  components: {
    QSCInspectionListResult,
    QSCInspectionList,
    ScoreList,
    ScoreTag,
  },
  props: {
    data: { type: Object, require: true },
  },
  methods: {
    today() {
      return returnToday();
    },
  },
  created() {
    // console.log("점검표", this.data);
    // todo 스크롤 to top
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
@import "@/assets/scss/qsc.scss";
.personFont {
  color: $black100;
  font-weight: 500;
  font-size: $fs12;
  line-height: 14.4px;
  letter-spacing: -1.3%;
}
.dayFont {
  color: $black50;
  font-weight: 400;
  font-size: $fs12;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-bottom: 12px;
}
</style>
