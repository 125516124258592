<template>
  <div class="BG" @click="changeDisplay">
    <div class="bottomOptionBG">
        <div class="handle"></div>
        <div class="picker-group" style="display:flex;flex-direction:row;height:200px;">
            <ScrollPicker :options="hour" v-model="selectedHour" :dragSensitivity="2" :scrollSensitivity="2" :touchSensitivity="2" style="width:150px;padding-left:100px"/>
            <ScrollPicker :options="minute" v-model="selectedMinute" :dragSensitivity="2" :scrollSensitivity="2" :touchSensitivity="2" style="width:150px"/>
            <ScrollPicker :options="time" v-model="selectedTime" :dragSensitivity="2" :scrollSensitivity="2" :touchSensitivity="2" style="width:150px;padding-right:100px"/>
        </div>
        <button class="baseBtn fill" @click="onActions" >적용</button>
    </div>
  </div>
</template>

<script>
import { ScrollPicker } from "vue-scroll-picker"

export default {
  components: {
    ScrollPicker,
  },
  props: {
    isTimeView: { type: Boolean, required: true},
    workTime: { type: String, required: true },
  },
  data (){
    return {
      displayState: this.isTimeView,
      selectedTime: null,
      selectedHour: null,
      selectedMinute: null,
      hour: [],
      minute: [],
      time: ['AM','PM'],
    }
  },
  methods: {
    makeTime() {
      const hours = [];
        for (let index = 1; index < 13; index++) {
            hours.push(index)
        }
        this.hour = hours
        const minutes = [];
        for (let index = 0; index < 61; index++) {
            minutes.push(`${index<10?`0`+index:index}`)
        }
        this.minute = minutes
    },
    onActions() {
      this.displayState = !this.displayState
      this.$emit('changeTime', this.displayState, this.makeData)
    },
    changeDisplay() {
      this.displayState = !this.displayState
      this.$emit('changeTime', this.displayState)
    }
  },
  computed: {
    makeData(){
      let timeData = '';
      if(this.selectedTime === 'AM'){
        timeData = `${this.selectedHour > 9 ? this.selectedHour : '0'+ this.selectedHour}:${this.selectedMinute}:00`
      } else {
        let hour = this.selectedHour + 12
        timeData = `${hour}:${this.selectedMinute}:00`
      }
      return timeData
    }
  },
  watch: {
    selectedTime: {
        //handler: function(바뀐값,바뀌기전 값)
        handler: function () {
          // console.log("selectedTime바뀜",this.selectedTime)
        }
      },
      selectedHour: {
        //handler: function(바뀐값,바뀌기전 값)
        handler: function () {
          // console.log("selectedHour",this.selectedHour)
        }
      },
      selectedMinute: {
        //handler: function(바뀐값,바뀌기전 값)
        handler: function () {
          // console.log("selectedTime바뀜",this.selectedMinute)
        }
      },
      workTime: {
        //handler: function(바뀐값,바뀌기전 값)
        handler: function () {
          this.selectedTime = 'AM'
          this.selectedHour = Number(this.workTime.slice(0,2))
          this.selectedMinute = Number(this.workTime.slice(3,5))
        }
      },
  },
  created(){
    // console.log(this.workTime)
    this.makeTime()
    this.selectedTime = Number(this.workTime.slice(0,2))>12 ? 'PM' : 'AM'
    if(Number(this.workTime.slice(0,2))>12){
      this.selectedHour = Number(this.workTime.slice(0,2)) - 12
    }else{
      this.selectedHour = Number(this.workTime.slice(0,2))
    }
    this.selectedMinute = Number(this.workTime.slice(3,5))
  }
}
</script>

<style lang="scss">
    @import '@/assets/scss/variable.scss'; 
    @import '@/assets/scss/component.scss'; 
  .vue-scroll-picker-item {
    height: 1.6em !important;
  }
  .vue-scroll-picker-layer .top {
    height: calc(50% - 1.3em) !important;
  }
  .vue-scroll-picker-layer .bottom {
    height: calc(50% - 0.9em) !important;
  }
</style>