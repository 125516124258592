<template>
  <div class="loginBG">
    <div>
      <div class="flex_col flex_center">
        <img
          src="@/assets/logoWhite.svg"
          alt=""
          width="57px"
          height="37px"
          style="margin-bottom: 95px"
        />
      </div>
      <p class="fontStyle1">고객의 행복한<br />식문화를 만들어갑니다.</p>
      <label class="relative">
        <input
          class="loginInputStyle"
          type="text"
          placeholder="아이디(사번)"
          :value="account"
          @input="valiCheck('account', $event.target.value)"
          style="border-radius: 8px 8px 0 0"
        />
        <img
          v-if="valiAccount === false"
          src="@/assets/check-icon-fail.svg"
          alt="Not Valid"
          style="position: absolute; top: 18px; right: 18px"
        />
      </label>
      <label class="relative">
        <input
          class="loginInputStyle"
          :type="isShowPwd ? 'text' : 'password'"
          placeholder="비밀번호"
          :value="pwd"
          ref="pwdRef"
          @input="valiCheck('pwd', $event.target.value)"
          @focus="focusControll('focus')"
          @blur="focusControll('blur')"
          style="
            border-radius: 0 0 8px 8px;
            border-top: 1px solid rgba(0, 0, 0, 0.16);
          "
        />
        <div
          style="
            display: flex;
            position: absolute;
            top: 18px;
            right: 18px;
            gap: 10px;
          "
        >
          <img
            v-if="pwd.length > 0 && isShowPwd"
            src="@/assets/password-on-icon.svg"
            alt="Show Password"
            @click="focusinControll"
          />
          <img
            v-else-if="pwd.length > 0 && isShowPwd === false"
            src="@/assets/password-icon.svg"
            alt="Blind Password"
            @click="focusinControll"
          />
          <img
            v-if="valiPWD === false"
            src="@/assets/check-icon-fail.svg"
            alt="Not Valid"
          />
        </div>
      </label>
      <div
        style="
          background-color: white;
          width: 100%;
          border-radius: 8px;
          margin-top: 40px;
        "
      >
        <ButtonBasic
          type="fill"
          text="로그인"
          :isDisable="!isValid"
          :clickEvent="submitForm"
        />
      </div>
    </div>
    <p class="fontStyle2" @click="$router.push({ name: 'password-find' })">
      비밀번호를 잊어버리셨나요?
    </p>
    <div class="copy">
      본 어플리케이션은 인가받은 사용자만 사용이 가능합니다.<br />
      계정이 없으시다면, 아래 번호로 관리자에게 문의하세요.<br />
      (관리자 연락처. 0000-0000)
    </div>
    <CommonAlert2 :state="isShowAlert" @changeDisplay="getAlertDisplayState">
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #content2 v-if="alertContent2.length !== 0">
        {{ alertContent2 }}
      </template>
      <template #btn>
        {{ alertBtn }}
      </template>
    </CommonAlert2>
    <ToastMsg :message="toastMassage" :display="toastDisplay"></ToastMsg>
  </div>
</template>

<script>
import ButtonBasic from '@/components/global/inputs/ButtonBasic.vue';
import { login, loginSuccess } from '@/api/LoginAPI';
import CommonAlert2 from '@/components/global/alert/CommonAlert2.vue';
import ToastMsg from '@/components/global/alert/ToastMsg.vue';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

export default {
  name: 'login-page',
  components: {
    ButtonBasic,
    CommonAlert2,
    ToastMsg,
  },
  data() {
    return {
      isShowPwd: false,
      // 점장 kimmanager
      // 팀장 kimleader
      account: '',
      pwd: '',
      pwdFocus: false,
      valiPWD: null,
      valiAccount: null,
      isShowAlert: false,
      alertTitle: '',
      alertContent1: '',
      alertContent2: '',
      alertBtn: '',
      toastDisplay: false,
      toastMassage: '',
    };
  },
  methods: {
    focusControll(state) {
      // (현재사용안함)
      if (state === 'focus') {
        this.pwdFocus = true;
      } else {
        setTimeout(() => {
          this.pwdFocus = false;
        }, 100);
      }
    },
    focusinControll() {
      // 비밀번호 인풋에서 눈 아이콘 눌렀을 경우에도 포커스 유지 시키는 기능
      let data = this.pwd;
      this.isShowPwd = !this.isShowPwd;
      this.$refs.pwdRef.focus();
      this.pwd = data;
    },
    getAlertDisplayState(value) {
      this.isShowAlert = value;
    },
    valiCheck(type, data) {
      // 아이디, 비밀번호 인풋 이벤트
      // 아이디, 비밀번호 유효성 검사 로직 (현재 사용 안함)
      // const accountRegExp = /^([a-z*]|[0-9*]|[a-z0-9]){5,20}$/g;
      // const pwdRegExp =  /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
      if (type === 'account') {
        this.account = data;
        // if(accountRegExp.test(data)){
        //   this.valiAccount = true
        // } else {
        //   this.valiAccount = false
        // }
      } else if (type === 'pwd') {
        this.pwd = data;
        // if(pwdRegExp.test(data)){
        //   this.valiPWD = true
        // } else {
        //   this.valiPWD = false
        // }
      }
    },
    submitForm() {
      const formData = new FormData();
      formData.append('account', this.account);
      formData.append('pwd', this.pwd);
      const loginData = formData;

      if (
        Capacitor.isNativePlatform() &&
        window.localStorage.getItem('fcmToken')
      ) {
        loginData.append('fcmToken', window.localStorage.getItem('fcmToken'));
      }
      
      login(loginData)
        .then((res) => {
          // (int) codeManagerFromStore : 팀장(2), 지점이 있는 점장(1), 지점이 없는 점장(0) 에 관한 상태값.
          // (string) msgManagerFromStore : isManagerFromStore에 대한 설명 문구.

          if (res.data.isSuccessful) {
            if (res.data.result.codeManagerFromStore === 0) {
              // 담당 지점이 없는 점장의 로그인 시 ACTION
              // v Alert 띄우기 : 해당 점장은 소속된 지점이 없습니다.
              this.valiAccount = false;
              this.valiPWD = false;
              this.pwd = '';
              this.account = '';
              this.alertTitle = '로그인 실패';
              this.alertContent1 = '담당 지점이 없어 사용이 불가한 계정입니다.'
              this.alertContent2 = '관리자에게 문의해주세요.';
              this.alertBtn = '확인';
              this.isShowAlert = true;
            } else {
              // 로그인 성공시 ACTION
              // v Toast 띄우기 : 로그인에 성공했습니다.
              this.toastDisplay = true;
              this.toastMassage = '로그인에 성공했습니다';
              setTimeout(() => {
                this.toastDisplay = false;
                this.toastMassage = '';
                // v 토큰 localstorage 저장
                loginSuccess(res.data.result, this.account, this.pwd);
                // v main-page 로 이동
                this.$router.push({ name: 'main-page' });
              }, 1000);
            }
          } else {
            // 로그인 실패시 ACTION
            // v Alert 띄우기 : 아이디 혹은 비밀번호가 틀렸습니다.
            this.valiAccount = false;
            this.valiPWD = false;
            this.pwd = '';
            this.account = '';
            this.alertTitle = '로그인 실패';
            this.alertContent1 =
              '아이디(사번) 또는 비밀번호가 일치하지 않습니다.';
            this.alertContent2 = '';
            this.alertBtn = '확인';
            this.isShowAlert = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    isValid() {
      // 아이디 비밀번호 모두 작성 되었을 때, 로그인 버튼 활성 화
      let value = null;
      if (this.pwd && this.account) {
        value = true;
      } else {
        value = false;
      }
      return value;
    },
  },
  mounted() {
    const setupFcm = async () => {
      if (Capacitor.isNativePlatform()) {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }
      }
    };

    setupFcm();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.loginBG {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(@/assets/splash-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding: 80px 20px 32px 20px;
}
.fontStyle1 {
  display: flex;
  font-size: $fs22;
  font-weight: 600;
  line-height: 30.03px;
  letter-spacing: -1.3%;
  color: $white100;
  margin: 0;
  margin-bottom: 20px;
}
.fontStyle2 {
  display: flex;
  justify-content: center;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
  color: $white100;
  text-decoration: underline;
  margin: 0;
  margin-bottom: 53px;
}
.loginInputStyle {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  font-size: $fs16;
  font-weight: 400;
  line-height: 19.2px;
  color: $black100;
  margin: 0;
  padding: 18px 16px;
  border: none;
  outline: none;
  &::placeholder {
    color: $black50;
  }
}
.copy {
  display: flex;
  font-size: $fs12;
  font-weight: 400;
  line-height: 17.52px;
  color: rgba(255, 255, 255, 0.5);
}
</style>
