<template>
    <div>
        <InspectionReportMaster v-if="isMaster"></InspectionReportMaster>
        <InspectionReportManager v-else></InspectionReportManager>
    </div>
</template>

<script>
import InspectionReportManager from "@/components/inspection/InspectionReportManager"
import InspectionReportMaster from "@/components/inspection/InspectionReportMaster"

export default {
  name: 'store-list',
  components: {
    InspectionReportManager,
    InspectionReportMaster,
  },
  props: {
  },
  data () {
    return {
        useData: null,
        isMaster: true //true: 팀장 & false: 점장
    }
  },
  created(){
    if(localStorage.getItem('managerType') === '팀장'){
        this.isMaster = true
    } else {
        this.isMaster = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variable.scss';
  @import '@/assets/scss/component.scss';
</style>