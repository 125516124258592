<template>
  <div v-if="dataList.length>0">
    <div v-for="data in dataList" 
      :key="data.id"
      class="flex_row listStyle" 
      @click="goDetailPage(data.id)">
        <div class="flex_col">
            <div class="fontStyle1">
            {{ data.category }}
            </div>
            <div class="fontStyle2">
              {{ data.title }}
            </div>
            <div class="fontStyle3">
              {{ data.updateAt }}
            </div>
        </div>
        <img src="@/assets/more.svg" alt="" style="width:20px" @click.stop="isMenu=!isMenu,id=data.id"/>
      </div>
      <BottomOptionList :displayState="isMenu" type="education" @modiAction="modiAction" @deleteAction="deleteAction"></BottomOptionList>
      <CommonAlertTwoBtn :state="isShowAlert" @changeDisplay="getAlertDisplayState" @confirmAction1="confirmAction1" @confirmAction2="confirmAction2">
        <template #title>
          {{ alertTitle }}
        </template>
        <template #content1>
          {{ alertContent1 }}
        </template>
        <template #content2>
          {{ alertContent2 }}
        </template>
        <template #btn1>
          {{ alertBtn1 }}
        </template>
        <template #btn2>
          {{ alertBtn2 }}
        </template>
      </CommonAlertTwoBtn>
      <ToastMsg :message="toastMassage" :display="toastDisplay"></ToastMsg>
  </div>
</template>
  
  <script>
  import { delLearning } from "@/api/EducationAPI"
  import BottomOptionList from "@/components/global/option/BottomOptionList"
  import CommonAlertTwoBtn from "@/components/global/alert/CommonAlertTwoBtn.vue"
  import ToastMsg from "@/components/global/alert/ToastMsg.vue"
  export default {
    name: 'inspaction-temporary-list',
    components: {
      BottomOptionList,
      CommonAlertTwoBtn,
      ToastMsg,
  },
  props: {
    EduList: { type: Array, required: false },
    isReset: { type: Boolean, required: false }
  },
  data () {
    return {
      id: null,
      isResetValue: this.isReset,
      dataList: this.EduList,
      isMenu: false,
      isShowAlert: false,
      alertTitle:'',
      alertContent1:'',
      alertContent2:'정말로 삭제하시겠습니까?',
      alertBtn1:'취소',
      alertBtn2:'삭제',
      toastDisplay: false,
      toastMassage: ''
    }
  },
  methods: {
    goDetailPage(id){
      if(this.$route.name === "learning-list"){
        this.$router.push(
          {
            path: `/learning/detail/${id}`, 
            params: {
              id: id
              }
          })
      }
      if(this.$route.name === "certificate-list"){
        this.$router.push(
          {
            path: `/certificate/detail/${id}`, 
            params: {
              id: id
              }
          })
      }
    },
    changeResetState(){
      this.isResetValue = true
        this.$emit('changeResetState',this.isResetValue)
      this.isResetValue = false
    },
    modiAction(){
      if(this.$route.name === "learning-list"){
        this.$router.push(
          {
            path: `/learning/edit/${this.id}`, 
            params: {
              id: this.id
              }
          })
      }
      if(this.$route.name === "certificate-list"){
        this.$router.push(
          {
            path: `/certificate/edit/${this.id}`, 
            params: {
              id: this.id
              }
          })
      }
    },
    deleteAction(){
      this.isShowAlert = true
      if(this.$route.name === "learning-list"){
        this.alertTitle = '교육일지 삭제'
        this.alertContent1 = '한번 삭제된 교육일지는 복구할 수 없습니다.'
      }
      if(this.$route.name === "certificate-list"){
        this.alertTitle = '수료증 삭제'
        this.alertContent1 = '한번 삭제된 수료증은 복구할 수 없습니다.'
      }
    },
    getAlertDisplayState(value){
        this.isShowAlert = value
    },
    confirmAction1(){
        this.isShowAlert = false;
        this.optionState = null;
    },
    confirmAction2(){
      delLearning(this.id)
        .then((res)=>{
          // console.log(res.data)
          if(res.data.isSuccessful){
              this.toastDisplay = true
              this.toastMassage = "삭제되었습니다"
              setTimeout(() => {
                this.toastDisplay = false
                this.toastMassage = ""
                this.changeResetState()
              }, 1000);
          } else {
              console.log("에러")
          }
        })
        .catch((err)=>{
            console.log(err)
        })
        .finally(()=>{
          this.optionState = null;
        })
      },
  },
  watch: {
    EduList: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        this.dataList = this.EduList
      }
    }
  },
  // created(){
  //   if(this.$route.name === "certificate-list"){
  //     console.log(this.$route.name)
  //   }
  // }
}
  </script>
  
  <style lang="scss" scoped>
    @import '@/assets/scss/variable.scss';
    @import '@/assets/scss/component.scss';
    .listStyle {
      width: 100%;
      padding: 16px;
      border-bottom: 1px solid $black8;
      justify-content: space-between;
      align-items: flex-start;
    }
    .fontStyle1 {
      color: $black50;
      font-size: $fs12;
      font-weight: 500; 
      line-height: 14.4px;
      letter-spacing: -1.3%;
      margin-bottom: 4px;
    }
    .fontStyle2 {
      color: $black100;
      font-size: $fs16;
      font-weight: 500; 
      line-height: 19.2px;
      letter-spacing: -1.3%;
      margin-bottom: 4px;
    }
    .fontStyle3 {
      color: $black32;
      font-size: $fs10;
      font-weight: 400; 
      line-height: 12px;
      letter-spacing: -2%;
    }
  </style>