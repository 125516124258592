<template>
  <!-- 수정페이지에서 바로바로 반영될 필요없음 -->
  <div class="listScore" v-if="data">
    미흡
    {{
      this.$route.name === "inspection-list-report"
        ? data.insufficientCnt
        : insufficientCnt
    }}건
    <span class="gray">|</span>
    피드백
    {{
      this.$route.name === "inspection-list-report"
        ? data.feedbackCnt
        : feedbackCnt
    }}건
    <span class="gray">|</span>
    <span class="burgundy">
      처리
      {{
        this.$route.name === "inspection-list-report"
          ? data.feedbackOfFeedbackCnt
          : feedbackOfFeedbackCnt
      }}건
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, require: true },
  },
  computed: {
    insufficientCnt() {
      return (
        Number(this.data.hygieneInsufficientCnt) +
        Number(this.data.lawInsufficientCnt) +
        Number(this.data.serviceInsufficientCnt)
      );
    },
    feedbackCnt() {
      return (
        Number(this.data.hygieneFeedbackCnt) +
        Number(this.data.lawFeedbackCnt) +
        Number(this.data.serviceFeedbackCnt)
      );
    },
    feedbackOfFeedbackCnt() {
      return (
        Number(this.data.hygieneFeedbackOfFeedbackCnt) +
        Number(this.data.lawFeedbackOfFeedbackCnt) +
        Number(this.data.serviceFeedbackOfFeedbackCnt)
      );
    },
  },
  created() {
    // console.log("data", this.data);
    // if (this.$route.name === "inspection-list-report") {
    //   console.log("미흡", this.data.insufficientCnt);
    //   console.log("피드백", this.data.feedbackCnt);
    //   console.log("처리", this.data.feedbackOfFeedbackCnt);
    // } else {
    //   console.log("미흡", this.insufficientCnt);
    //   console.log("피드백", this.feedbackCnt);
    //   console.log("처리", this.feedbackOfFeedbackCnt);
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
.listScore {
  color: $black100;
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-bottom: 19px;
}
.gray {
  font-size: $fs10;
  color: $black8;
}
.burgundy {
  color: $burgundy;
}
</style>
