<!-- 점수 수정이 불가한 경우 -->
<template>
  <div class="flex_col" id="envTop">
    <div
      :class="{
        paddingBottom:
          this.$route.name !== 'qsc-inspection-detail' &&
          this.$route.name !== 'qsc-inspection-feedback',
      }"
    >
      <div v-if="law.detail" class="flex_col">
        <div class="flex_row flex_between" style="padding: 28px 16px">
          <div class="totalScoreTitle">중점 관리 사항</div>
          <div class="totalScoreStyle">
            <span class="burgundy">{{ data.lawScore }}점</span>/{{
              data.lawTotal
            }}점
          </div>
        </div>
        <div
          v-for="(question, index) in law.detail"
          :key="index"
          style="margin: 0 16px"
        >
          <div :class="{ bottomLine: law.detail.length - 1 !== index }">
            <QSCInspectionQuestion
              :data="question"
              :index="index"
              @getAnswer="getAnswerLaw"
            ></QSCInspectionQuestion>
          </div>
        </div>
      </div>
      <div v-if="hygiene.detail" class="flex_col">
        <div class="flex_row flex_between" style="padding: 28px 16px">
          <div class="totalScoreTitle">일반관리</div>
          <div class="totalScoreStyle">
            <span class="burgundy">{{ data.hygieneScore }}점</span>/{{
              data.hygieneTotal
            }}점
          </div>
        </div>
        <div
          v-for="(question, index) in hygiene.detail"
          :key="index"
          style="margin: 0 16px"
        >
          <div :class="{ bottomLine: hygiene.detail.length - 1 !== index }">
            <QSCInspectionQuestion
              :data="question"
              :index="index"
              @getAnswer="getAnswerHygiene"
            ></QSCInspectionQuestion>
          </div>
        </div>
      </div>
      <div v-if="service.detail" class="flex_col">
        <div class="flex_row flex_between" style="padding: 28px 16px">
          <div class="totalScoreTitle">문서 관리</div>
          <div class="totalScoreStyle">
            <span class="burgundy">{{ data.serviceScore }}점</span>/{{
              data.serviceTotal
            }}점
          </div>
        </div>
        <div
          v-for="(question, index) in service.detail"
          :key="index"
          style="margin: 0 16px"
        >
          <div :class="{ bottomLine: service.detail.length - 1 !== index }">
            <QSCInspectionQuestion
              :data="question"
              :index="index"
              @getAnswer="getAnswerService"
            ></QSCInspectionQuestion>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="this.$route.name == 'qsc-inspection-detail'"
        style="padding: 24px"
      >
        <div v-if="!isMaster">
          <div
            v-if="
              data.reportType.slice(
                data.reportType.length - 5,
                data.reportType.length
              ) === '자가점검'
            "
          >
            <div class="baseBtn fill" @click="moveFeedback">
              피드백 작성하기
            </div>
          </div>
          <div v-else class="flex_row" style="gap: 12px">
            <div class="baseBtn fill" @click="moveFeedback">
              피드백 작성하기
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="this.$route.name == 'qsc-inspection-feedback'"
        style="padding: 16px"
      >
        <!-- <div class="flex_col" style="gap: 11px">
          <div class="flex_row flex_between">
            <div class="scoreTitleStyle">총점</div>
            <div class="scoreStyle">
              <span class="burgundy">{{ data.totalScore }}점</span> /{{
                defaultScore
              }}점
            </div>
          </div>
          <div class="flex_row flex_between" style="padding-bottom: 30px">
            <div class="scoreTitleStyle">등급</div>
            <div class="flex_row" style="align-items: center">
              <div v-if="this.lawCheck === false" class="scoreNoticeStyle">
                중점 관리 사항 미흡 항목 있음
              </div>
              <ScoreTag :rank="rankCheck"></ScoreTag>
            </div>
          </div>
        </div> -->
        <ButtonBasic
          text="저장하기"
          type="fill"
          :isDisable="false"
          :clickEvent="addFeedback"
        />
      </div>
    </div>
    <div v-if="this.$route.name === 'qsc-inspection-detail'">
      <button
        v-if="
          (isMaster &&
            data.reportType.slice(
              data.reportType.length - 4,
              data.reportType.length
            ) === '본사점검') ||
          (!isMaster &&
            data.reportType.slice(
              data.reportType.length - 4,
              data.reportType.length
            ) === '자가점검')
        "
        class="headerTextBtn saveBtn"
        @click="moveModi"
        :style="`top:${envTopSize}px`"
      >
        수정
      </button>
    </div>
    <Sign
      :signDisplay="isSignDisplay"
      @changeDisplay="signDisplayHandler"
      @getSign="getSign"
    ></Sign>
    <CommonAlert2 :state="isShowAlert" @changeDisplay="getAlertDisplayState">
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #content2 v-if="alertContent2.length !== 0">
        {{ alertContent2 }}
      </template>
      <template #btn>
        {{ alertBtn }}
      </template>
    </CommonAlert2>
    <CommonAlertTwoBtn
      :state="isShowAlert2"
      @changeDisplay="getAlertDisplayState2"
      @confirmAction1="confirmAction1"
      @confirmAction2="confirmAction2"
    >
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #content2 v-if="alertContent2.length !== 0">
        {{ alertContent2 }}
      </template>
      <template #btn1>
        {{ alertBtn1 }}
      </template>
      <template #btn2>
        {{ alertBtn2 }}
      </template>
    </CommonAlertTwoBtn>
    <ToastMsg :message="toastMassage" :display="toastDisplay"></ToastMsg>
  </div>
</template>

<script>
import QSCInspectionQuestion from "@/components/qsc/QSCInspectionQuestion.vue";
import { getScore, addInspectionQA, modiInspection } from "@/api/StoreAPI";
// import ScoreTag from "../store/ScoreTag.vue";
import ButtonBasic from "@/components/global/inputs/ButtonBasic.vue";
import Sign from "../lib/Sign.vue";
import CommonAlert2 from "@/components/global/alert/CommonAlert2.vue";
import CommonAlertTwoBtn from "@/components/global/alert/CommonAlertTwoBtn.vue";
import ToastMsg from "@/components/global/alert/ToastMsg.vue";

export default {
  components: {
    QSCInspectionQuestion,
    // ScoreTag,
    ButtonBasic,
    Sign,
    CommonAlert2,
    CommonAlertTwoBtn,
    ToastMsg,
  },
  props: {
    data: { type: Object, require: true },
  },
  data() {
    return {
      envTopSize: 0,
      id: this.$route.params.id,
      useData: null,
      isMaster: true,
      law: this.data.law,
      hygiene: this.data.hygiene,
      service: this.data.service,
      newLawData: this.data.law,
      newHygieneData: this.data.hygiene,
      newServiceData: this.data.service,
      totalScore: 0,
      scoreData: null,
      isSignDisplay: false,
      signLink: "",
      isShowAlert: false,
      isShowAlert2: false,
      alertTitle: "",
      alertContent1: "",
      alertContent2: "",
      alertBtn: "",
      alertBtn1: "",
      alertBtn2: "",
      toastDisplay: false,
      toastMassage: "",
    };
  },
  methods: {
    confirmAction1() {
      this.isShowAlert2 = false;
    },
    confirmAction2() {
      this.isShowAlert2 = false;
      this.addQSC(1);
    },
    getAlertDisplayState(value) {
      this.isShowAlert = value;
    },
    getAlertDisplayState2(value) {
      this.isShowAlert2 = value;
    },
    getSign(value) {
      // 사인 받으면 제출 API 호출
      this.signLink = value;
      this.isSignDisplay = false;
      this.addQSC(1);
    },
    signDisplayHandler(value) {
      this.isSignDisplay = value;
    },
    moveFeedback() {
      this.$router.replace({
        path: `/qsc/feedback/${this.id}`,
        params: { id: this.id },
      });
    },
    moveModi() {
      this.$router.replace({
        path: `/qsc/edit/${this.id}`,
        params: { id: this.id },
      });
    },
    addFeedback() {
      this.scoreCheck();
    },
    getAnswerLaw(index, value) {
      let arr = [...this.newLawData.detail];
      arr.splice(index, 1, value);
      this.newLawData.detail = arr;
    },
    getAnswerHygiene(index, value) {
      let arr = [...this.newHygieneData.detail];
      arr.splice(index, 1, value);
      this.newHygieneData.detail = arr;
    },
    getAnswerService(index, value) {
      let arr = [...this.newServiceData.detail];
      arr.splice(index, 1, value);
      this.newServiceData.detail = arr;
    },
    scoreCheck() {
      // 점검 점수 내기 API
      const formData = new FormData();
      formData.append("law", JSON.stringify(this.newLawData));
      formData.append("hygiene", JSON.stringify(this.newHygieneData));
      formData.append("service", JSON.stringify(this.newServiceData));
      const scoredata = formData;
      getScore(scoredata)
        .then((res) => {
          if (res.data) {
            if (res.data.isSuccessful) {
              this.scoreData = res.data.result;
              if (this.$route.name !== "qsc-inspection-feedback") {
                this.isSignDisplay = true;
              } else {
                this.alertTitle = "저장하기";
                this.alertContent1 = "작성한 피드백을 저장하시겠습니까?";
                this.alertContent2 = "";
                this.alertBtn1 = "취소";
                this.alertBtn2 = "저장";
                this.isShowAlert2 = true;
              }
            } else {
              this.alertTitle = "저장실패";
              this.alertContent1 = "답변하지 않은 문항이 있습니다.";
              this.alertContent2 = "답변을 완료해주세요.";
              this.alertBtn = "확인";
              this.isShowAlert = true;
            }
          }
        })
        .catch((err) => {
          this.alertTitle = "저장실패";
          this.alertContent1 = "알수 없는 에러";
          this.alertContent2 = "서비스 관리자에게 문의하세요.";
          this.alertBtn = "확인";
          this.isShowAlert = true;
          console.log(err);
        });
    },
    addQSC(sendType) {
      // 점검표 등록 API
      const formData = new FormData();
      formData.append(
        "storeId",
        this.$route.params.storeId
          ? this.$route.params.storeId
          : this.data.storeId
      );
      formData.append(
        "reportTemplateId",
        this.$route.params.reportTemplateId
          ? this.$route.params.reportTemplateId
          : this.data.reportTemplateId
      );
      formData.append("isShow", sendType);
      formData.append("law", JSON.stringify(this.newLawData));
      formData.append("hygiene", JSON.stringify(this.newHygieneData));
      formData.append("service", JSON.stringify(this.newServiceData));
      formData.append("totalScore", this.scoreData.totalScore);
      formData.append("rank", this.scoreData.rank);
      formData.append("lawScore", this.scoreData.lawScore);
      formData.append("lawTotalScore", this.scoreData.lawTotalScore);
      formData.append("lawInsufficientCnt", this.scoreData.lawInsufficientCnt);
      formData.append(
        "lawQuestionIsInsufficient",
        this.scoreData.lawQuestionIsInsufficient ? 1 : 0
      );
      formData.append("lawFeedbackCnt", this.scoreData.lawFeedbackCnt);
      formData.append(
        "lawFeedbackOfFeedbackCnt",
        this.scoreData.lawFeedbackOfFeedbackCnt
      );
      formData.append("hygieneScore", this.scoreData.hygieneScore);
      formData.append("hygieneTotalScore", this.scoreData.hygieneTotalScore);
      formData.append(
        "hygieneInsufficientCnt",
        this.scoreData.hygieneInsufficientCnt
      );
      formData.append("hygieneFeedbackCnt", this.scoreData.hygieneFeedbackCnt);
      formData.append(
        "hygieneFeedbackOfFeedbackCnt",
        this.scoreData.hygieneFeedbackOfFeedbackCnt
      );
      formData.append("serviceScore", this.scoreData.serviceScore);
      formData.append("serviceTotalScore", this.scoreData.serviceTotalScore);
      formData.append(
        "serviceInsufficientCnt",
        this.scoreData.serviceInsufficientCnt
      );
      formData.append("serviceFeedbackCnt", this.scoreData.serviceFeedbackCnt);
      formData.append(
        "serviceFeedbackOfFeedbackCnt",
        this.scoreData.serviceFeedbackOfFeedbackCnt
      );
      formData.append("managerSign", this.signLink);
      const qscData = formData;
      if (this.$route.name === "qsc-inspection-feedback") {
        modiInspection(this.id, qscData)
          .then((res) => {
            if (res.data.isSuccessful) {
              // v Toast 띄우기 : 성공적으로 제출되었습니다.
              this.toastDisplay = true;
              this.toastMassage = "성공적으로 제출되었습니다.";
              setTimeout(() => {
                this.toastDisplay = false;
                this.toastMassage = "";
                // v listpage 로 이동
                this.$router.push({ path: "/inspection/report" });
              }, 1000);
            } else {
              this.alertTitle = "저장실패";
              this.alertContent1 = "알수 없는 에러";
              this.alertContent2 = "서비스 관리자에게 문의하세요.";
              this.alertBtn = "확인";
              this.isShowAlert = true;
            }
          })
          .catch((err) => {
            this.alertTitle = "저장실패";
            this.alertContent1 = "알수 없는 에러";
            this.alertContent2 = "서비스 관리자에게 문의하세요.";
            this.alertBtn = "확인";
            this.isShowAlert = true;
            console.log(err);
          });
      } else {
        addInspectionQA(qscData)
          .then((res) => {
            if (res.data.isSuccessful) {
              // v Toast 띄우기 : 성공적으로 제출되었습니다.
              this.toastDisplay = true;
              this.toastMassage = "성공적으로 제출되었습니다.";
              setTimeout(() => {
                this.toastDisplay = false;
                this.toastMassage = "";
                // v listpage 로 이동
                this.$router.push({ path: "/inspection/report" });
              }, 1000);
            } else {
              this.alertTitle = "저장실패";
              this.alertContent1 = "알수 없는 에러";
              this.alertContent2 = "서비스 관리자에게 문의하세요.";
              this.alertBtn = "확인";
              this.isShowAlert = true;
            }
          })
          .catch((err) => {
            this.alertTitle = "저장실패";
            this.alertContent1 = "알수 없는 에러";
            this.alertContent2 = "서비스 관리자에게 문의하세요.";
            this.alertBtn = "확인";
            this.isShowAlert = true;
            console.log(err);
          });
      }
    },
  },

  computed: {
    lawCheck() {
      let arr = this.newLawData.detail;
      return arr.every((value) => value.answerSelect === "준수");
    },
    defaultScore() {
      if (this.$route.name === "qsc-inspection-feedback") {
        let score =
          Number(this.data.hygieneTotal) +
          Number(this.data.lawTotal) +
          Number(this.data.serviceTotal);
        return score > 0 ? score : 0;
      } else {
        let score =
          Number(this.data.hygieneTotalCnt) +
          Number(this.data.lawTotalCnt) +
          Number(this.data.serviceTotalCnt);
        return score > 0 ? score : 0;
      }
    },
    rankCheck() {
      if (this.lawCheck === false) {
        return "D등급";
      } else if (this.totalScore >= 195) {
        return "S등급";
      } else if (this.totalScore >= 190) {
        return "A등급";
      } else if (this.totalScore >= 180) {
        return "B등급";
      } else if (this.totalScore >= 160) {
        return "C등급";
      } else {
        return "D등급";
      }
    },
  },
  created() {
    if (localStorage.getItem("managerType") === "팀장") {
      this.isMaster = true;
    } else {
      this.isMaster = false;
    }
  },
  mounted() {
    this.envTopSize =
      Number(
        getComputedStyle(document.getElementById("envTop"))
          .getPropertyValue("--safe-area-inset-top")
          .replace(/px/g, "")
      ) + 17;
    window.scrollTo(0, 0);
    if (this.$route.name === "qsc-inspection-feedback") {
      this.totalScore = this.data.totalScore;
      this.signLink = this.data.managerSign;
    }
    // console.log("중점 관리 사항", this.law);
    // console.log("일반관리", this.hygiene);
    // console.log("문서관리", this.service);
  },
  updated() {
    if (this.$route.name === "qsc-inspection-feedback") {
      this.totalScore = this.data.totalScore;
      this.signLink = this.data.managerSign;
    }
    // console.log("Prop데이터", this.data);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
@import "@/assets/scss/header.scss";
@import "@/assets/scss/qsc.scss";
.paddingBottom {
  padding-bottom: 200px;
}
.saveBtn {
  font-family: "Pretendard";
  border: none;
  background-color: white;
  text-align: right;
  position: absolute;
  right: 16px;
  &:disabled {
    color: $gray2;
  }
}
.bottomLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

#envTop {
  --safe-area-inset-top: env(safe-area-inset-top);
}
</style>
