<template>
    <div v-if="docData" style="width:100%">
        <div class="documentTitle">
            <div class="titleFont">{{ docData.title }}</div>
            <div class="dateFont">{{ docData.updateAt.slice(0,16) }}{{ docData.updateAt.slice(19) }}</div>
            <div v-if="docData.fileLink" class="flex_row" style="align-items:center;margin-top: 14px;">
                <img src="@/assets/link.svg" alt="link" style="width:12px;margin-right:3px"/>
                <a class="linkFont" :href="docData.fileLink" download>링크</a>
            </div>
        </div>
        <div class="documentContent">
            <div class="contentFont" v-html="docData.content"></div>
        </div>
    </div>
</template>

<script>
import { getDocumentDetail } from "@/api/DocumentAPI"

export default {
    data() {
    // state 저장하는 곳
    return {
      docData: null,
      parameter: this.$route.params.id
    }
  },
  methods: {
      getDocData(){
        getDocumentDetail(this.parameter)
        .then((res)=>{
            // console.log(res.data.result)
            this.docData = res.data.result
        })
        .catch((err)=>{
            console.log(err)
        })
      },
    },
    created(){
      this.getDocData()
    }
}
</script>


<style lang="scss">
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
    .documentTitle {
        padding: 16px;
        .titleFont {
            color: $black100;
            font-weight: 600;
            font-size: $fs18;
            line-height: 21.6px;
            letter-spacing: -2%;
            margin-bottom: 6px;
        }
        .dateFont {
            color: $black50;
            font-weight: 400;
            font-size: $fs12;
            line-height: 14.4px;
            letter-spacing: -1.3%;
        }
        .linkFont {
            color: $black32;
            font-weight: 500;
            font-size: $fs12;
            line-height: 14.4px;
            text-decoration: underline;
        }
    }
    .documentContent {
        max-width: 100%;
        padding: 16px 16px 32px;
        border-top: 1px solid $black8;
        .contentFont {
            max-width: 100%;
            color: $black80;
            font-weight: 400;
            font-size: $fs16;
            line-height: 24.48px;
            letter-spacing: -2%;  
            img {
                width: 100% !important;
                margin: 16px 0 !important;
            }
        }
    }
</style>