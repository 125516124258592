<template>
  <div class="flex_col padding">
    <div class="flex_col" style="gap: 24px">
      <BottomDropDown
        v-if="isMaster"
        type="소속을"
        :value="depart"
        @changeSelectValue="getSelectValue"
      ></BottomDropDown>
      <BottomDropDown
        type="지점을"
        :value="store"
        :departId="departId"
        @changeSelectValue="getSelectValue"
      ></BottomDropDown>
      <BottomDropDown
        type="점검표를"
        :value="reportTemplate"
        @changeSelectValue="getSelectValue"
      ></BottomDropDown>
    </div>
    <div style="margin-top: auto">
      <button
        type="submit"
        class="baseBtn fill"
        :disabled="!storeId || !reportTemplateId"
        @click="locationCheck(storeLat, storeLong)"
      >
        다음
      </button>
    </div>
    <CommonAlert
      :state="isShowAlert"
      @changeDisplay="getAlertDisplayState"
      @confirmAction="confirmAction"
    >
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #btn>
        {{ alertBtn }}
      </template>
    </CommonAlert>
  </div>
</template>

<script>
import BottomDropDown from '@/components/global/option/BottomDropDown.vue';
import CommonAlert from '@/components/global/alert/CommonAlert.vue';
export default {
  name: 'inspaction-add-page',
  components: {
    BottomDropDown,
    CommonAlert,
  },
  props: {},
  data() {
    return {
      isMaster: false,
      departId: null,
      storeId: null,
      reportTemplateId: null,
      depart: null,
      store: null,
      reportTemplate: null,
      locationCheckResult: true,
      myLat: null,
      myLong: null,
      storeLat: null,
      storeLong: null,
      isShowAlert: false,
      alertTitle: '위치 확인 불가',
      alertContent1:
        '점검지점이 1km 밖에 있습니다. 점검을 하시려면 해당 지점으로 이동해주세요.',
      alertBtn: '확인',
    };
  },
  methods: {
    getAlertDisplayState(value) {
      this.isShowAlert = value;
    },
    confirmAction() {
      this.isShowAlert = false;
    },
    getSelectValue(value, value2, type, lat, long) {
      if (type === '소속') {
        this.depart = value2;
        this.departId = value;
      } else if (type === '지점') {
        this.store = value2;
        this.storeId = value;
        this.storeLat = lat;
        this.storeLong = long;
      } else {
        this.reportTemplate = value2;
        this.reportTemplateId = value;
      }
    },
    locationCheck(storeLat, storeLong) {
      const showAlert = () => {
        this.isShowAlert = true;
      };
      const routingAction = () => {
        this.$router.push({
          name: 'qsc-inspection',
          params: {
            storeId: this.storeId,
            reportTemplateId: this.reportTemplateId,
          },
        });
      };
      function success(location) {
        let lat = location.coords.latitude;
        let long = location.coords.longitude;
        // console.log("내위치", "위도:", lat, "경도:", long);
        // console.log("매장위치", "위도:", storeLat, "경도:", storeLong);
        // 위도(lat) 1km = 0.0091 (37.497)
        // 경도(long) 1km = 0.0113 (127.0254)
        if (lat) {
          if (lat > storeLat + 0.0091 || lat < storeLat - 0.0091) {
            showAlert();
          } else {
            if (long > storeLong + 0.0113 || long < storeLong - 0.0113) {
              showAlert();
            } else {
              routingAction();
            }
          }
        }
      }
      function error() {
        alert('위치정보를 사용할 수 없습니다.');
      }

      if (location.protocol === 'http:') {
        routingAction();
      } else if (!navigator.geolocation) {
        alert('위치정보를 사용할 수 없습니다.');
      } else {
        navigator.geolocation.getCurrentPosition(success, error);
      }
    },
  },
  created() {
    if (localStorage.getItem('managerType') === '팀장') {
      this.isMaster = true;
    } else {
      this.isMaster = false;
      this.departId = 0;
      // console.log(this.departId)
    }
  },
  onMounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.fontStyle1 {
  color: $black100;
  font-size: $fs20;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -1.3%;
  margin-top: 21px;
  margin-bottom: 22px;
}
.padding {
  padding: 16px;
  height: calc(
    100vh - 58px - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  );
}
</style>
