<template>
  <div class="flex_col">
    <div class="titleFont">{{ title }}</div>
    <div v-if="disabled" class="dropDownBox disabled vaild">
      {{ value }}
    </div>
    <div
      v-else
      class="dropDownBox"
      @click="isSelected = !isSelected"
      :class="{ vaild: currentData || value !== `${type} 선택해주세요` }"
    >
      {{ value ? value : currentData }}
      <img src="@/assets/arrow4.svg" alt="" style="width: 20px" />
    </div>
    <div class="BG" v-if="isSelected" @click="isSelected = !isSelected">
      <div class="bottomOptionBG">
        <div class="handle"></div>
        <div v-if="name === 'learning'" class="flex_col overFlow">
          <div
            class="fontStyle1"
            v-for="value in learningData"
            :key="value"
            @click="currentData = value"
          >
            {{ value }}
          </div>
        </div>
        <div v-else-if="name === 'certificate'" class="flex_col overFlow">
          <div
            class="fontStyle1"
            v-for="value in certificateData"
            :key="value"
            @click="currentData = value"
          >
            {{ value }}
          </div>
        </div>
        <div v-else-if="title === '소속'" class="flex_col overFlow">
          <div
            class="fontStyle1"
            v-for="value in departData"
            :key="value.id"
            @click="selectFunc(title, value)"
          >
            {{ value.line }} {{ value.team }}
          </div>
        </div>
        <div v-else-if="title === '지점'" class="flex_col overFlow">
          <template v-if="!storeData">
            <div class="fontStyle1">소속을 선택해주세요.</div>
          </template>
          <template v-else>
            <div
              class="fontStyle1"
              v-for="value in storeData"
              :key="value.id"
              @click="selectFunc(title, value)"
            >
              {{ value.name }}
            </div>
          </template>
        </div>
        <div v-else class="flex_col overFlow">
          <div
            class="fontStyle1"
            v-for="value in templateData"
            :key="value.id"
            @click="selectFunc(title, value)"
          >
            {{ value.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDepart, getStores, getInspectionTemplate } from '@/api/StoreAPI';

export default {
  props: {
    value: { type: String, required: false },
    type: { type: String, required: true },
    name: { type: String, required: false },
    disabled: { type: Boolean, required: false, default: false },
    pb: { type: Number, require: false, default: 0 },
    departId: { type: Number, require: false },
  },
  components: {},
  data() {
    return {
      title: this.type.slice(0, this.type.length - 1),
      isSelected: false,
      currentData: `${this.type} 선택해주세요`,
      currentID: null,
      storeLat: null,
      storeLong: null,
      departData: null,
      storeData: null,
      templateData: null,
      learningData: ['위생교육', '산업안전교육'],
      certificateData: [
        '영양사식품위생교육',
        '조리사위생교육',
        '신규/기존영업자위생교육',
      ],
    };
  },
  methods: {
    selectFunc(title, value) {
      // console.log("selectFunc", title, value);
      if (title === '소속') {
        this.currentID = value.id;
        this.currentData = `${value.line} ${value.team}`;
      } else if (title === '지점') {
        this.currentID = value.id;
        this.currentData = value.name;
        this.storeLat = value.latitude;
        this.storeLong = value.longitude;
      } else {
        this.currentID = value.id;
        this.currentData = value.title;
      }
    },
    getDepartList() {
      getDepart()
        .then((res) => {
          // console.log("소속리스트", res.data.result.list);
          this.departData = res.data.result.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStoreList() {
      getStores(this.departId)
        .then((res) => {
          // console.log("지점리스트", res.data.result.list);
          this.storeData = res.data.result.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTemplateList() {
      getInspectionTemplate()
        .then((res) => {
          // console.log("템플릿리스트", res.data.result.list);
          this.templateData = res.data.result.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    currentData: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        if (this.title === '지점') {
          this.$emit(
            'changeSelectValue',
            this.currentID,
            this.currentData,
            this.title,
            this.storeLat,
            this.storeLong
          );
        } else if (this.title === '소속' || this.title === '점검표') {
          this.$emit(
            'changeSelectValue',
            this.currentID,
            this.currentData,
            this.title
          );
        } else {
          this.$emit('changeSelectValue', this.currentData, this.title);
        }
      },
    },
    departId: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        // console.log("departId",this.departId)
        this.getStoreList();
      },
    },
  },
  created() {
    if (this.$route.name === 'inspaction-add-page') {
      this.getDepartList();
      this.getTemplateList();
      if (localStorage.getItem('managerType') === '점장') {
        this.getStoreList();
      }
      // console.log(this.value)
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.titleFont {
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  color: $black70;
  letter-spacing: -1.3%;
  margin-bottom: 8px;
}
.dropDownBox {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  color: $black28;
  letter-spacing: -1.3%;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid $black28;
  height: 42px;
}
.fontStyle1 {
  font-size: $fs16;
  font-weight: 500;
  line-height: 19.2px;
  color: $black100;
  letter-spacing: -1.3%;
  margin: 0;
  padding: 14px 0;
}
.overFlow {
  max-height: 210px;
  overflow-y: scroll;
}
.vaild {
  color: $black100;
}
</style>
