<template>
  <div class="flex_col">
    <!-- 점검자 피드백 -->
    <!-- 점검자 피드백 : 수정/등록인 경우 -->
    <template v-if="type === 1 || type === 2">
      <div class="flex_col inspectionLine" style="margin-top: 16px">
        <div class="inspectionFeedbackFont" style="margin-bottom: 8px">
          점검자 피드백
        </div>
        <input
          class="inspectionInput"
          type="text"
          placeholder="피드백을 작성해주세요"
          :value="feedback"
          @input="feedbackContentChange($event.target.value)"
        />
      </div>
    </template>
    <!-- 점검자 피드백 : 결과인 경우 -->
    <template v-else>
      <div
        v-if="currentData.feedback.length > 0"
        class="inspectionFeedback"
        style="margin-top: 16px"
      >
        <div
          class="inspectionFeedbackFont"
          style="margin-bottom: 8px; padding-left: 0"
        >
          점검자 피드백
        </div>
        {{ currentData.feedback }}
      </div>
    </template>
    <!-- 점검자 피드백 : 수정/등록인 경우 이미지 수정 및 추가 -->
    <template v-if="type === 1 || type === 2">
      <div class="flex_row flex_between" style="margin-top: 17px">
        <div class="flex_col">
          <input
            v-if="isNotPackagingAndroidApp"
            type="file"
            accept="image/*;camera"
            :id="index"
            @change="getImageLinkforServer($event)"
          />
          <div class="titleFont" style="margin-bottom: 2px">
            이미지 업로드 <span class="burgundy">{{ imgArray.length }}/5</span>
          </div>
          <div class="fontStyle1">500mb이하만 올릴 수 있습니다.</div>
        </div>
        <label class="loadBtn" :for="index" @click="uploadFile">
          <img
            src="@/assets/addfile.svg"
            alt="upload"
            style="width: 18px; margin-right: 8px"
          />
          파일찾기
        </label>
      </div>
      <div
        v-if="imgArray.length > 0"
        class="flex_row inspectionLine"
        style="gap: 16px; margin-top: 8px"
      >
        <div v-for="(img, i) in imgArray" :key="img.id" class="feedImageStyle">
          <img :src="img" class="feedImageStyle" @click="bigImageView(img)" />
          <img
            src="@/assets/close6.svg"
            class="feedImageDelete"
            @click="delImg(i)"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-if="currentData.feedbackImgList.length > 0"
        class="flex_row inspectionLine"
        style="gap: 16px; margin-top: 8px"
      >
        <div
          v-for="img in currentData.feedbackImgList"
          :key="img.id"
          class="feedImageStyle"
        >
          <img :src="img" class="feedImageStyle" @click="bigImageView(img)" />
        </div>
      </div>
    </template>

    <!-- 나의 피드백 -->
    <!-- 나의 피드백 : 수정/등록인 경우 -->
    <template v-if="type === 4">
      <div class="flex_col inspectionLine" style="margin-top: 8px">
        <div
          class="flex_row"
          style="gap: 8px; align-items: center; margin-bottom: 8px"
        >
          <img src="@/assets/reply.svg" alt="reply" style="width: 14px" />
          <div
            class="inspectionFeedbackFont"
            style="margin-bottom: 0; padding-top: 2px"
          >
            나의 피드백 {{ today() }}
          </div>
        </div>
        <input
          class="inspectionInput"
          type="text"
          placeholder="피드백을 작성해주세요"
          :value="feedbackOfFeedback"
          @input="feedbackOfFeedbackContentChange($event.target.value)"
        />
      </div>
      <div class="flex_row flex_between" style="margin-top: 17px">
        <div class="flex_col">
          <input
            v-if="isNotPackagingAndroidApp"
            type="file"
            accept="image/*;camera"
            :id="index"
            @change="getImageLinkforServerFF($event)"
          />
          <div class="titleFont" style="margin-bottom: 2px">
            이미지 업로드
            <span class="burgundy">{{ imgArrayFF.length }}/5</span>
          </div>
          <div class="fontStyle1">500mb이하만 올릴 수 있습니다.</div>
        </div>
        <label class="loadBtn" :for="index" @click="uploadFile">
          <img
            src="@/assets/addfile.svg"
            alt="upload"
            style="width: 18px; margin-right: 8px"
          />
          파일찾기
        </label>
      </div>
      <div
        v-if="imgArrayFF.length > 0"
        class="flex_row inspectionLine"
        style="gap: 16px; margin-top: 8px"
      >
        <div
          v-for="(img, i) in imgArrayFF"
          :key="img.id"
          class="feedImageStyle"
        >
          <img :src="img" class="feedImageStyle" @click="bigImageView(img)" />
          <img
            src="@/assets/close6.svg"
            class="feedImageDelete"
            @click="delImgFF(i)"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <!-- 나의 피드백 : 결과인 경우 -->
      <div
        v-if="currentData.feedbackOfFeedback.length > 0"
        class="flex_col inspectionFeedbackReply"
      >
        <div class="flex_row" style="gap: 8px; align-items: center">
          <img src="@/assets/reply.svg" alt="reply" style="width: 14px" />
          <div class="titleFont" style="padding-top: 4px">나의 피드백</div>
          <div class="fontStyle2">
            {{ currentData.feedbackOfFeedbackWriteDate }}
          </div>
        </div>
        <div class="fontStyle3">
          {{ currentData.feedbackOfFeedback }}
        </div>
      </div>
      <div
        v-if="currentData.feedbackOfFeedbackImgList.length > 0"
        class="flex_row inspectionLine"
        style="gap: 16px; margin-top: 8px"
      >
        <div
          v-for="img in currentData.feedbackOfFeedbackImgList"
          :key="img.id"
          class="feedImageStyle"
        >
          <img :src="img" class="feedImageStyle" @click="bigImageView(img)" />
        </div>
      </div>
    </template>
    <!-- 이미지 확대 -->
    <div v-if="bigView" class="BG">
      <div class="imageBox">
        <img :src="bigView" width="100%" height="100%" />
      </div>
      <div class="delImgBtn">
        <img src="@/assets/close7.svg" @click="closeBigImag" />
      </div>
    </div>
  </div>
</template>

<script>
import { getImageLink } from '@/api/StoreAPI';
import returnToday from '@/functions/returnToday';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';

export default {
  props: {
    currentData: { type: Object, require: true },
    imageArray: { type: Array, require: true },
    imageArrayFF: { type: Array, require: true },
    index: { type: String, require: true },
  },
  data() {
    return {
      bigView: false,
      type: null,
      state: null,
      imgArray: this.imageArray,
      imgArrayFF: this.imageArrayFF,
      feedback: '',
      feedbackOfFeedback: '',
    };
  },
  methods: {
    bigImageView(img) {
      // console.log(img);
      this.bigView = img;
    },
    closeBigImag() {
      this.bigView = false;
    },
    today() {
      return returnToday();
    },
    getImageLinkforServer(e) {
      // console.log(e);
      if (this.imgArray.length >= 5) {
        alert('사진은 최대 5장까지만 업로드 가능합니다.');
      } else {
        let image = e.target.files[0];
        const formData = new FormData();
        formData.append('file', image);
        const imageData = formData;
        getImageLink(imageData)
          .then((res) => {
            // console.log(res);
            if (res.data.isSuccessful) {
              this.addImage(res.data.result);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getImageLinkforServerFF(e) {
      if (this.imgArrayFF.length >= 5) {
        alert('사진은 최대 5장까지만 업로드 가능합니다.');
      } else {
        let image = e.target.files[0];
        const formData = new FormData();
        formData.append('file', image);
        const imageData = formData;
        getImageLink(imageData)
          .then((res) => {
            if (res.data.isSuccessful) {
              this.addImageFF(res.data.result);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addImage(link) {
      let arr = [...this.imgArray];
      arr.push(link);
      this.imgArray = arr;
      this.$emit('feedbackImgChange', this.imgArray);
    },
    delImg(index) {
      let arr = [...this.imgArray];
      arr.splice(index, 1);
      this.imgArray = arr;
      this.$emit('feedbackImgChange', this.imgArray);
    },
    addImageFF(link) {
      let arr = [...this.imgArrayFF];
      arr.push(link);
      this.imgArrayFF = arr;
      this.$emit('feedbackImgChangeFF', this.imgArrayFF);
    },
    delImgFF(index) {
      let arr = [...this.imgArrayFF];
      arr.splice(index, 1);
      this.imgArrayFF = arr;
      this.$emit('feedbackImgChangeFF', this.imgArrayFF);
    },
    feedbackContentChange(value) {
      this.feedback = value;
      this.$emit('feedbackContent', this.feedback);
    },
    feedbackOfFeedbackContentChange(value) {
      this.feedbackOfFeedback = value;
      this.$emit('feedbackOfFeedbackContent', this.feedbackOfFeedback);
    },
    async uploadFile(isFF) {
      if (this.isNotPackagingAndroidApp) {
        return;
      }

      let arr = isFF ? this.imgArray : this.imgArrayFF;
      let addImageFunc = isFF ? this.addImage : this.addImageFF;

      if (arr.length >= 5) {
        alert('사진은 최대 5장까지만 업로드 가능합니다.');
      } else {
        const image = await Camera.getPhoto({
          quality: 90,
          source: 'PROMPT',
          resultType: CameraResultType.Base64,
          promptLabelHeader: '이미지 불러오기',
          promptLabelPhoto: '갤러리',
          promptLabelPicture: '사진 촬영',
        });

        let base64 = await fetch(
          `data:image/jpeg;base64,${image.base64String}`
        );
        let blob = await base64.blob();
        let newFile = new File([blob], `${Date.now()}.jpeg`);

        const formData = new FormData();
        formData.append('file', newFile);
        const imageData = formData;
        getImageLink(imageData)
          .then((res) => {
            if (res.data.isSuccessful) {
              addImageFunc(res.data.result);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  created() {
    // console.log("imageArray",this.imageArray)
    // console.log("imageArrayFF",this.imageArrayFF)
    // console.log("bigView", this.bigView);
    // console.log("currentData", this.currentData);
    if (this.$route.name === 'qsc-inspection') {
      // qsc 등록일 경우: type 1
      this.type = 1;
      // console.log("등록페이지");
    } else if (
      this.$route.name === 'qsc-inspection-edit' ||
      this.$route.name === 'qsc-temporary-edit'
    ) {
      // qsc 수정일 경우: type 2
      this.type = 2;
      this.feedback = this.currentData.feedback;
      this.imgArray = this.currentData.feedbackImgList;
      // console.log("수정페이지", this.currentData);
    } else if (this.$route.name === 'qsc-inspection-detail') {
      // qsc 상세일 경우: type 3
      this.type = 3;
      // console.log("상세페이지", this.currentData);
    } else if (this.$route.name === 'qsc-inspection-feedback') {
      // qsc 피드백페이지 일 경우: type 4
      this.type = 4;
      this.feedbackOfFeedback = this.currentData.feedbackOfFeedback;
      this.imgArrayFF = this.currentData.feedbackOfFeedbackImgList;
      // console.log("피드백작성페이지", this.currentData);
    }
  },
  watch: {
    currentData: {
      handler: function () {
        // console.log("피드백여기참고", this.currentData);
      },
    },
    bigView: {
      handler: function () {
        // console.log("bigView", this.bigView);
      },
    },
  },
  computed: {
    isNotPackagingAndroidApp() {
      return !(
        Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
@import '@/assets/scss/qsc.scss';
.titleFont {
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  color: $black70;
  letter-spacing: -1.3%;
  margin-bottom: 4px;
}
.fontStyle1 {
  font-size: $fs10;
  font-weight: 400;
  line-height: 12px;
  color: $black50;
  letter-spacing: -2%;
}
.fontStyle2 {
  font-size: $fs12;
  font-weight: 400;
  color: $black50;
  letter-spacing: -1.3%;
  line-height: 14.4px;
}
.fontStyle3 {
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  color: rgba(0, 85, 164, 1);
  letter-spacing: -1.3%;
  margin-top: 4px;
  margin-left: 22px;
}
.loadBtn {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-size: $fs13;
  font-weight: 600;
  line-height: 15.6px;
  color: $black100;
  letter-spacing: -2%;
  border: 1px solid $black8;
  border-radius: 6px;
}
.imageBox {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.delImgBtn {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
