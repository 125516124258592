<template>
    <div class="flex_col scroll">
      <StoreOptionButton :selected="isParam" @changeOption="getOptionState"></StoreOptionButton>
      <InspectionButton :state="isParam"></InspectionButton>
      <!-- option에 따라 아래 항목 render -->
        <InspectionDashBoard v-if="isParam==='dash'"></InspectionDashBoard>
        <InspectionReport v-else-if="isParam==='report'"></InspectionReport>
    </div>
</template>

<script>
import StoreOptionButton from "@/components/global/option/StoreOptionButton.vue";
import InspectionDashBoard from "@/components/inspection/InspectionDashBoard.vue"
import InspectionReport from "@/components/inspection/InspectionReport.vue"
import InspectionButton from "@/components/inspection/InspectionButton.vue"

export default {
  name: 'inspection-list',
  components: {
    StoreOptionButton,
    InspectionDashBoard,
    InspectionReport,
    InspectionButton,
  },
  props: {
  },
  data () {
    return {
      isParam: this.$route.path==='/inspection/dashboard'?'dash':'report',
      isMenu: false,
    }
  },
  methods: {
    getOptionState(value) {
      this.isParam = value
    }
  },
  created(){
      // console.log(this.$route.hash)
      // console.log(this.isParam)
  },
  watch: {
    '$route' () {
      if(this.$route.path==='/inspection/dashboard'){
      // console.log(this.$route.path)
        this.isParam = 'dash'
      } else {
        this.isParam = 'report'
      // console.log(this.$route.path)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variable.scss';
  @import '@/assets/scss/component.scss';
    .fontStyle2 {
        color: $black28;
        font-size: $fs12;
        font-weight: 500; 
        line-height: 14.4px;
        letter-spacing: -1.3%;
        margin-top: 8px;
    }
</style>