<template>
  <div>
    <div v-if="qscData&&qscData.length > 0" class="flex_col">
      <div v-for="(data,index) in qscData" :key="data.id" class="flex_col listStyle" :class="{'border':qscData.length-1 !== index}" @click="optionState=`${data.id}`">
        <div class="flex_row flex_between">
          <div class="fontStyle1">
            {{ data.team }}, {{ data.storeName }}, {{ data.templateTitle }}
          </div>
          <img src="@/assets/more-btn-dot.svg" alt="" style="width:20px"/>
        </div>
        <div class="fontStyle2">
          {{ data.updateAt.slice(0,16) }}
        </div>
      </div>
    </div>
    <BottomOptionList :displayState="optionState" :type="optionState" @modiAction="modiAction" @deleteAction="deleteAction"></BottomOptionList>
    <CommonAlertTwoBtn :state="isShowAlert" @changeDisplay="getAlertDisplayState" @confirmAction1="confirmAction1" @confirmAction2="confirmAction2">
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #content2>
        {{ alertContent2 }}
      </template>
      <template #btn1>
        {{ alertBtn1 }}
      </template>
      <template #btn2>
        {{ alertBtn2 }}
      </template>
    </CommonAlertTwoBtn>
    <ToastMsg :message="toastMassage" :display="toastDisplay"></ToastMsg>
  </div>
</template>

<script>
import { getInspectionList , deleteInspection } from "@/api/StoreAPI"
import BottomOptionList from "@/components/global/option/BottomOptionList.vue"
import CommonAlertTwoBtn from "@/components/global/alert/CommonAlertTwoBtn.vue"
import ToastMsg from "@/components/global/alert/ToastMsg.vue"
export default {
  name: 'inspaction-temporary-list',
  components: {
    BottomOptionList,
    CommonAlertTwoBtn,
    ToastMsg,
  },
  props: {
  },
  data () {
    return {
        id: null,
        optionState: '',
        qscData: null,
        curPage: 1,
        isShowAlert: false,
        alertTitle:'임시저장 삭제',
        alertContent1:'한번 삭제된 데이터는 복구하실 수 없습니다.',
        alertContent2:'정말로 삭제하시겠습니까?',
        alertBtn1:'취소',
        alertBtn2:'삭제',
        toastDisplay: false,
        toastMassage: ''
    }
  },
  computed: {
      params(){
        let parameter = Object.entries({
            curPage: this.curPage,
            keyword: '',
            orderBy: '최신순',
            isShow: '0',
            categoryDateStart: '',
            categoryDateEnd: '',
            categoryType: '',
            categoryGrade: ''
          }).map((e) => e.join('='))
            .join('&');
        return parameter;
      },
  },
  methods: {
    getTemporaryList(){
      getInspectionList(this.params)
      .then((res)=>{
        // console.log(res.data.result)
          this.qscData = res.data.result.list
      })
      .catch((err)=>{
          console.log(err)
      })
    },
    modiAction(value){
      // console.log("수정하기",value)
      this.$router.replace(
          {
            path: `/qsc/temporary/${value}`, 
            params: { id: value }
          })
    },
    deleteAction(value){
      this.isShowAlert = true
      this.optionState = value
    },
    getAlertDisplayState(value){
        this.isShowAlert = value
    },
    confirmAction1(){
      this.isShowAlert = false
    },
    confirmAction2(){
      deleteInspection(this.optionState)
      .then((res)=>{
            if(res.data.isSuccessful){
              this.toastDisplay = true
              this.toastMassage = "삭제되었습니다"
              setTimeout(() => {
              this.toastDisplay = false
              this.toastMassage = ""
              }, 1000);
              this.getTemporaryList()
            }
        })
        .catch((err)=>{
            console.log(err)
        })

    }
  },
  created(){
    this.getTemporaryList()
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variable.scss';
  @import '@/assets/scss/component.scss';
  .listStyle {
    padding: 16px;
  }
  .border {
    border-bottom: 1px solid $black8;
  }
  .fontStyle1 {
    color: $black80;
    font-size: $fs14;
    font-weight: 500; 
    line-height: 16.8px;
    letter-spacing: -1.3%;
  }
  .fontStyle2 {
    color: $black50;
    font-size: $fs12;
    font-weight: 400; 
    line-height: 14.4px;
    letter-spacing: -1.3%;
  }
</style>