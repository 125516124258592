<template>
  <div>
    <div v-if="category==='learning'" class="flex_col flex_center btnWrap" @click="$router.push({name:'learning-add-page'})">
      <img src="@/assets/add.svg" alt="" style="width:16px;margin-bottom:4px"/>
      교육일지
    </div>
    <div v-else class="flex_col flex_center btnWrap" @click="$router.push({name:'certificate-add-page'})">
      <img src="@/assets/add.svg" alt="" style="width:16px;margin-bottom:4px"/>
      수료증
    </div>
  </div>
</template>
  
  <script>
  export default {
    props: {
      category: { type: String, required: true },
    },
  }
  </script>
  
  
  <style lang="scss" scoped>
    @import '@/assets/scss/variable.scss';
    @import '@/assets/scss/component.scss';
    .btnWrap {
      width: 62px;
      height: 62px;
      border-radius: 100%;
      position: fixed;
      bottom: 40px;
      right: 24px;
      background-color: $burgundy;
      color: $white100;
      font-size: $fs12;
      font-weight: 500; 
      line-height: 14.4px;
      letter-spacing: -1.3%;
    }
  </style>