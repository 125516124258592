<template>
  <div class="listScore" v-if="data">
    {{ data.total_score }}점/
    <span class="gray">200점</span>
    <span class="bar">|</span>
    피드백 {{ data.feedbackCnt }}건
    <span class="bar">|</span>
    <span class="burgundy">
      처리 {{ data.feedbackOfFeedbackCnt }}건
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, require: true }
  },
}
</script>


<style lang="scss" scoped>
  @import '@/assets/scss/variable.scss';
  @import '@/assets/scss/component.scss';
  .listScore {
    color: $black100;
    font-size: $fs10;
    font-weight: 400; 
    line-height: 12px;
    letter-spacing: -2%;
  }
  .gray {
    color: $black50;
  }
  .bar {
    display: inline-block;
    margin: 0 2px;
    font-size: $fs10;
    color: $black8;
  }
  .burgundy {
    color: $burgundy;
  }
</style>