import API from "@/api/BaseUrl";

// 최근 방문 지점 조회
export async function getRecentStoreList() {
    return API.get(`home/store`)
}
// 공지사항 조회
export async function getMainNoticeList() {
    return API.get(`home/notice`)
}
// 가까운 일정 조회
export async function getScheduleList() {
    return API.get(`home/calendar`)
}
// 팝업
export async function getPopUpList() {
    return API.get(`home/popup`)
}
