<template>
  <div v-if="type === 'detail'" class="optionWrap sticky">
    <div
      class="fontStyle1"
      :class="{ optionActive: state === 'qsc' }"
      @click="changeState('qsc')"
    >
      QSC점검
    </div>
    <div
      class="fontStyle1"
      :class="{ optionActive: state === 'clean' }"
      @click="changeState('clean')"
    >
      위생교육
    </div>
    <div
      class="fontStyle1"
      :class="{ optionActive: state === 'safety' }"
      @click="changeState('safety')"
    >
      산업안전교육
    </div>
    <div
      class="fontStyle1"
      :class="{ optionActive: state === 'feedback' }"
      @click="changeState('feedback')"
    >
      피드백
    </div>
  </div>
  <div v-else class="optionWrap2">
    <div>
      <div
        style="width:fit-content;margin:auto"
        class="fontStyle1"
        :class="{ optionActive: state === 'dash' }"
        @click="inspectionRouter('dash')"
      >
        대시보드
      </div>
    </div>
    <div style="width:100%">
      <div
        style="width:fit-content;margin:auto"
        class="fontStyle1"
        :class="{ optionActive: state === 'report' }"
        @click="inspectionRouter('report')"
      >
        리포트 작성/조회
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    type: { type: String, required: false },
    selected: { type: String, required: false, default: "dash" },
  },
  data() {
    return {
      state: this.selected,
    };
  },
  methods: {
    inspectionRouter(value) {
      this.state = value;
      this.$emit("changeOption", this.state);
      if (value === "dash") {
        this.$router.push({
          path: "/inspection/dashboard",
        });
      }
      if (value === "report") {
        this.$router.push({
          path: "/inspection/report",
        });
      }
    },
    changeState(value) {
      this.state = value;
      this.$emit("changeOption", this.state);
    },
  },
  watch: {
    $route() {
      if (this.$route.path === "/inspection/dashboard") {
        // console.log(this.$route.path)
        this.state = "dash";
      } else {
        // console.log(this.$route.path);
        this.state = "report";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
.optionWrap {
  background-color: $white100;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 43px;
  border-bottom: 1px solid $black8;
}
.optionWrap2 {
  background-color: $white100;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 43px;
  border-bottom: 1px solid $black8;
}
.sticky {
  position: sticky;
  top: 0px;
}
.fontStyle1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  color: $black32;
  font-size: $fs16;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: -1.3%;
}
.optionActive {
  margin-top: 1px;
  color: $black100;
  font-weight: 600;
  border-bottom: 2px solid $burgundy;
}
</style>
