<template>
  <div class="headerWrap" :class="{ noBorder: isLoginPage }">
    <div
      class="flex_center"
      style="width: 50px; height: 24px"
      @click="movePage"
    >
      <img src="@/assets/arrow.svg" alt="" width="24px" height="24px" />
    </div>

    <div class="headerTitle">
      {{
        $route.name === 'inspaction-add-page' ||
        $route.name === 'qsc-inspection'
          ? isMaster
            ? 'QSC 점검하기'
            : 'QSC 자가점검하기'
          : $route.meta.title
      }}
    </div>

    <!-- NotiBtn or textBtn -->
    <div
      v-if="isAlarmShow"
      class="flex_center headerNotiBtn"
      style="width: 50px; height: 24px"
      @click="$router.push({ name: 'alarm-list' })"
    >
      <div v-if="isAlarm" class="alarm"></div>
      <img src="@/assets/noti.svg" alt="" width="24px" height="24px" />
    </div>
    <div
      v-else-if="buttonText"
      class="flex_center headerTextBtn"
      style="padding-right: 8px"
      @click="textBtnClick"
    >
      {{ buttonText }}
    </div>
    <div v-else class="headerTextBtn"></div>
  </div>
</template>
<script>
import { getIsAlarm } from '@/api/DocumentAPI';

export default {
  name: 'default-header',
  data() {
    return {
      isMaster: false,
      isLoginPage: false,
      isAlarmShow: true,
      isAlarm: null,
      buttonText: null,
    };
  },
  methods: {
    movePage() {
      if (this.$route.name === 'alarm-list') {
        this.$router.push('/');
      } else {
        this.$router.go(-1);
      }
    },
    textBtnClick() {
      if (this.$route.name === 'my-schedule') {
        this.$router.push({ path: '/my-schedule/add' });
      } else if (this.$route.name === 'my-schedule-detail') {
        this.$router.push({
          path: `/my-schedule/edit/${this.$route.params.id}`,
          params: {
            id: this.$route.params.id,
          },
        });
      } else if (this.$route.name === 'learning-detail') {
        this.$router.push({
          path: `/learning/edit/${this.$route.params.id}`,
          params: {
            id: this.$route.params.id,
          },
        });
      } else if (this.$route.name === 'certificate-detail') {
        this.$router.push({
          path: `/certificate/edit/${this.$route.params.id}`,
          params: {
            id: this.$route.params.id,
          },
        });
      }
    },
    getIsAlarmCheck() {
      getIsAlarm()
        .then((res) => {
          if (res.data.isSuccessful) {
            if (res.data.result.checkCnt) {
              this.isAlarm = true;
            } else {
              this.isAlarm = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    $route() {
      // console.log(this.$route);
      // console.log(this.$router);
      if (
        this.$route.name === 'password-find' ||
        this.$route.name === 'password-reset' ||
        this.$route.name === 'password-change'
      ) {
        this.isLoginPage = true;
        this.isAlarmShow = false;
      } else {
        this.isLoginPage = false;
        this.isAlarmShow = false;
        if (this.$route.name === 'my-schedule') {
          this.buttonText = '추가';
        } else if (this.$route.name === 'my-schedule-detail') {
          this.buttonText = '';
        } else if (this.$route.name === 'my-schedule-add') {
          this.buttonText = '';
        } else if (this.$route.name === 'qsc-inspection-feedback') {
          this.buttonText = '';
        } else if (
          this.$route.name === 'learning-detail' ||
          this.$route.name === 'certificate-detail'
        ) {
          this.buttonText = '수정';
        } else {
          this.buttonText = '';
        }
        this.getIsAlarmCheck();
      }
    },
  },
  created() {
    if (localStorage.getItem('managerType') === '팀장') {
      this.isMaster = true;
    } else {
      this.isMaster = false;
    }
    // console.log(this.$route);
    if (
      this.$route.name === 'password-find' ||
      this.$route.name === 'password-reset' ||
      this.$route.name === 'password-change'
    ) {
      this.isLoginPage = true;
      this.isAlarmShow = false;
    } else {
      this.isLoginPage = false;
      this.isAlarmShow = false;
      if (this.$route.name === 'my-schedule') {
        this.buttonText = '추가';
      } else if (this.$route.name === 'my-schedule-detail') {
        this.buttonText = '';
      } else if (this.$route.name === 'my-schedule-add') {
        this.buttonText = '';
      } else if (this.$route.name === 'qsc-inspection-feedback') {
        this.buttonText = '';
      } else if (
        this.$route.name === 'learning-detail' ||
        this.$route.name === 'certificate-detail'
      ) {
        this.buttonText = '수정';
      } else {
        this.buttonText = '';
      }
      this.getIsAlarmCheck();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/header.scss';
// .fixed {
//   width: 100%;
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
// }
</style>
