<template>
  <div v-if="filterData.length > 0" id="schedule-list" style="width: 100%">
    <div
      v-for="data in filterData"
      :key="data.workAt"
      class="flex_col"
      style="padding: 0 16px"
    >
      <div class="dayFont" name="">
        {{ data.workAt.slice(8, 10) }}일, {{ transferDay(data.workAt) }}
        {{ data.isToday ? '(오늘)' : '' }}
      </div>
      <div
        v-for="detailData in data.data"
        :key="detailData.id"
        :id="data.workAt"
        @click="
          $router.push({
            path: `/my-schedule/${detailData.id}`,
            params: {
              id: detailData.id,
            },
          })
        "
      >
        <div
          v-if="!detailData.workTime"
          class="flex_col"
          style="padding: 16px 0"
        >
          <div class="flex_row flex_between">
            <div class="flex_row" style="gap: 12px; align-items: center">
              <img
                src="@/assets/ellipse-fill.svg"
                alt=""
                style="width: 8px; height: 8px"
              />
              <h4 class="dateStyle allDay">하루 종일</h4>
            </div>
            <img
              src="@/assets/more-btn-dot.svg"
              alt="more"
              style="width: 20px; height: 20px"
              @click.stop="optionState = `${detailData.id}`"
            />
          </div>
          <div class="titleStyle">
            {{ detailData.title }}
          </div>
          <div class="detailStyle">
            {{ detailData.content }}
          </div>
        </div>
        <div v-else class="flex_col" style="padding: 16px 0">
          <div class="flex_row flex_between">
            <div class="flex_row" style="gap: 12px; align-items: center">
              <img
                src="@/assets/ellipse-empty.svg"
                alt=""
                style="width: 8px; height: 8px"
              />
              <h4 class="dateStyle">
                {{ detailData.workTime.slice(0, 5)
                }}{{ detailData.workTime.slice(8) }}
              </h4>
            </div>
            <img
              src="@/assets/more-btn-dot.svg"
              alt="more"
              style="width: 20px; height: 20px"
              @click.stop="optionState = `${detailData.id}`"
            />
          </div>
          <div class="titleStyle">
            {{ detailData.title }}
          </div>
          <div class="detailStyle">
            {{ detailData.content }}
          </div>
        </div>
      </div>
    </div>
    <BottomOptionList
      :displayState="optionState"
      :type="optionState"
      @modiAction="modiAction"
      @deleteAction="deleteAction"
    ></BottomOptionList>
    <CommonAlertTwoBtn
      :state="isShowAlert"
      @changeDisplay="getAlertDisplayState"
      @confirmAction1="confirmAction1"
      @confirmAction2="confirmAction2"
    >
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #content2>
        {{ alertContent2 }}
      </template>
      <template #btn1>
        {{ alertBtn1 }}
      </template>
      <template #btn2>
        {{ alertBtn2 }}
      </template>
    </CommonAlertTwoBtn>
    <ToastMsg :message="toastMassage" :display="toastDisplay"></ToastMsg>
  </div>
</template>

<script>
import { delSchedule } from '@/api/SchedulAPI';
import BottomOptionList from '@/components/global/option/BottomOptionList.vue';
import CommonAlertTwoBtn from '@/components/global/alert/CommonAlertTwoBtn.vue';
import ToastMsg from '@/components/global/alert/ToastMsg.vue';
import transferDay from '@/functions/transferDay';

export default {
  props: {
    Scheduledata: { type: Array, required: false },
    isReset: { type: Boolean, required: false },
  },
  components: {
    BottomOptionList,
    CommonAlertTwoBtn,
    ToastMsg,
  },
  data() {
    return {
      data: null,
      optionState: '',
      todayScroll: null,
      isResetValue: this.isReset,
      isShowAlert: false,
      alertTitle: '일정 삭제',
      alertContent1: '한번 삭제된 일정은 복구하실 수 없습니다.',
      alertContent2: '정말로 삭제하시겠습니까?',
      alertBtn1: '취소',
      alertBtn2: '삭제',
      toastDisplay: false,
      toastMassage: '',
    };
  },
  methods: {
    scrollToToday() {
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth() + 1;
      let date = today.getDate();
      let transToday = `${year}-${month > 9 ? month : '0' + month}-${
        date > 9 ? date : '0' + date
      }`;
      if (transToday) {
        let todayData = document.getElementById(`${transToday}`);

        if (todayData) {
          window.scrollTo({
            top:
              todayData.offsetTop -
              172 -
              Number(
                getComputedStyle(document.getElementById('schedule-list'))
                  .getPropertyValue('--safe-area-inset-top')
                  .replace(/px/g, '')
              ),
            behavior: 'smooth',
          });
        }
      }
    },

    changeResetState() {
      this.isResetValue = true;
      this.$emit('changeResetState', this.isResetValue);
      this.isResetValue = false;
    },
    getAlertDisplayState(value) {
      this.isShowAlert = value;
    },
    confirmAction1() {
      this.isShowAlert = false;
      this.optionState = '';
    },
    confirmAction2() {
      delSchedule(this.optionState)
        .then((res) => {
          if (res.data.isSuccessful) {
            this.toastDisplay = true;
            this.toastMassage = '삭제되었습니다';
            setTimeout(() => {
              this.toastDisplay = false;
              this.toastMassage = '';
            }, 1000);
            this.changeResetState();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.optionState = '';
        });
    },
    transferDay(value) {
      return transferDay(value);
    },
    modiAction(value) {
      // console.log("수정하기",value)
      this.$router.push({
        path: `/my-schedule/edit/${value}`,
        params: {
          id: value,
        },
      });
    },
    deleteAction(value) {
      // console.log("삭제하기",value)
      this.isShowAlert = true;
      this.optionState = value;
    },
  },
  mounted() {
    this.scrollToToday();
  },
  computed: {
    filterData() {
      let curData = this.Scheduledata;
      let changeData = [];
      curData.forEach((v) => {
        if (changeData.find((val) => val.workAt === v.workAt)) {
          changeData[
            changeData.findIndex((val) => val.workAt === v.workAt)
          ].data.push(v);
        } else {
          changeData.push({
            workAt: v.workAt,
            data: [v],
          });
        }
      });
      return changeData;
    },
  },
  watch: {
    todayScroll: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        if (this.todayScroll) {
          this.scrollToToday();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.dayFont {
  color: $black50;
  font-weight: 500;
  font-size: $fs12;
  line-height: 14px;
  letter-spacing: -0.013em;
  padding-top: 24px;
}
.mainScheduleList {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
}
.titleStyle {
  color: $black80;
  font-size: $fs16;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: -1.3%;
  margin: 0;
  margin-left: 20px;
  margin-top: 6px;
}
.detailStyle {
  color: $black50;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
  margin: 0;
  margin-left: 20px;
  margin-top: 4px;
}
.dateStyle {
  color: $accent1;
  font-size: $fs14;
  font-weight: 500;
  letter-spacing: -0.013em;
  margin: 0;
}
.allDay {
  background: rgba(190, 72, 59, 0.12);
  border-radius: 4px;
  padding: 2px 4px;
}
#schedule-list {
  --safe-area-inset-top: env(safe-area-inset-top);
}
</style>
