<template>
    <div v-if="dashData" class="flex_col" style="padding-bottom:100px">
        <!-- 팀장인 경우 -->
        <div v-if="isMaster" class="gridWrap">
            <div class="storeDetailCard1">
                <div class="cardFontStyle1">소속</div>
                <div class="cardFontStyle2">{{ dashData.managerDepartName }}</div>
                <div class="cardFontStyle3">담당 매장 {{ dashData.managerHasStoreCnt }}개 / 총 {{ dashData.allStoreCnt }}개</div>
            </div>
            <div class="storeDetailCard1">
                <div class="cardFontStyle1">QSC점검횟수(올해)</div>
                <div class="cardFontStyle2">{{ dashData.thisYearQscCnt }}회</div>
                <div class="cardFontStyle3">미방문 {{ dashData.notGotoStoreCnt }}개 지점</div>
            </div>
            <div class="storeDetailCard1">
                <div class="cardFontStyle1">담당 매장 평균 위생 점수</div>
                <div class="flex_row flex_between">
                    <div class="cardFontStyle2">{{ dashData.managerAvgScore }}점</div>
                    <ScoreTag :rank="dashData.managerGrade" style="align-self:flex-end"></ScoreTag>
                </div>
            </div>
            <div class="storeDetailCard1">
                <div class="cardFontStyle1">평균 위생 점수</div>
                <div class="flex_row flex_between">
                    <div class="cardFontStyle2">{{ dashData.allAvgScore }}점</div>
                    <ScoreTag :rank="dashData.allGrade" style="align-self:flex-end"></ScoreTag>
                </div>
            </div>
        </div>
        <!-- 점장인 경우 -->
        <div v-else class="gridWrap">
            <div class="storeDetailCard1">
                <div class="cardFontStyle1">소속</div>
                <div class="cardFontStyle2">{{ dashData.storeDepartName }}</div>
            </div>
            <div class="storeDetailCard1">
                <div class="cardFontStyle1">QSC점검횟수(올해)</div>
                <div class="cardFontStyle2">{{ dashData.qscCnt }}회</div>
            </div>
            <div class="storeDetailCard1">
                <div class="cardFontStyle1">평균 위생 점수(최근 6개월)</div>
                <div class="flex_row flex_between">
                    <div class="cardFontStyle2">{{ dashData.avgScore6months }}점</div>
                    <ScoreTag :rank="dashData.grade6months" style="align-self:flex-end"></ScoreTag>
                </div>
            </div>
            <div class="storeDetailCard1">
                <div class="cardFontStyle1">평균 위생 점수(전체 기간)</div>
                <div class="flex_row flex_between">
                    <div class="cardFontStyle2">{{ dashData.avgScoreAll }}점</div>
                    <ScoreTag :rank="dashData.gradeAll" style="align-self:flex-end"></ScoreTag>
                </div>
            </div>
        </div>

        <!-- 팀장인 경우 -->
        <template v-if="isMaster">
            <div class="flex_col">
                <div class="flex_col" style="padding:16px">
                    <div class="flex_row flex_between">
                        <div class="graphTitle">위생 등급 분포</div>
                        <div class="graphMoreBtn" @click="$router.push({path:'/inspection/report'})" style="color:rgba(0,0,0,0.32)">모두 보기</div>
                    </div>
                    <GraphBar :data="dashData"></GraphBar>
                </div>
                <div class="flex_col">
                    <div class="flex_row flex_between" style="padding:14px 16px" @click="$router.push({path:'/inspection/report', hash:`#S등급`})">
                        <div class="flex_row">
                            <div class="optionBtn" style="background-color:rgba(59,210,138,1);"></div>
                            <span class="scoreFont">S등급</span>
                            <p class="FontStyle">{{ dashData.srankList.map(v=>v.storeName).toString() }}</p>
                        </div>
                        <img src="@/assets/arrow3.svg" alt="" style="width:20px;height:20px;margin-left:17px;margin-top:2px"/>
                    </div>
                    <div class="flex_row flex_between" style="padding:14px 16px" @click="$router.push({path:'/inspection/report', hash:`#A등급`})">
                        <div class="flex_row">
                            <div class="optionBtn" style="background-color:rgba(81,171,255,1);"></div>
                            <span class="scoreFont">A등급</span>
                            <p class="FontStyle">{{ dashData.arankList.map(v=>v.storeName).toString() }}</p>
                        </div>
                        <img src="@/assets/arrow3.svg" alt="" style="width:20px;height:20px;margin-left:17px;margin-top:2px"/>
                    </div>
                    <div class="flex_row flex_between" style="padding:14px 16px" @click="$router.push({path:'/inspection/report', hash:`#B등급`})">
                        <div class="flex_row">
                            <div class="optionBtn" style="background-color:rgba(252,218,0,1); "></div>
                            <span class="scoreFont">B등급</span>
                            <p class="FontStyle">{{ dashData.brankList.map(v=>v.storeName).toString() }}</p>
                        </div>
                        <img src="@/assets/arrow3.svg" alt="" style="width:20px;height:20px;margin-left:17px;margin-top:2px"/>
                    </div>
                    <div class="flex_row flex_between" style="padding:14px 16px" @click="$router.push({path:'/inspection/report', hash:`#C등급`})">
                        <div class="flex_row">
                            <div class="optionBtn" style="background-color:rgba(255,164,39,1);"></div>
                            <span class="scoreFont">C등급</span>
                            <p class="FontStyle">{{ dashData.crankList.map(v=>v.storeName).toString() }}</p>
                        </div>
                        <img src="@/assets/arrow3.svg" alt="" style="width:20px;height:20px;margin-left:17px;margin-top:2px"/>
                    </div>
                    <div class="flex_row flex_between" style="padding:14px 16px" @click="$router.push({path:'/inspection/report', hash:`#D등급`})">
                        <div class="flex_row">
                            <div class="optionBtn" style="background-color:rgba(255,108,104,1);"></div>
                            <span class="scoreFont">D등급</span>
                            <p class="FontStyle">{{ dashData.drankList.map(v=>v.storeName).toString() }}</p>
                        </div>
                        <img src="@/assets/arrow3.svg" alt="" style="width:20px;height:20px;margin-left:17px;margin-top:2px"/>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="dashData.hygieneScoreList.length>0" class="flex_col" style="padding:16px">
                <div class="flex_row">
                    <div class="graphTitle">최근 6개월 위생등급 추이</div>
                </div>
                <div class="flex_col flex_center" style="padding-top: 26px;">
                    <GraphLine :data="dashData.hygieneScoreList"></GraphLine>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ScoreTag from '@/components/store/ScoreTag.vue';
import GraphBar from '../lib/GraphBar.vue';
import GraphLine from '../lib/GraphLine.vue';
import { getDashboard } from '@/api/StoreAPI'

export default {
  name: 'inspection-list',
  components: { 
    ScoreTag,
    GraphBar,
    GraphLine,
  },
  data () {
    return {
        isMaster: true, //true: 팀장 & false: 점장
        dashData: null,
    }
  },
  methods: {
    getDashBoardData(){
        getDashboard()
        .then((res)=>{
            if(res.data.isSuccessful){
                // console.log(res.data.result)
                this.dashData = res.data.result
                // console.log(this.dashData)
            }
        })
        .catch((err)=>{
            console.log(err)
        })
    }
  },
  created(){
      this.getDashBoardData()
  },
  mounted(){
        if(localStorage.getItem('managerType')==='팀장'){
            this.isMaster = true
        }else{
            this.isMaster = false
        }
    },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variable.scss';
  @import '@/assets/scss/component.scss';
.cardFontStyle1 {
    color: $black50;
    font-size: $fs12;
    font-weight: 500; 
    line-height: 14.4px;
    letter-spacing: -1.3%;
}
.cardFontStyle2 {
    color: $black100;
    font-size: $fs16;
    font-weight: 600; 
    line-height: 19.2px;
    letter-spacing: -1.3%;
}
.cardFontStyle3 {
    color: $black50;
    font-size: $fs10;
    font-weight: 400; 
    line-height: 12px;
}
.graphTitle {
    color: $black100;
    font-size: $fs20;
    font-weight: 600; 
    line-height: 24px;
    letter-spacing: -1.3%;
}
.graphMoreBtn {
    color: $accent1;
    font-size: $fs14;
    font-weight: 500; 
    line-height: 16.8px;
    letter-spacing: -1.3%;
}
.optionBtn {
    min-width: 8px;
    max-width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-top: 6px;
    margin-right: 8px;
}
.scoreFont {
    color: $black50;
    font-size: $fs14;
    font-weight: 500; 
    line-height: 17px;
    letter-spacing: -1.3%;
    min-width: fit-content;
    max-width: fit-content;
    margin-right: 17px;
    margin-top: 2px;
}
.FontStyle {
    color: $black100;
    font-size: $fs14;
    font-weight: 400; 
    line-height: 20.72px;
    letter-spacing: -1.3%;
}
</style>