<!-- 점수 수정이 가능한 경우 -->
<template>
  <div class="flex_col" style="padding-bottom: 200px">
    <div>
      <div v-if="data.isUseLaw === 1" class="flex_col">
        <div class="flex_row flex_between" style="padding: 18px 18px 8px 16px">
          <div class="totalScoreTitle">중점 관리 사항</div>
          <div class="totalScoreStyle">
            <span class="burgundy">{{ lawrScore }}점</span>/{{
              data.lawTotalCnt ? data.lawTotalCnt : data.lawTotal
            }}점
          </div>
        </div>
        <div v-for="(question, index) in law.detail" :key="index" style="margin: 0 16px">
          <div :class="{'bottomLine':(law.detail.length)-1 !== index}">
            <QSCInspectionQuestion
              :data="question"
              :index="index"
              @getAnswer="getAnswerLaw"
            ></QSCInspectionQuestion>
          </div>
        </div>
      </div>
      <div v-if="data.isUseHygiene === 1" class="flex_col">
        <div class="flex_row flex_between" style="padding: 18px 18px 8px 16px">
          <div class="totalScoreTitle">일반관리</div>
          <div class="totalScoreStyle">
            <span class="burgundy">{{ hygieneScore }}점</span>/{{
              data.hygieneTotalCnt ? data.hygieneTotalCnt : data.hygieneTotal
            }}점
          </div>
        </div>
        <div v-for="(question, index) in hygiene.detail" :key="index" style="margin: 0 16px">
          <div :class="{'bottomLine':(hygiene.detail.length)-1 !== index}">
            <QSCInspectionQuestion
              :data="question"
              :index="index"
              @getAnswer="getAnswerHygiene"
            ></QSCInspectionQuestion>
          </div>
        </div>
      </div>
      <div v-if="data.isUseService === 1" class="flex_col">
        <div class="flex_row flex_between" style="padding: 18px 18px 8px 16px">
          <div class="totalScoreTitle">문서관리</div>
          <div class="totalScoreStyle">
            <span class="burgundy">{{ serviceScore }}점</span>/{{
              data.serviceTotalCnt ? data.serviceTotalCnt : data.serviceTotal
            }}점
          </div>
        </div>
        <div v-for="(question, index) in service.detail" :key="index" style="margin: 0 16px">
          <div :class="{'bottomLine':(service.detail.length)-1 !== index}">
            <QSCInspectionQuestion
              :data="question"
              :index="index"
              @getAnswer="getAnswerService"
            ></QSCInspectionQuestion>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div style="padding: 16px; border-top: 1px solid rgba(0, 0, 0, 0.08)">
        <div class="flex_col" style="gap: 11px">
          <div class="flex_row flex_between">
            <div class="scoreTitleStyle">총점</div>
            <div class="scoreStyle">
              <span class="burgundy">{{ totalScore }}점</span> /{{
                defaultScore
              }}점
            </div>
          </div>
          <div class="flex_row flex_between">
            <div class="scoreTitleStyle">등급</div>
            <div class="flex_row" style="align-items: center">
              <div v-if="this.lawCheck === false" class="scoreNoticeStyle">
                중점 관리 사항 미흡 항목 있음
              </div>
              <ScoreTag :rank="rankCheck"></ScoreTag>
            </div>
          </div>
        </div>
        <div class="flex_row" style="gap: 12px; margin-top: 24px">
          <!-- todo 변화 있을 때만 임시저장 버튼 활성화 -->
          <button 
            v-if="this.$route.name==='qsc-temporary-edit'"
            class="baseBtn line"
            @click="addQSC(0)"
            :disabled="btnDisable"
          >
            임시저장
          </button>
          <button
            v-else
            class="baseBtn line"
            @click="addQSC(0)"
            :disabled="isDisable"
          >
            임시저장
          </button>
          <button class="baseBtn fill" @click="scoreCheck(1)">제출</button>
        </div>
      </div>
    </div>
    <Sign
      :signDisplay="isSignDisplay"
      @changeDisplay="signDisplayHandler"
      @getSign="getSign"
    ></Sign>
    <CommonAlert2 :state="isShowAlert" @changeDisplay="getAlertDisplayState">
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #content2 v-if="alertContent2.length !== 0">
        {{ alertContent2 }}
      </template>
      <template #btn>
        {{ alertBtn }}
      </template>
    </CommonAlert2>
    <ToastMsg :message="toastMassage" :display="toastDisplay"></ToastMsg>
  </div>
</template>

<script>
import QSCInspectionQuestion from "@/components/qsc/QSCInspectionQuestion.vue";
import { getScore, addInspectionQA, modiInspection } from "@/api/StoreAPI";
import ScoreTag from "../store/ScoreTag.vue";
import Sign from "../lib/Sign.vue";
import CommonAlert2 from "@/components/global/alert/CommonAlert2.vue";
import ToastMsg from "@/components/global/alert/ToastMsg.vue";

export default {
  components: {
    QSCInspectionQuestion,
    ScoreTag,
    Sign,
    CommonAlert2,
    ToastMsg,
  },
  props: {
    data: { type: Object, require: true },
  },
  data() {
    return {
      id: this.$route.params.id,
      isMaster: true,
      law: this.data.law,
      hygiene: this.data.hygiene,
      service: this.data.service,
      newLawData: this.data.law,
      newHygieneData: this.data.hygiene,
      newServiceData: this.data.service,
      scoreData: null,
      managerSign: null,
      isSignDisplay: false,
      signLink: "",
      isShowAlert: false,
      alertTitle: "",
      alertContent1: "",
      alertContent2: "",
      alertBtn: "",
      toastDisplay: false,
      toastMassage: "",
      isDisable: false,
    };
  },
  methods: {
    getAlertDisplayState(value) {
      this.isShowAlert = value;
    },
    getSign(value) {
      // 사인 받으면 제출 API 호출
      this.signLink = value;
      this.isSignDisplay = false;
      this.addQSC(1);
    },
    signDisplayHandler(value) {
      this.isSignDisplay = value;
    },
    moveFeedback() {
      this.$router.replace({
        path: `/qsc/feedback/${this.id}`,
        params: { id: this.id },
      });
    },
    moveModi() {
      this.$router.replace({
        path: `/qsc/edit/${this.id}`,
        params: { id: this.id },
      });
    },
    getAnswerLaw(index, value) {
      // console.log("Law데이터바뀜");
      let arr = [...this.newLawData.detail];
      arr.splice(index, 1, value);
      this.newLawData.detail = arr;
    },
    getAnswerHygiene(index, value) {
      // console.log("Hygiene데이터바뀜");
      let arr = [...this.newHygieneData.detail];
      arr.splice(index, 1, value);
      this.newHygieneData.detail = arr;
    },
    getAnswerService(index, value) {
      // console.log("Service데이터바뀜");
      let arr = [...this.newServiceData.detail];
      arr.splice(index, 1, value);
      this.newServiceData.detail = arr;
    },
    scoreCheck(sendType) {
      // 점검 점수 내기 API
      const formData = new FormData();
      formData.append("law", this.newLawData ? JSON.stringify(this.newLawData) : undefined);
      formData.append("hygiene", this.newHygieneData ? JSON.stringify(this.newHygieneData) : undefined);
      formData.append("service", this.newServiceData ? JSON.stringify(this.newServiceData) : undefined);
      const scoredata = formData;
      getScore(scoredata)
        .then((res) => {
          if (res.data) {
            if (res.data.isSuccessful) {
              this.scoreData = res.data.result;
              if (sendType === 1) {
                // 제출시 사인 넣는 창 띄우기
                this.isSignDisplay = true;
              } else {
                // 임시저장시 바로 저장
                this.addQSC(sendType);
              }
            } else {
              this.alertTitle = "저장실패";
              this.alertContent1 = "답변하지 않은 문항이 있습니다.";
              this.alertContent2 = "답변을 완료해주세요.";
              this.alertBtn = "확인";
              this.isShowAlert = true;
            }
          }
        })
        .catch((err) => {
          this.alertTitle = "저장실패";
          this.alertContent1 = "알수 없는 에러";
          this.alertContent2 = "서비스 관리자에게 문의하세요.";
          this.alertBtn = "확인";
          this.isShowAlert = true;
          console.log(err);
        });
    },
    addQSC(sendType) {
      // 점검표 등록 API
      const formData = new FormData();
      formData.append(
        "storeId",
        this.$route.params.storeId
          ? this.$route.params.storeId
          : this.data.storeId
      );
      formData.append(
        "reportTemplateId",
        this.$route.params.reportTemplateId
          ? this.$route.params.reportTemplateId
          : this.data.reportTemplateId
      );
      formData.append("isShow", sendType);
      formData.append("law", this.newLawData ? JSON.stringify(this.newLawData) : undefined);
      formData.append("hygiene", this.newHygieneData ? JSON.stringify(this.newHygieneData) : undefined);
      formData.append("service", this.newServiceData ? JSON.stringify(this.newServiceData) : undefined);
      formData.append("totalScore", this.scoreData?this.scoreData.totalScore:'');
      formData.append("rank", this.scoreData?this.scoreData.rank:'');
      formData.append("lawScore", this.scoreData?this.scoreData.lawScore:'');
      formData.append("lawTotalScore", this.scoreData?this.scoreData.lawTotalScore:'');
      formData.append("lawInsufficientCnt", this.scoreData?this.scoreData.lawInsufficientCnt:'');
      formData.append(
        "lawQuestionIsInsufficient",
        this.scoreData?this.scoreData.lawQuestionIsInsufficient ? 1 : 0 : 0
      );
      formData.append("lawFeedbackCnt", this.scoreData?this.scoreData.lawFeedbackCnt:'');
      formData.append(
        "lawFeedbackOfFeedbackCnt",
        this.scoreData?this.scoreData.lawFeedbackOfFeedbackCnt:''
      );
      formData.append("hygieneScore", this.scoreData?this.scoreData.hygieneScore:'');
      formData.append("hygieneTotalScore", this.scoreData?this.scoreData.hygieneTotalScore:'');
      formData.append(
        "hygieneInsufficientCnt",
        this.scoreData?this.scoreData.hygieneInsufficientCnt:''
      );
      formData.append("hygieneFeedbackCnt", this.scoreData?this.scoreData.hygieneFeedbackCnt:'');
      formData.append(
        "hygieneFeedbackOfFeedbackCnt",
        this.scoreData?this.scoreData.hygieneFeedbackOfFeedbackCnt:''
      );
      formData.append("serviceScore", this.scoreData?this.scoreData.serviceScore:'');
      formData.append("serviceTotalScore", this.scoreData?this.scoreData.serviceTotalScore:'');
      formData.append(
        "serviceInsufficientCnt",
        this.scoreData?this.scoreData.serviceInsufficientCnt:''
      );
      formData.append("serviceFeedbackCnt", this.scoreData?this.scoreData.serviceFeedbackCnt:'');
      formData.append(
        "serviceFeedbackOfFeedbackCnt",
        this.scoreData?this.scoreData.serviceFeedbackOfFeedbackCnt:''
      );
      formData.append("managerSign", this.signLink);
      const qscData = formData;
      if (
        this.$route.name === "qsc-inspection-feedback" ||
        this.$route.name === "qsc-inspection-edit" ||
        this.$route.name === "qsc-temporary-edit"
      ) {
        modiInspection(this.id, qscData)
          .then((res) => {
            if (res.data.isSuccessful) {
              // v Toast 띄우기 : 성공적으로 제출되었습니다.
              this.toastDisplay = true;
              this.toastMassage = "성공적으로 제출되었습니다.";
              setTimeout(() => {
                this.toastDisplay = false;
                this.toastMassage = "";
                // v listpage 로 이동
                this.$router.push({ path: "/inspection/report" });
              }, 1000);
            } else {
              this.alertTitle = "저장실패";
              this.alertContent1 = "알수 없는 에러";
              this.alertContent2 = "서비스 관리자에게 문의하세요.";
              this.alertBtn = "확인";
              this.isShowAlert = true;
            }
          })
          .catch((err) => {
            this.alertTitle = "저장실패";
            this.alertContent1 = "알수 없는 에러";
            this.alertContent2 = "서비스 관리자에게 문의하세요.";
            this.alertBtn = "확인";
            this.isShowAlert = true;
            console.log(err);
          });
      } else {
        if (sendType === 1) {
          addInspectionQA(qscData)
            .then((res) => {
              if (res.data.isSuccessful) {
                // v Toast 띄우기 : 성공적으로 제출되었습니다.
                this.toastDisplay = true;
                this.toastMassage = "성공적으로 제출되었습니다.";
                setTimeout(() => {
                  this.toastDisplay = false;
                  this.toastMassage = "";
                  // v listpage 로 이동
                  this.$router.push({ path: "/inspection/report" });
                }, 1000);
              } else {
                this.alertTitle = "저장실패";
                this.alertContent1 = "알수 없는 에러";
                this.alertContent2 = "서비스 관리자에게 문의하세요.";
                this.alertBtn = "확인";
                this.isShowAlert = true;
              }
            })
            .catch((err) => {
              this.alertTitle = "저장실패";
              this.alertContent1 = "알수 없는 에러";
              this.alertContent2 = "서비스 관리자에게 문의하세요.";
              this.alertBtn = "확인";
              this.isShowAlert = true;
              console.log(err);
            });
        } else {
          addInspectionQA(qscData)
            .then((res) => {
              if (res.data.isSuccessful) {
                // v Toast 띄우기 : 임시저장되었습니다.
                this.toastDisplay = true;
                this.toastMassage = "임시저장되었습니다.";
                setTimeout(() => {
                  this.toastDisplay = false;
                  this.toastMassage = "";
                  // v listpage 로 이동
                  this.$router.push({ path: "/inspection/report" });
                }, 1000);
              } else {
                this.alertTitle = "저장실패";
                this.alertContent1 = "알수 없는 에러";
                this.alertContent2 = "서비스 관리자에게 문의하세요.";
                this.alertBtn = "확인";
                this.isShowAlert = true;
              }
            })
            .catch((err) => {
              this.alertTitle = "저장실패";
              this.alertContent1 = "알수 없는 에러";
              this.alertContent2 = "서비스 관리자에게 문의하세요.";
              this.alertBtn = "확인";
              this.isShowAlert = true;
              console.log(err);
            });
        }
      }
    },
  },

  computed: {
    lawCheck() {
      if (this.data.isUseLaw === 0) {
        return null;
      } else {
        let arr = this.newLawData.detail;
        return arr.every((value) => value.answerSelect === "준수");
      }
    },
    lawrScore() {
      if (this.data.isUseLaw === 0) {
        return 0;
      } else {
        let score = this.newLawData.detail.reduce((pre, cur) => {
          if (cur.answerSelect === "준수") {
            let result = pre;
            result = pre + cur.score;
            return result;
          } else {
            let result = pre;
            return result;
          }
        }, 0);
        // console.log("lawrScore", score);
        return score > 0 ? score : 0;
      }
    },
    hygieneScore() {
      if (this.data.isUseHygiene === 0) {
        return 0;
      } else {
        let score = this.newHygieneData.detail.reduce((pre, cur) => {
          if (cur.answerSelect === "준수") {
            let result = pre;
            result = pre + cur.score;
            return result;
          } else {
            let result = pre;
            return result;
          }
        }, 0);
        // console.log("hygieneScore", score);
        return score > 0 ? score : 0;
      }
    },
    serviceScore() {
      if (this.data.isUseService === 0) {
        return 0;
      } else {
        let score = this.newServiceData.detail.reduce((pre, cur) => {
          if (cur.answerSelect === "준수") {
            let result = pre;
            result = pre + cur.score;
            return result;
          } else {
            let result = pre;
            return result;
          }
        }, 0);
        // console.log("serviceScore", score);
        return score > 0 ? score : 0;
      }
    },
    totalScore() {
      return this.lawrScore + this.hygieneScore + this.serviceScore;
    },
    defaultScore() {
      if (
        this.$route.name === "qsc-inspection-feedback" ||
        this.$route.name === "qsc-inspection-edit" ||
        this.$route.name === "qsc-temporary-edit"
      ) {
        let score =
          Number(this.data.hygieneTotal ? this.data.hygieneTotal : 0) +
          Number(this.data.lawTotal ? this.data.lawTotal : 0) +
          Number(this.data.serviceTotal ? this.data.serviceTotal : 0);
        return score > 0 ? score : 0;
      } else {
        let score =
          Number(this.data.hygieneTotalCnt ? this.data.hygieneTotalCnt : 0) +
          Number(this.data.lawTotalCnt ? this.data.lawTotalCnt : 0) +
          Number(this.data.serviceTotalCnt ? this.data.serviceTotalCnt : 0);
        return score > 0 ? score : 0;
      }
    },
    rankCheck() {
      if (this.lawCheck === false) {
        return "D등급";
      } else if (this.totalScore >= 195) {
        return "S등급";
      } else if (this.totalScore >= 190) {
        return "A등급";
      } else if (this.totalScore >= 180) {
        return "B등급";
      } else if (this.totalScore >= 160) {
        return "C등급";
      } else {
        return "D등급";
      }
    },
    btnDisable(){
      if(this.law === this.newLawData && this.hygiene === this.newHygieneData && this.service === this.newServiceData){
        return true
      }else{
        return false
      }
    }
  },
  watch: {
    lawrScore: {
      handler: function () {
        // console.log("lawrScore", this.lawrScore);
        // console.log("hygieneScore", this.hygieneScore);
        // console.log("serviceScore", this.serviceScore);
        // this.totalScore = this.lawrScore + this.hygieneScore + this.serviceScore
      },
    },
    hygieneScore: {
      handler: function () {
        // console.log("lawrScore", this.lawrScore);
        // console.log("hygieneScore", this.hygieneScore);
        // console.log("serviceScore", this.serviceScore);
        // this.totalScore = this.lawrScore + this.hygieneScore + this.serviceScore
      },
    },
    serviceScore: {
      handler: function () {
        // console.log("lawrScore", this.lawrScore);
        // console.log("hygieneScore", this.hygieneScore);
        // console.log("serviceScore", this.serviceScore);
        // this.totalScore = this.lawrScore + this.hygieneScore + this.serviceScore
      },
    },
  },
  created() {
    if (localStorage.getItem("managerType") === "팀장") {
      this.isMaster = true;
    } else {
      this.isMaster = false;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    // console.log("storeId",this.$route.params.storeId)
    // console.log("reportTemplateId",this.$route.params.reportTemplateId)
    // console.log("routename",this.$route.name)
    // console.log("data", this.data);
    // console.log("중점 관리 사항", this.law);
    // console.log("일반관리", this.hygiene);
    // console.log("문서관리", this.service);
    // console.log("totalScore", this.totalScore);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
@import "@/assets/scss/qsc.scss";
.paddingBottom {
  padding-bottom: 200px;
}

.bottomLine {
    border-bottom:1px solid rgba(0,0,0,0.04);
  }
</style>
