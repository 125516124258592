<template>
  <div v-if="display" class="BG" @click="changeDisplay">
    <div class="bottomOptionBG">
      <div class="handle"></div>
      <div v-if="type === '정렬'" class="flex_col">
        <div
          class="fontStyle1"
          v-for="value in arrayOption"
          :key="value"
          @click.self="changeOption(value)"
        >
          {{ value }}
        </div>
      </div>
      <div v-else-if="type === '정렬2'" class="flex_col">
        <div
          class="fontStyle1"
          v-for="value in arrayOption2"
          :key="value"
          @click.self="changeOption(value)"
        >
          {{ value }}
        </div>
      </div>
      <div v-else-if="type === 'learning'" class="flex_col">
        <div class="fontStyle1" v-for="value in learning" :key="value">
          {{ value }}
        </div>
      </div>
      <div v-else-if="type === 'certificate'" class="flex_col">
        <div class="fontStyle1" v-for="value in certificate" :key="value">
          {{ value }}
        </div>
      </div>
      <div v-else-if="type === 'inspection'" class="flex_col">
        <div
          class="fontStyle1"
          @click.self="$router.push({ name: 'inspaction-add-page' })"
        >
          신규 등록하기
        </div>
        <div
          class="fontStyle1"
          @click.self="$router.push({ name: 'inspaction-temporary-list' })"
        >
          임시저장 불러오기
        </div>
      </div>
      <div v-else-if="type === 'education '" class="flex_col">
        <div
          class="fontStyle1"
          @click.self="$router.push({ name: 'inspaction-add-page' })"
        >
          위생교육
        </div>
        <div
          class="fontStyle1"
          @click.self="$router.push({ name: 'inspaction-temporary-list' })"
        >
          산업안전교육
        </div>
      </div>
      <div v-else class="flex_col">
        <div class="fontStyle1" @click.self="modiAction">수정</div>
        <div class="fontStyle1" @click.self="deleteAction">삭제</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    displayState: { type: [String, Boolean], required: true },
    type: { type: [String, Number], required: true },
    value: { type: String, required: false },
    selected: { type: String, required: false },
  },
  data() {
    return {
      arrayOption: ["최근 방문순", "오래된 방문순", "가나다순", "가나다 역순"],
      arrayOption2: ["최신순", "오래된 순","가나다순", "가나다 역순"],
      inspection: ["신규 등록하기", "임시저장 불러오기"],
      learning: ["위생교육", "산업안전교육"],
      certificate: [
        "영양사식품위생교육",
        "조리사위생교육",
        "신규/기존영업자위생교육",
      ],
      display: this.displayState,
      state: this.selected,
    };
  },
  methods: {
    changeDisplay() {
      // 닫기
      this.display = false;
      this.$emit("changeDisplay", false);
    },
    changeOption(clickData) {
      // console.log(clickData);
      this.state = clickData;
      this.$emit("changeOption", this.state);
    },
    modiAction() {
      // console.log(this.type);
      this.$emit("modiAction", this.type);
    },
    deleteAction() {
      this.$emit("deleteAction", this.type);
    },
  },
  watch: {
    displayState: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        this.display = this.displayState;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
.fontStyle1 {
  font-size: $fs16;
  font-weight: 500;
  line-height: 19.2px;
  color: $black100;
  letter-spacing: -1.3%;
  margin: 0;
  padding: 14px 0;
}
</style>
