<template>
	<div class="BG" v-if="signDisplay" @click.self="changeDisplay">
		<div class="signWrap">
			<div class="signTitle">점검표 제출</div>
			<div class="signInfo">점검표를 최종 제출하시겠습니까?<br/>하단 서명란에 서명해주십시오.</div>
			<vueSignature ref="signature" 
				:sigOption="option" 
				:w="'100%'" 
				:h="'210px'" 
				:disabled="disabled"
				:defaultUrl="dataUrl"
				style="margin:auto;border:1px dashed rgba(0,0,0,0.16);border-radius:3px;"></vueSignature> 
			<!-- <vueSignature ref="signature1" :sigOption="option"></vueSignature>  -->
			<div class="flex_row" style="width:100%;justify-content: flex-end;margin-top:16px">
				<button class="cancleBtn" @click="changeDisplay">취소</button>
				<button class="submitBtn" :disabled="submitDisable" @click="save">서명완료</button>
			</div>
		</div>
	</div>
</template>

<script>
import { getImageLink } from "@/api/StoreAPI"
import vueSignature from "vue-signature"
export default {
	name: "sign-component",
	components:{
		vueSignature
	},
    props: {
        signDisplay: Boolean,
    },
	data() {
		return {
			option:{
				penColor:"rgb(0, 0, 0)",
				backgroundColor:"rgba(0, 0, 0, 0.02)",
			},
			isDisplay: this.signDisplay,
			disabled:false,
			dataUrl:"",
			signLink:"",
			submitDisable: true,
			init:false
		};
	},
	methods:{
		checkEmpty(){
			if(this.$refs.signature?.isEmpty()){
				this.submitDisable = true
			}else{
				this.submitDisable = false
			}
		},
		getSign(){
            this.$emit('getSign', this.signLink)
		},
        changeDisplay() {
            this.isDisplay = false
            this.$emit('changeDisplay', this.isDisplay)
        },
		save(){
			var _this = this;
			// var png = _this.$refs.signature.save()
			// var jpeg = _this.$refs.signature.save('image/jpeg')
			var svg = _this.$refs.signature.save('image/svg+xml');
			// console.log(png);
			// console.log(jpeg)
			// console.log(svg)
			this.base64toFile(svg,'sign')
		},
		clear(){
			var _this = this;
			_this.$refs.signature.clear();
		},
		undo(){
			var _this = this;
			_this.$refs.signature.undo();
		},
		addWaterMark(){
			var _this = this;
			_this.$refs.signature.addWaterMark({
				text:"여기에 사인해주십시오.",          // watermark text, > default ''
				font:"12px Pretendard",         // mark font, > default '20px sans-serif'
				style:'stroke',               // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill		
				fillStyle:"#00000047",           // fillcolor, > default '#333' 
				strokeStyle:"#00000047",	   // strokecolor, > default '#333'	
				x:0,                     // fill positionX, > default 20
				y:0,                     // fill positionY, > default 20				
				sx:100,                    // stroke positionX, > default 40
				sy:200                     // stroke positionY, > default 40
			});
		},
		base64toFile(base_data, filename){
			var arr = base_data.split(','),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]),
				n = bstr.length,
				u8arr = new Uint8Array(n);
				while(n--){
					u8arr[n] = bstr.charCodeAt(n);
				}
			let file = new File([u8arr], filename, {type:mime});
			const formData = new FormData();
				formData.append('file',file);
			const imageData = formData;
			getImageLink(imageData)
			.then((res)=>{
				if(res.data.isSuccessful){
					this.signLink = res.data.result
					// console.log(res.data.result)
					this.getSign()
				}
			})
			.catch((err)=>{
			console.log(err)
			})

		},
		fromDataURL(url){
			var _this = this;
			_this.$refs.signature.fromDataURL(url);
		},
		handleDisabled(){
			var _this = this;
			_this.disabled  = !_this.disabled
		}
	},
    watch: {
		signDisplay: {
            handler: function () {
				// console.log("signDisplay감지")

				this.isDisplay = this.signDisplay

				if(this.isDisplay===true){
					// console.log('isDisplay:TRUE',this.isDisplay);
					this.init = setInterval(() => {
						this.checkEmpty()
					}, 1000)
				}
            }
      }
    },
	destroyed(){
		// console.log('destroyed');
		clearInterval(
						this.init
					)
	}
};
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/variable.scss';
  @import '@/assets/scss/component.scss';
  .BG {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 55;
  }
  .signWrap {
	position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: $white100;
	border-radius: 16px;
	width: 280px;
	padding: 24px 20px;
  }
  .signTitle {
    color: $black100;
    font-size: $fs18;
    font-weight: 600;
    letter-spacing: -2%;
	line-height: 21.6px;
  }
  .signInfo {
    color: $black66;
    font-size: $fs14;
    font-weight: 500;
    letter-spacing: -1.3%;
	line-height: 20.72px;
	margin-top: 12px;
	margin-bottom: 12px;
  }
  .cancleBtn {
	border: none;
	background: none;
    color: $black100;
    font-size: $fs16;
    font-weight: 500;
    letter-spacing: -1.3%;
	line-height: 19.2px;
  }
  .submitBtn {
	border: none;
	background: none;
    color: $burgundy;
    font-size: $fs16;
    font-weight: 600;
    letter-spacing: -1.3%;
	line-height: 19.2px;
	&:disabled {
		color: $black50;
	}
  }
</style>