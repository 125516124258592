<template>
  <div class="maindWrap scroll" style="padding: 8px 0 44px 0">
    <template v-if="displayPopUp.length !== 0">
      <div class="BG" v-if="displayPopUp.length !== 0">
        <AdPopUp
          v-for="(data, index) in displayPopUp"
          :key="index"
          :data="data"
          :displayPopUp="displayPopUp"
          @closePopUpToday="closePopUpToday"
          @closePopUp="closePopUp"
        />
      </div>
    </template>
    <!-- 최근 방문한 지점은 팀장에게만 보여짐 -->
    <section v-if="isMaster">
      <div class="flex_row flex_between" style="padding: 28px 16px">
        <h1 class="sectionTitleFont">최근 방문한 지점</h1>
        <span class="allListBtn" @click="$router.push({ name: 'store-list' })"
          >모두 보기</span
        >
      </div>
      <MainCard></MainCard>
    </section>
    <!-- 점장 - 내 지점 보여주기 -->
    <section v-else>
      <div class="flex_row flex_between" style="padding: 28px 16px">
        <h1 class="sectionTitleFont">내 지점</h1>
        <span
          class="allListBtn"
          @click="$router.push({ name: 'my-store-list' })"
          >모두 보기</span
        >
      </div>
      <MainCard></MainCard>
    </section>
    <section class="section" :class="{ paddingTop: !isMaster }">
      <div class="flex_row flex_between" style="padding: 0 16px 28px">
        <h1 class="sectionTitleFont">공지사항</h1>
        <span class="allListBtn" @click="$router.push({ name: 'notice-list' })"
          >모두 보기</span
        >
      </div>
      <div>
        <MainNotiList :data="noticeData" />
      </div>
    </section>
    <section class="section">
      <div class="flex_row flex_between" style="padding: 34px 16px 28px">
        <h1 class="sectionTitleFont">가까운 일정</h1>
        <span class="allListBtn" @click="$router.push({ name: 'my-schedule' })"
          >모두 보기</span
        >
      </div>
      <div>
        <MainScheduleList :data="scheduleData" />
      </div>
    </section>
  </div>
</template>

<script>
import AdPopUp from "@/components/global/alert/AdPopUp.vue";
import MainNotiList from "@/components/main/MainNotiList.vue";
import MainScheduleList from "@/components/main/MainScheduleList.vue";
import MainCard from "@/components/main/MainCard.vue";
import {
  getMainNoticeList,
  getScheduleList,
  getPopUpList,
} from "@/api/MainPageAPI";
export default {
  name: "main-page",
  components: {
    AdPopUp,
    MainCard,
    MainNotiList,
    MainScheduleList,
  },
  data() {
    return {
      isMaster: true, //true: 팀장 & false: 점장
      storeData: null,
      noticeData: null,
      scheduleData: null,
      popupData: null,
      displayPopUp: [],
      displayPopUpTodayNotView: [],
    };
  },
  methods: {
    closePopUp(value) {
      let arr = [...this.displayPopUp];
      arr.splice(
        arr.findIndex((v) => v.id === value),
        1
      );
      this.displayPopUp = arr;
    },
    closePopUpToday(value) {
      let notView = [...this.displayPopUpTodayNotView];
      if (notView.find((v) => v === value)) {
        let arr = [...this.displayPopUp];
        arr.splice(
          arr.findIndex((v) => v.id === value),
          1
        );
        this.displayPopUp = arr;
      } else {
        notView.push(value);
        let arr = [...this.displayPopUp];
        arr.splice(
          arr.findIndex((v) => v.id === value),
          1
        );
        this.displayPopUp = arr;
      }
      this.displayPopUpTodayNotView = notView;
    },
    saveNoViewPopUp() {
      let exdate = new Date();
      exdate.setDate(exdate.getDate());
      let expdate = `${exdate.getFullYear()}-${exdate.getMonth()}-${exdate.getDate()}`;
      window.localStorage.setItem("popupEx", expdate);
      window.localStorage.setItem(
        "popupArr",
        this.displayPopUpTodayNotView.toString()
      );
    },
    getMainNoti() {
      getMainNoticeList()
        .then((res) => {
          // console.log("MainNotice", res.data.result);
          this.noticeData = res.data.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMainSchedule() {
      getScheduleList()
        .then((res) => {
          // console.log("ScheduleList", res.data.result);
          this.scheduleData = res.data.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPopUpData() {
      getPopUpList()
        .then((res) => {
          // console.log("PopUp", res.data.result);
          this.popupData = res.data.result;
          // 오늘 안볼 리스트 로컬스토리지에 있는지 확인
          if (localStorage.getItem("popupArr")) {
            // 있을 경우
            // 날짜확인해서 보여줄지말지 여부 확인
            // =오늘 안볼 리스트와 비교해서 오늘 안볼 리스트에 없으면 보여주기
            if (localStorage.getItem("popupEx")) {
              let today = new Date();
              today.setDate(today.getDate());
              let tooday = `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`;
              // console.log("popupEx",localStorage.getItem('popupEx'))
              // console.log("today",tooday)
              if (localStorage.getItem("popupEx") === tooday) {
                let view = [];
                this.popupData.reduce((acc, cur) => {
                  if (
                    [
                      ...localStorage.getItem("popupArr").replaceAll(",", ""),
                    ].find((v) => Number(v) === cur.id)
                  ) {
                    // console.log("오늘보지않는팝업")
                  } else {
                    view.push(cur);
                  }
                }, view);
                this.displayPopUp = view;
                // console.log("보여줄거",view)
                // console.log("안보여줄거",localStorage.getItem('popupArr'))
              } else {
                this.displayPopUp = res.data.result;
                this.saveNoViewPopUp();
              }
            }
          } else {
            // 없을 경우
            // =오늘 안볼 리스트를 로컬에 저장
            this.displayPopUp = res.data.result;
            this.saveNoViewPopUp();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getMainNoti();
    this.getMainSchedule();
    this.getPopUpData();
    if (localStorage.getItem("managerType") === "팀장") {
      this.isMaster = true;
    } else {
      this.isMaster = false;
    }
  },
  watch: {
    $route() {
      this.getMainNoti();
      this.getMainSchedule();
      this.getPopUpData();
    },
    displayPopUpTodayNotView: {
      handler: function () {
        window.localStorage.setItem(
          "popupArr",
          this.displayPopUpTodayNotView.toString()
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
.maindWrap {
  flex-direction: column;
  padding: 16px 0px;
  gap: 16px;
}
.section {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.sectionTitleFont {
  color: $black100;
  font-size: $fs22;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.013em;
  margin: 0;
}
.allListBtn {
  color: $gray2;
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -1.3%;
  margin: 0;
}
.paddingTop {
  padding-top: 28px;
}
</style>
