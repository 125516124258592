var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.menuDisplay)?_c('MenuBar',{attrs:{"state":_vm.menuDisplay},on:{"changeDisplay":_vm.getMenuDisplayState}}):_vm._e(),_c('div',{staticClass:"headerWrap",class:{
      bordernone:
        this.$route.name === 'inspection-list' ||
        this.$route.name === 'learning-list' ||
        this.$route.name === 'certificate-list' ||
        this.$route.name === 'document-list' ||
        this.$route.name === 'notice-list',
      fixed: _vm.$route.name === 'my-schedule',
    },on:{"click":_vm.test}},[_c('div',{staticStyle:{"width":"50px","height":"24px"},on:{"click":function($event){_vm.menuDisplay = !_vm.menuDisplay}}},[_c('img',{attrs:{"src":require("@/assets/menu.svg"),"alt":"","width":"24px","height":"24px"}})]),(_vm.$route.meta.title === '메인')?_c('div',{staticClass:"headerTitle",staticStyle:{"width":"38px","height":"25px"}},[_c('img',{attrs:{"src":require("@/assets/logoBlack.svg"),"alt":"logo","width":"38px","height":"25px"},on:{"click":function($event){return _vm.$router.push('/login-page')}}})]):_c('div',{staticClass:"headerTitle"},[_vm._v(_vm._s(_vm.$route.meta.title))]),(_vm.$route.name === 'my-schedule')?_c('div',{staticClass:"flex_center headerTextBtn",staticStyle:{"margin-right":"0"},on:{"click":function($event){return _vm.$router.push('/my-schedule/add')}}},[_vm._v(" 추가 ")]):_c('div',{staticClass:"headerNotiBtn",staticStyle:{"width":"50px","height":"24px","display":"flex","justify-content":"flex-end"},on:{"click":function($event){return _vm.$router.push({ name: 'alarm-list' })}}},[(_vm.isAlarm)?_c('div',{staticClass:"alarm"}):_vm._e(),_c('img',{attrs:{"src":require("@/assets/noti.svg"),"alt":"","width":"24px","height":"24px"}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }