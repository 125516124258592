<template>
  <div style="width:100%">
    <LineChartGenerator v-if="chartData.labels"
      :data="chartData"
      :options="chartOptions"
      :width="400"
      :height="400"
    ></LineChartGenerator>
    <div v-else class="flex_col flex_center nographBox">
      <img src="@/assets/error-not12.svg" alt="" style="width:32px"/>
          <div class="errorInfoFont1" style="color:rgba(0, 0, 0, 0.12)">
              최근 6개월 내 점검 내역이 없습니다.
          </div>
    </div>
  </div>
</template>

<script>
import { Line as LineChartGenerator, } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,Filler
)

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },
  props: {
    data: { 
      type: Array,
      require: true
    },
  },
  data() {
    return {
      chartData: {
        labels: null,
        datasets: [
          {
              borderWidth: 1, // 라인 넓이
              label: 'label', // 데이터 라벨
              legend: {
                display: false
              },
              backgroundColor: 'rgba(190, 72, 59, 0.12)',
              fill:'origin',
              tension: 0.1,
              borderColor: 'rgba(190, 72, 59, 1)',
              pointBackgroundColor: 'white',
              pointBorderColor: 'rgba(190, 72, 59, 1)',
              pointBorderWidth: 2,
              data: null,
          },
        ],
      },
      chartOptions: {
        plugins: {
            legend: false // Hide legend
        },
        scales: {
            y: {
                // display: false // Hide Y axis labels
            },
            x: {
                // display: false // Hide X axis labels
            }
        }   
      }
    }
  },
  created(){
    // console.log("6개월이내데이터",this.data)
    let label = this.data.map(v=>v.dt.slice(5,8)+'월')
        if(label.length === 1){
          label.unshift('')
        }
          this.chartData.labels = [...label]
          // console.log("라벨",this.chartData.labels)
        let score = this.data.map(v=>v.avgScore)
        if(score.length === 1){
          score.unshift(0)
        }
          this.chartData.datasets[0].data = [...score]
          // console.log("데이터",this.chartData.datasets[0].data)
  },
  watch: {
    data: {
      handler: function () {
        let label = this.data.map(v=>v.dt.slice(5,8)+'월')
        if(label.length === 1){
          label.unshift('')
        }
          this.chartData.labels = [...label]
        let score = this.data.map(v=>v.avgScore)
        if(score.length === 1){
          score.unshift(0)
        }
          this.chartData.datasets[0].data = [...score]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/variable.scss';
  @import '@/assets/scss/component.scss';
  .nographBox {
    // border: 1px dashed $black16;
    border-radius: 8px;
    min-width: 100%;
    height: 172px;
    padding-bottom: 8px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='rgba(0,0,0,0.16)' stroke-width='1' stroke-dasharray='4%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
</style>