<template>
  <div id="scroll" class="flex_col scroll" @scroll="scrollHandler()">
    <EducationButton category=""></EducationButton>
    <div class="flex_row_slide" style="margin: 16px 16px 0 16px">
      <div
        class="filterTag"
        :class="{ tagActive: category === '전체' }"
        @click="category = '전체'"
      >
        전체
      </div>
      <div
        class="filterTag"
        :class="{ tagActive: category === '영양사식품위생교육' }"
        @click="category = '영양사식품위생교육'"
      >
        영양사식품위생교육
      </div>
      <div
        class="filterTag"
        :class="{ tagActive: category === '조리사위생교육' }"
        @click="category = '조리사위생교육'"
      >
        조리사위생교육
      </div>
      <div
        class="filterTag"
        :class="{ tagActive: category === '신규/기존영업자위생교육' }"
        @click="category = '신규/기존영업자위생교육'"
      >
        신규/기존영업자위생교육
      </div>
    </div>
    <template v-if="listData.length > 0">
      <!-- 데이터 있을 경우 -->
      <div class="flex_row flex_between" style="padding: 8px 16px">
        <div class="resultInfoFont">
          총 {{ totalCnt }}개 수료증이 조회되었습니다.
        </div>
        <div
          class="flex_row"
          style="align-items: center"
          @click="isArray = !isArray"
        >
          <div class="filterSelectFont">{{ orderBy }}</div>
          <img src="@/assets/arrowdown.svg" alt="array" style="width: 16px" />
        </div>
        <BottomOptionList
          type="정렬2"
          :displayState="isArray"
          @changeOption="updateArray"
          :isReset="isReset"
        ></BottomOptionList>
      </div>
      <EduList
        :EduList="listData"
        :isReset="isReset"
        @changeResetState="getResetState"
      ></EduList>
    </template>
    <template v-else>
      <!-- 데이터 없을경우 -->
      <div class="flex_col" style="margin-top: 143px; align-items: center">
        <img src="@/assets/error-not.svg" alt="" style="width: 32px" />
        <div class="errorInfoFont1">등록된 수료증이 없습니다.</div>
        <div class="errorInfoFont2">
          하단 추가버튼을 눌러 수료증을 등록해주세요.
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BottomOptionList from '@/components/global/option/BottomOptionList.vue';
import EduList from '@/components/education/EduList.vue';
import EducationButton from '@/components/education/EducationButton.vue';
import { getCertificateList } from '@/api/EducationAPI';

export default {
  components: {
    BottomOptionList,
    EduList,
    EducationButton,
  },
  data() {
    return {
      isReset: false,
      isArray: false,
      curPage: 1,
      lastPage: 1,
      category: '전체',
      orderBy: '최신순',
      totalCnt: 0,
      listData: [],
      isScrollRoading: false,
    };
  },
  computed: {
    params() {
      let parameter = Object.entries({
        curPage: this.curPage,
        category: this.category,
        orderBy: this.orderBy,
      })
        .map((e) => e.join('='))
        .join('&');
      return parameter;
    },
  },
  methods: {
    scrollHandler() {
      const scroller = document.getElementById('scroll');
      const isTotallyScrolled =
        Math.abs(
          scroller.scrollHeight - scroller.clientHeight - scroller.scrollTop
        ) < 1;

      if (
        isTotallyScrolled &&
        !this.isScrollRoading &&
        this.curPage < this.lastPage
      ) {
        this.curPage = this.curPage + 1;
        this.isScrollRoading = true;
        this.getEduScrollList();
      }
    },
    getResetState(value) {
      this.isReset = value;
      setTimeout(() => {
        this.isReset = false;
      }, 1000);
    },
    getEduNewList() {
      getCertificateList(this.params)
        .then((res) => {
          // console.log(res.data.result)
          this.listData = res.data.result.list;
          this.totalCnt = res.data.result.totalCnt;
          this.lastPage = Math.ceil(Number(res.data.result.totalCnt) / 10);
          this.isScrollRoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEduScrollList() {
      getCertificateList(this.params)
        .then((res) => {
          // console.log(res.data.result)
          this.listData.push(...res.data.result.list);
          this.totalCnt = res.data.result.totalCnt;
          this.lastPage = Math.ceil(Number(res.data.result.totalCnt) / 10);
          this.isScrollRoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateArray(value) {
      this.orderBy = value;
      this.isArray = false;
    },
  },
  created() {
    this.getEduNewList();
  },
  watch: {
    category: {
      handler: function () {
        this.curPage = 1;
        this.getEduNewList();
      },
    },
    orderBy: {
      handler: function () {
        this.curPage = 1;
        this.getEduNewList();
      },
    },
    isReset: {
      handler: function () {
        if (this.isReset) {
          this.getEduNewList();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
</style>
