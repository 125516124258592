<template>
  <div class="graphStyle">
    <Bar id="my-chart-id" :options="chartOptions" :data="chartData"></Bar>
    <div
      class="flex_row"
      style="
        width: 100%;
        justify-content: space-between;
        padding-left: 35px;
        padding-right: 8px;
      "
    >
      <div
        class="flex_col flex_center"
        @click="$router.push({ path: '/inspection/report', hash: `#S등급` })"
      >
        <div class="baseTagSquare scoreS">S등급</div>
        <div class="fontStyle">{{ this.data.srankListCnt }}개점</div>
      </div>
      <div
        class="flex_col flex_center"
        @click="$router.push({ path: '/inspection/report', hash: `#A등급` })"
      >
        <div class="baseTagSquare scoreA">A등급</div>
        <div class="fontStyle">{{ this.data.arankListCnt }}개점</div>
      </div>
      <div
        class="flex_col flex_center"
        @click="$router.push({ path: '/inspection/report', hash: `#B등급` })"
      >
        <div class="baseTagSquare scoreB">B등급</div>
        <div class="fontStyle">{{ this.data.brankListCnt }}개점</div>
      </div>
      <div
        class="flex_col flex_center"
        @click="$router.push({ path: '/inspection/report', hash: `#C등급` })"
      >
        <div class="baseTagSquare scoreC">C등급</div>
        <div class="fontStyle">{{ this.data.crankListCnt }}개점</div>
      </div>
      <div
        class="flex_col flex_center"
        @click="$router.push({ path: '/inspection/report', hash: `#D등급` })"
      >
        <div class="baseTagSquare scoreD">D등급</div>
        <div class="fontStyle">{{ this.data.drankListCnt }}개점</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  components: {
    Bar,
  },
  props: {
    data: { type: Object, require: true },
  },
  data() {
    return {
      chartData: {
        labels: ["S등급", "A등급", "B등급", "C등급", "D등급"],
        datasets: [
          {
            data: [
              this.data.srankListCnt,
              this.data.arankListCnt,
              this.data.brankListCnt,
              this.data.crankListCnt,
              this.data.drankListCnt,
            ],
            backgroundColor: [
              "rgba(59,210,138,1)",
              "rgba(81,171,255,1)",
              "rgba(252,218,0,1)",
              "rgba(255,164,39,1)",
              "rgba(255,108,104,1)",
            ],
            borderRadius: 8,
            barThickness: 43,
          },
        ],
      },
      chartOptions: {
        animation: true,
        plugins: {
          legend: false, // Hide legend
        },
        scales: {
          y: {
            min: 0,
            max: 10,
            grid: {
              drawTicks: true,
              tickColor: "white",
              tickBorderDash: [5,5],
              // color: "rgba(0, 0, 0, 0.28)",
            },
            ticks: {
              color: "rgba(0, 0, 0, 0.28)",
            },
            border: {
              color: "white",
              dash:[5,5]
            },
          },
          x: {
            display: false, // Hide X axis labels
          },
        },
      },
    };
  },
  created() {
    // console.log(this.data);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
.graphStyle {
  width: 100%;
  padding: 16px 0;
}
.fontStyle {
  color: rgba(18, 18, 18, 0.5);
  font-size: $fs12;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-top: 8px;
}
</style>
