<template>
  <div class="passwordWrap">
    <h2 class="passwordFont" style="margin-bottom: 13px">비밀번호 변경</h2>
    <div class="flex_col">
      <label
        class="passwordLabelStyle"
        :class="{ focusLabelStyle: curPwdFocus, errorLabelStyle: curPwdError }"
        style="max-height: 77px"
      >
        <h5 class="passwordInputFont">현재 비밀번호</h5>
        <div class="flex_row" style="position: relative; gap: 12px">
          <input
            class="passwordInputStyle"
            :type="isShowcurPwd ? 'text' : 'password'"
            placeholder="비밀번호를 입력해주세요"
            :value="curPwd"
            name="curPwd"
            @input="valiCheck('curPwd', $event.target.value)"
            @focus="labelColorChange($event, 'focus')"
            @blur="labelColorChange($event, 'blur')"
            style="width: 100%"
          />
          <img
            v-if="curPwdFocus && curPwd.length > 0"
            src="@/assets/close1.svg"
            alt="cloas"
            style="width: 18px"
            @click="focusControll('curPwd', 'delete')"
          />
          <img
            v-if="curPwd.length > 0 && isShowcurPwd"
            src="@/assets/password-on-icon.svg"
            alt="Show Password"
            @click="focusControll('curPwd', 'blind')"
          />
          <img
            v-else-if="curPwd.length > 0 && isShowcurPwd === false"
            src="@/assets/password-icon.svg"
            alt="Blind Password"
            @click="focusControll('curPwd', 'blind')"
          />
        </div>
      </label>
      <p v-if="curPwdErrorMsg" class="errMsg">{{ curPwdErrorMsg }}</p>
    </div>
    <div class="flex_col">
      <label
        class="passwordLabelStyle"
        :class="{
          focusLabelStyle: changePwdFocus,
          errorLabelStyle: changePwdError,
        }"
        style="max-height: 77px"
      >
        <h5 class="passwordInputFont">신규 비밀번호</h5>
        <div class="flex_row" style="position: relative; gap: 12px">
          <input
            class="passwordInputStyle"
            :type="isShowchangePwd ? 'text' : 'password'"
            placeholder="6자 이상 25자 이하, 문자/숫자/기호 2개 이상 조합"
            :value="changePwd"
            name="changePwd"
            @input="valiCheck('changePwd', $event.target.value)"
            @focus="labelColorChange($event, 'focus')"
            @blur="labelColorChange($event, 'blur')"
            style="width: 100%"
          />
          <img
            v-if="changePwdFocus && changePwd.length > 0"
            src="@/assets/close1.svg"
            alt="cloas"
            style="width: 18px"
            @click="focusControll('changePwd', 'delete')"
          />
          <img
            v-if="changePwd.length > 0 && isShowchangePwd"
            src="@/assets/password-on-icon.svg"
            alt="Show Password"
            @click="focusControll('changePwd', 'blind')"
          />
          <img
            v-else-if="changePwd.length > 0 && isShowchangePwd === false"
            src="@/assets/password-icon.svg"
            alt="Blind Password"
            @click="focusControll('changePwd', 'blind')"
          />
        </div>
      </label>
      <p v-if="changePwdErrorMsg" class="errMsg">{{ changePwdErrorMsg }}</p>
    </div>
    <div class="flex_col">
      <label
        class="passwordLabelStyle"
        :class="{
          focusLabelStyle: changePwdCFFocus,
          errorLabelStyle: changePwdCFError,
        }"
        style="max-height: 77px"
      >
        <h5 class="passwordInputFont">신규 비밀번호 확인</h5>
        <div class="flex_row" style="position: relative; gap: 12px">
          <input
            class="passwordInputStyle"
            :type="isShowchangePwdCF ? 'text' : 'password'"
            placeholder="비밀번호 입력"
            :value="changePwdCF"
            name="changePwdCF"
            @input="valiCheck('changePwdCF', $event.target.value)"
            @focus="labelColorChange($event, 'focus')"
            @blur="labelColorChange($event, 'blur')"
            style="width: 100%"
          />
          <img
            v-if="changePwdCFFocus && changePwdCF.length > 0"
            src="@/assets/close1.svg"
            alt="cloas"
            style="width: 18px"
            @click="focusControll('changePwdCF', 'delete')"
          />
          <img
            v-if="changePwdCF.length > 0 && isShowchangePwdCF"
            src="@/assets/password-on-icon.svg"
            alt="Show Password"
            @click="focusControll('changePwdCF', 'blind')"
          />
          <img
            v-else-if="changePwdCF.length > 0 && isShowchangePwdCF === false"
            src="@/assets/password-icon.svg"
            alt="Blind Password"
            @click="focusControll('changePwdCF', 'blind')"
          />
        </div>
      </label>
      <p v-if="changePwdCFErrorMsg" class="errMsg">{{ changePwdCFErrorMsg }}</p>
    </div>
    <div style="margin-top: auto; margin-top: 161px">
      <ButtonBasic
        type="fill"
        :isDisable="!isValid"
        text="비밀번호 재설정"
        :clickEvent="letChangePassword"
      />
    </div>
    <CommonAlert2
      :state="isShowAlert"
      @changeDisplay="getAlertDisplayState"
      @confirmAction="confirmAction"
    >
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #content2 v-if="alertContent2.length !== 0">
        {{ alertContent2 }}
      </template>
      <template #btn>
        {{ alertBtn }}
      </template>
    </CommonAlert2>
  </div>
</template>

<script>
import ButtonBasic from '@/components/global/inputs/ButtonBasic.vue';
import { changePassword } from '@/api/LoginAPI';
import CommonAlert2 from '@/components/global/alert/CommonAlert2.vue';

export default {
  name: 'password-change',
  components: {
    ButtonBasic,
    CommonAlert2,
  },
  props: {},
  data() {
    return {
      curPwd: '',
      curPwdFocus: false,
      curPwdError: false,
      isShowcurPwd: false,
      curPwdErrorMsg: '',
      changePwd: '',
      changePwdFocus: false,
      changePwdError: false,
      isShowchangePwd: false,
      changePwdErrorMsg: '',
      changePwdCF: '',
      changePwdCFFocus: false,
      changePwdCFError: false,
      isShowchangePwdCF: false,
      changePwdCFErrorMsg: '',
      isShowAlert: false,
      alertTitle: '',
      alertContent1: '',
      alertContent2: '',
      alertBtn: '',
    };
  },
  methods: {
    focusControll(type, action) {
      if (type === 'curPwd') {
        if (action === 'delete') {
          this.curPwd = '';
        } else {
          this.isShowcurPwd = !this.isShowcurPwd;
        }
        setTimeout(() => {
          this.curPwdFocus = true;
        }, 100);
      } else if (type === 'changePwd') {
        if (action === 'delete') {
          this.changePwd = '';
        } else {
          this.isShowchangePwd = !this.isShowchangePwd;
        }
        setTimeout(() => {
          this.changePwdFocus = true;
        }, 100);
      } else {
        if (action === 'delete') {
          this.changePwdCF = '';
        } else {
          this.isShowchangePwdCF = !this.isShowchangePwdCF;
        }
        setTimeout(() => {
          this.changePwdCFFocus = true;
        }, 100);
      }
    },
    getAlertDisplayState(value) {
      this.isShowAlert = value;
    },
    confirmAction() {
      if (this.alertTitle === '비밀번호 변경 완료') {
        this.$router.push({ name: `login-page` });
      }
    },
    valiCheck(type, data) {
      if (type === 'curPwd') {
        this.curPwd = data;
      } else if (type === 'changePwd') {
        this.changePwd = data;
      } else if (type === 'changePwdCF') {
        this.changePwdCF = data;
      }
    },
    labelColorChange(item, state) {
      // focus or blur border color change
      if (state === 'focus') {
        if (item.target.name === 'curPwd') {
          this.curPwdFocus = true;
        } else if (item.target.name === 'changePwd') {
          this.changePwdFocus = true;
        } else if (item.target.name === 'changePwdCF') {
          this.changePwdCFFocus = true;
        }
      } else {
        if (item.target.name === 'curPwd') {
          setTimeout(() => {
            this.curPwdFocus = false;
          }, 100);
        } else if (item.target.name === 'changePwd') {
          const pwdRegExp =
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,25}$|^(?=.*[A-Za-z])(?=.*[~!@#$%^&*()_+=;:'"?])[A-Za-z~!@#$%^&*()_+=;:'"?]{6,25}$|^(?=.*\d)(?=.*[~!@#$%^&*()_+=;:'"?])[\d~!@#$%^&*()_+=;:'"?]{6,25}$|^(?=.*[A-Za-z])(?=.*[~!@#$%^&*()_+=;:'"?])(?=.*\d)[A-Za-z\d~!@#$%^&*()_+=;:'"?]{6,25}$/;
          if (pwdRegExp.test(this.changePwd)) {
            setTimeout(() => {
              this.changePwdFocus = false;
              this.changePwdError = false;
              this.changePwdErrorMsg = '';
            }, 100);
          } else {
            this.changePwdError = true;
            this.changePwdErrorMsg =
              '6자 이상 25자 이하, 문자/숫자/기호 2개 이상 조합을 사용해주세요.';
          }
        } else if (item.target.name === 'changePwdCF') {
          if (this.changePwdCF === this.changePwd) {
            setTimeout(() => {
              this.changePwdCFFocus = false;
              this.changePwdCFError = false;
              this.changePwdCFErrorMsg = '';
            }, 100);
          } else {
            this.changePwdCFError = true;
            this.changePwdCFErrorMsg = '잘못된 비밀번호 입니다.';
          }
        }
      }
    },
    letChangePassword() {
      const formData = new FormData();
      formData.append('curPwd', this.curPwd);
      formData.append('changePwd', this.changePwd);
      const password = formData;
      changePassword(password)
        .then((res) => {
          if (res.data.isSuccessful) {
            // Alert : 비밀번호 변경 완료
            this.alertTitle = '비밀번호 변경 완료';
            this.alertContent1 = '비밀번호를 정상적으로 변경하였습니다.';
            this.alertContent2 = '다시 로그인해 주세요.';
            this.alertBtn = '로그인';
            this.isShowAlert = true;
          } else {
            this.curPwdError = true;
            this.curPwd = '';
            this.curPwdErrorMsg = '잘못된 비밀번호 입니다.';
            // console.log(res.data.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    isValid() {
      let value = null;
      if (
        this.curPwd.length > 1 &&
        this.changePwd.length > 1 &&
        this.changePwdCF.length > 1
      ) {
        if (this.changePwdError || this.changePwd !== this.changePwdCF) {
          value = false;
        } else {
          value = true;
        }
      } else {
        value = false;
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.sendBtn {
  padding: 10px 6px;
  width: fit-content;
  height: 29px;
  font-size: $fs14;
}
.count {
  color: $black50;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.info {
  text-align: center;
  color: $black50;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
  text-decoration: underline;
  height: 20px;
  margin-top: auto;
}
.errMsg {
  color: $error;
  font-size: $fs12;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: -2%;
  margin-top: 8px;
}
</style>
