<template>
  <div>
    <MenuBar v-if="menuDisplay" :state="menuDisplay" @changeDisplay="getMenuDisplayState" />
    <div
      class="headerWrap"
      @click="test"
      :class="{
        bordernone:
          this.$route.name === 'inspection-list' ||
          this.$route.name === 'learning-list' ||
          this.$route.name === 'certificate-list' ||
          this.$route.name === 'document-list' ||
          this.$route.name === 'notice-list',
        fixed: $route.name === 'my-schedule',
      }"
    >
      <div @click="menuDisplay = !menuDisplay" style="width: 50px; height: 24px">
        <img src="@/assets/menu.svg" alt="" width="24px" height="24px" />
      </div>

      <!-- title or logo -->
      <div
        v-if="$route.meta.title === '메인'"
        class="headerTitle"
        style="width: 38px; height: 25px"
      >
        <img
          src="@/assets/logoBlack.svg"
          alt="logo"
          width="38px"
          height="25px"
          @click="$router.push('/login-page')"
        />
      </div>
      <div v-else class="headerTitle">{{ $route.meta.title }}</div>

      <div
        v-if="$route.name === 'my-schedule'"
        class="flex_center headerTextBtn"
        @click="$router.push('/my-schedule/add')"
        style="margin-right: 0"
      >
        추가
      </div>
      <div
        v-else
        class="headerNotiBtn"
        style="width: 50px; height: 24px; display: flex; justify-content: flex-end"
        @click="$router.push({ name: 'alarm-list' })"
      >
        <div v-if="isAlarm" class="alarm"></div>
        <img src="@/assets/noti.svg" alt="" width="24px" height="24px" />
      </div>
    </div>
  </div>
</template>

<script>
import { getIsAlarm } from '@/api/DocumentAPI';
import MenuBar from '@/components/global/MenuBar.vue';
import { Capacitor } from '@capacitor/core';

export default {
  name: 'default-header',
  components: {
    MenuBar,
  },
  props: {},
  data() {
    return {
      menuDisplay: false,
      isAlarm: null,
    };
  },
  methods: {
    getMenuDisplayState(value) {
      this.menuDisplay = value;
    },
    getIsAlarmCheck() {
      getIsAlarm()
        .then((res) => {
          if (res.data.isSuccessful) {
            if (res.data.result.checkCnt) {
              this.isAlarm = true;
            } else {
              this.isAlarm = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    test() {
      if (Capacitor.isNativePlatform()) {
        console.log('@capacitor', localStorage.getItem('fcmToken'));
      }
    },
  },
  watch: {
    $route() {
      this.getIsAlarmCheck();
    },
  },
  // created() {
  //   console.log(this.$route)
  //   this.getIsAlarmCheck()
  // }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/header.scss';

.bordernone {
  border-bottom: none;
}
.headerWrap {
  padding: 0 18px;
}
.fixed {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.headerWrap.fixed {
  padding: env(safe-area-inset-top) 18px 0;
  height: calc(env(safe-area-inset-top) + 58px);
  max-height: calc(env(safe-area-inset-top) + 58px);
  min-height: calc(env(safe-area-inset-top) + 58px);
}
</style>
