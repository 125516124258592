<template>
  <div v-if="state==='report'">
    <div class="flex_col flex_center btnWrap" @click="isMenu=!isMenu">
        <img src="@/assets/checklist.svg" alt="" style="width:16px;margin-bottom:4px"/>
        점검하기
    </div>
    <BottomOptionList type="inspection" :displayState="isMenu"></BottomOptionList>
  </div>
</template>
  
  <script>
import { ref } from "vue";
import BottomOptionList from "@/components/global/option/BottomOptionList.vue"
  export default {
    components: {
      BottomOptionList,
    },
    props: {
      state: { type: String, required: true }
    },
    setup() {
    const isMenu = ref(false);
    return{
      isMenu,
    }
  }
  }
  </script>
  
  
  <style lang="scss" scoped>
    @import '@/assets/scss/variable.scss';
    @import '@/assets/scss/component.scss';
    .btnWrap {
      width: 62px;
      height: 62px;
      border-radius: 100%;
      position: fixed;
      bottom: 40px;
      right: 24px;
      background-color: $burgundy;
      color: $white100;
      font-size: $fs12;
      font-weight: 500; 
      line-height: 14.4px;
      letter-spacing: -1.3%;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
    }
  </style>