<template>
  <div class="passwordWrap">
    <h2 class="passwordFont" style="margin-bottom: 13px">비밀번호 재설정</h2>
    <label
      class="passwordLabelStyle"
      :class="{
        focusLabelStyle: changePwdFocus,
        errorLabelStyle: changePwdError,
      }"
      style="max-height: 77px"
    >
      <h5 class="passwordInputFont">신규 비밀번호</h5>
      <div class="flex_row" style="position: relative; gap: 12px">
        <input
          class="passwordInputStyle"
          :type="isShowchangePwd ? 'text' : 'password'"
          placeholder="6자 이상 25자 이하, 문자/숫자/기호 2개 이상 조합"
          :value="changePwd"
          name="changePwd"
          @input="valiCheck('changePwd', $event.target.value)"
          @focus="labelColorChange($event, 'focus')"
          @blur="labelColorChange($event, 'blur')"
          style="width: 100%"
        />
        <img
          v-if="changePwdFocus && changePwd.length > 0"
          src="@/assets/close1.svg"
          alt="delete"
          style="width: 18px"
          @click="focusControll('changePwd', 'delete')"
        />
        <img
          v-if="changePwd.length > 0 && isShowchangePwd"
          src="@/assets/password-on-icon.svg"
          alt="Show Password"
          @click="focusControll('changePwd', 'blind')"
        />
        <img
          v-else-if="changePwd.length > 0 && isShowchangePwd === false"
          src="@/assets/password-icon.svg"
          alt="Blind Password"
          @click="focusControll('changePwd', 'blind')"
        />
      </div>
    </label>
    <p v-if="changePwdErrorMsg" class="errMsg">{{ changePwdErrorMsg }}</p>
    <label
      class="passwordLabelStyle"
      :class="{
        focusLabelStyle: changePwdCFFocus,
        errorLabelStyle: changePwdCFError,
      }"
      style="max-height: 77px"
    >
      <h5 class="passwordInputFont">신규 비밀번호 확인</h5>
      <div class="flex_row" style="position: relative; gap: 12px">
        <input
          class="passwordInputStyle"
          :type="isShowchangePwdCF ? 'text' : 'password'"
          placeholder="비밀번호 입력"
          :value="changePwdCF"
          name="changePwdCF"
          @input="valiCheck('changePwdCF', $event.target.value)"
          @focus="labelColorChange($event, 'focus')"
          @blur="labelColorChange($event, 'blur')"
          style="width: 100%"
        />
        <img
          v-if="changePwdCFFocus && changePwdCF.length > 0"
          src="@/assets/close1.svg"
          alt="delete"
          style="width: 18px"
          @click="focusControll('changePwdCF', 'delete')"
        />
        <img
          v-if="changePwdCF.length > 0 && isShowchangePwdCF"
          src="@/assets/password-on-icon.svg"
          alt="Show Password"
          @click="focusControll('changePwdCF', 'blind')"
        />
        <img
          v-else-if="changePwdCF.length > 0 && isShowchangePwdCF === false"
          src="@/assets/password-icon.svg"
          alt="Blind Password"
          @click="focusControll('changePwdCF', 'blind')"
        />
      </div>
    </label>
    <p v-if="changePwdCFErrorMsg" class="errMsg">{{ changePwdCFErrorMsg }}</p>
    <div style="width: 100%; margin-top: 250px">
      <ButtonBasic
        type="fill"
        :isDisable="!isValid"
        text="비밀번호 재설정"
        :clickEvent="letChangePassword"
      />
    </div>
    <CommonAlert2
      :state="isShowAlert"
      @changeDisplay="getAlertDisplayState"
      @confirmAction="confirmAction"
    >
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #content2 v-if="alertContent2.length !== 0">
        {{ alertContent2 }}
      </template>
      <template #btn>
        {{ alertBtn }}
      </template>
    </CommonAlert2>
  </div>
</template>

<script>
import ButtonBasic from '@/components/global/inputs/ButtonBasic.vue';
import { resetPassword } from '@/api/LoginAPI';
import CommonAlert2 from '@/components/global/alert/CommonAlert2.vue';

export default {
  name: 'password-reset',
  components: {
    ButtonBasic,
    CommonAlert2,
  },
  props: {},
  data() {
    return {
      email: '',
      token: '',
      changePwd: '',
      changePwdFocus: false,
      changePwdError: false,
      isShowchangePwd: false,
      changePwdErrorMsg: '',
      changePwdCF: '',
      changePwdCFFocus: false,
      changePwdCFError: false,
      isShowchangePwdCF: false,
      changePwdCFErrorMsg: '',
      isShowAlert: false,
      alertTitle: '',
      alertContent1: '',
      alertContent2: '',
      alertBtn: '',
    };
  },
  methods: {
    focusControll(type, action) {
      if (type === 'changePwd') {
        if (action === 'delete') {
          this.changePwd = '';
        } else {
          this.isShowchangePwd = !this.isShowchangePwd;
        }
        setTimeout(() => {
          this.changePwdFocus = true;
        }, 100);
      } else {
        if (action === 'delete') {
          this.changePwdCF = '';
        } else {
          this.isShowchangePwdCF = !this.isShowchangePwdCF;
        }
        setTimeout(() => {
          this.changePwdCFFocus = true;
        }, 100);
      }
    },
    getAlertDisplayState(value) {
      this.isShowAlert = value;
    },
    confirmAction() {
      if (this.alertTitle === '비밀번호 재설정 완료') {
        this.$router.push({ name: `login-page` });
      }
    },
    valiCheck(type, data) {
      if (type === 'changePwd') {
        this.changePwd = data;
      } else if (type === 'changePwdCF') {
        this.changePwdCF = data;
        if (this.changePwd !== this.changePwdCF) {
          this.changePwdCFError = true;
          this.changePwdCFErrorMsg = '비밀번호가 일치하지 않습니다.';
        } else {
          this.changePwdCFError = false;
          this.changePwdCFErrorMsg = '';
        }
      }
    },
    labelColorChange(item, state) {
      // focus or blur border color change
      if (state === 'focus') {
        if (item.target.name === 'changePwd') {
          this.changePwdFocus = true;
        } else if (item.target.name === 'changePwdCF') {
          this.changePwdCFFocus = true;
        }
      } else {
        if (item.target.name === 'changePwd') {
          setTimeout(() => {
            this.changePwdFocus = false;
          }, 100);
          const pwdRegExp =
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,25}$|^(?=.*[A-Za-z])(?=.*[~!@#$%^&*()_+=;:'"?])[A-Za-z~!@#$%^&*()_+=;:'"?]{6,25}$|^(?=.*\d)(?=.*[~!@#$%^&*()_+=;:'"?])[\d~!@#$%^&*()_+=;:'"?]{6,25}$|^(?=.*[A-Za-z])(?=.*[~!@#$%^&*()_+=;:'"?])(?=.*\d)[A-Za-z\d~!@#$%^&*()_+=;:'"?]{6,25}$/;
          if (!pwdRegExp.test(this.changePwd)) {
            this.changePwdError = true;
            this.changePwdErrorMsg =
              '* 6자 이상 25자 이하, 문자/숫자/기호 2개 이상 조합을 사용해야 합니다.';
          } else {
            this.changePwdError = false;
          }
        } else if (item.target.name === 'changePwdCF') {
          setTimeout(() => {
            this.changePwdCFFocus = false;
          }, 100);
        }
      }
    },
    letChangePassword() {
      const formData = new FormData();
      formData.append('email', this.email);
      formData.append('changePwd', this.changePwd);
      const password = formData;
      resetPassword(password, this.token)
        .then((res) => {
          // console.log(res.data)
          if (res.data.isSuccessful) {
            this.alertTitle = '비밀번호 재설정 완료';
            this.alertContent1 = '비밀번호를 정상적으로 변경하였습니다.';
            this.alertContent2 = '다시 로그인해 주세요.';
            this.alertBtn = '로그인';
            this.isShowAlert = true;
          } else {
            // msg에 따라 Action
            // console.log(res.data.msg)
            this.alertTitle = '비밀번호 재설정 실패';
            this.alertContent1 = res.data.msg;
            this.alertBtn = '닫기';
            this.isShowAlert = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    isValid() {
      let value = null;
      if (this.changePwd.length > 1 && this.changePwdCF.length > 1) {
        value = true;
      } else {
        value = false;
      }
      return value;
    },
  },
  created() {
    // 라우터 파라미터로 받은 값 Email 넣기
    this.email = this.$route.params.email;
    this.token = this.$route.params.token;
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.sendBtn {
  padding: 10px 6px;
  width: fit-content;
  height: 29px;
  font-size: $fs14;
}
.count {
  color: $black50;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.info {
  text-align: center;
  color: $black50;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
  text-decoration: underline;
  height: 20px;
  margin-top: auto;
}
.errMsg {
  color: $error;
  font-size: $fs12;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: -2%;
}
</style>
