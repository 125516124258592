<template>
  <div v-if="curData" class="flex_col scroll" id="envTop">
    <div class="padding">
      <div class="flex_col" style="gap: 8px">
        <div class="fontStyle1">제목</div>
        <div class="fontStyle2">{{ curData.title }}</div>
      </div>
      <div class="line"></div>
      <div class="flex_col" style="gap: 8px">
        <div class="fontStyle1">내용</div>
        <div class="fontStyle2">{{ curData.content }}</div>
      </div>
      <div class="line"></div>
      <div class="flex_col" style="gap: 8px">
        <div class="fontStyle1">방문 일시</div>
        <div class="fontStyle2">
          {{ curData.workAt.replace("-", "년 ").replace("-", "월 ") }}일
          {{ transferDay(curData.workAt) }}
          {{ curData.workAt === today ? "(오늘)" : "" }}
          {{ curData.workTime.slice(0, 5) }} {{ curData.workTime.slice(8) }}
        </div>
      </div>
    </div>
    <button
      class="headerTextBtn saveBtn"
      :disabled="vailCheck"
      :style="`top:${envTopSize}px;margin-right: 0`"
      @click="
        $router.push({
          path: `/my-schedule/edit/${id}`,
          params: {
            id: id,
          },
        })
      "
    >
      수정
    </button>
    <div class="bottomOptBtn" @click="isShowAlert = true">삭제하기</div>
    <CommonAlertTwoBtn
      :state="isShowAlert"
      @changeDisplay="getAlertDisplayState"
      @confirmAction1="confirmAction1"
      @confirmAction2="confirmAction2"
    >
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #content2 v-if="alertContent2.length !== 0">
        {{ alertContent2 }}
      </template>
      <template #btn1>
        {{ alertBtn1 }}
      </template>
      <template #btn2>
        {{ alertBtn2 }}
      </template>
    </CommonAlertTwoBtn>
    <ToastMsg :message="toastMassage" :display="toastDisplay"></ToastMsg>
  </div>
</template>

<script>
import { getScheduleDetail, delSchedule } from "@/api/SchedulAPI";
import transferDay from "@/functions/transferDay";
import returnToday from "@/functions/returnToday";
import CommonAlertTwoBtn from "@/components/global/alert/CommonAlertTwoBtn";
import ToastMsg from "@/components/global/alert/ToastMsg.vue";

export default {
  name: "my-schedule-detail",
  components: {
    CommonAlertTwoBtn,
    ToastMsg,
  },
  props: {},
  data() {
    return {
      envTopSize: 0,
      id: null,
      curData: {},
      toastDisplay: false,
      optionState: false,
      isShowAlert: false,
      toastMassage: "",
      alertTitle: "일정 삭제",
      alertContent1: "한번 삭제된 일정은 복구하실 수 없습니다.",
      alertContent2: "정말로 삭제하시곘습니까?",
      alertBtn1: "취소",
      alertBtn2: "삭제",
    };
  },
  methods: {
    getAlertDisplayState(value) {
      this.isShowAlert = value;
    },
    confirmAction1() {
      this.isShowAlert = false;
    },
    confirmAction2() {
      delSchedule(this.id)
        .then((res) => {
          if (res.data.isSuccessful) {
            this.toastMassage = "삭제되었습니다";
            this.toastDisplay = true;
            setTimeout(() => {
              this.toastDisplay = false;
              this.toastMassage = "";
              this.$router.replace({ name: "my-schedule" });
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSchedule() {
      getScheduleDetail(this.id)
        .then((res) => {
          if (res.data.isSuccessful) {
            // console.log(res.data.result)
            this.curData = res.data.result;
          } else {
            // console.log(res.data.isSuccessful)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    transferDay(value) {
      return transferDay(value);
    },
    today() {
      return returnToday();
    },
  },

  mounted() {
    this.envTopSize =
      Number(
        getComputedStyle(document.getElementById("envTop"))
          .getPropertyValue("--safe-area-inset-top")
          .replace(/px/g, "")
      ) + 17;
  },
  created() {
    this.id = this.$route.params.id;
    this.getSchedule();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/header.scss";
@import "@/assets/scss/component.scss";
.padding {
  padding: 24px;
}
.line {
  height: 1px;
  width: 100%;
  border: none;
  background-color: $black8;
  margin: 20px 0;
}
.fontStyle1 {
  color: $black50;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.fontStyle2 {
  color: $black100;
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.saveBtn {
  font-family: "Pretendard";
  border: none;
  background-color: white;
  text-align: right;
  position: absolute;
  right: 18px;
  &:disabled {
    color: $gray2;
  }
}

#envTop {
  --safe-area-inset-top: env(safe-area-inset-top);
}
</style>
