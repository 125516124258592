<template>
  <div v-if="rank" class="baseTagSquare" :class="score">{{ scoreData }}</div>
</template>

<script>
export default {
  props: {
    rank: {
      type: String, require: true
    }
  },
  data () {
    return {
      scoreData: this.rank,
    }
  },
  computed: {
      score(){
        let propRank = this.rank
        if(propRank==='A등급'){
          return "scoreA"
        }else if(propRank==='B등급'){
          return "scoreB"
        }else if(propRank==='C등급'){
          return "scoreC"
        }else if(propRank==='D등급'){
          return "scoreD"
        }else{
          return "scoreS"
        }
      },
    },
  watch: {
    rank: {
        //handler: function(바뀐값,바뀌기전 값)
        handler: function () {
          this.scoreData = this.rank
        }
      },
    },
    mounted(){
        // console.log("updated",this.rank)
        // console.log("updated",this.scoreData)
      },
}
</script>


<style lang="scss" scoped>
  @import '@/assets/scss/variable.scss';
  @import '@/assets/scss/component.scss';
</style>