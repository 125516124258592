import API from "@/api/BaseUrl";

// 문서 목록 조회
export async function getDocumentList(docListGetQuery) {
    return API.get(`document?${docListGetQuery}`)
}
// 문서 상세 조회
export async function getDocumentDetail(docDetailGetQuery) {
    return API.get(`document/${docDetailGetQuery}`)
}
// 공지사항 목록 조회
export async function getNoticeList(noticeListGetQuery) {
    return API.get(`notice?${noticeListGetQuery}`)
}
// 공지사항 상세 조회
export async function getNoticeDetail(noticeDetailGetQuery) {
    return API.get(`notice/${noticeDetailGetQuery}`)
}


// 알림 확인 조회 (다읽었으면false 안읽은게있으면true)
export async function getIsAlarm() {
    return API.get(`checkAlarm`)
}
// 알림 목록 조회
export async function getAlarmList(page) {
    return API.get(`alarm?curPage=${page}`)
}
// 알림 확인 처리
export async function putIsAlarm(id) {
    return API.patch(`alarm?alarmId=${id}`)
}
