<template>
  <div class="storeInfo" v-if="data">
    <div class="flex_row flex_between">
      <div class="flex_row" style="align-items: center">
        <div class="companyName">{{ data[0].storeName }}</div>
        <div class="companyCode">
          {{ data[0].code ? data[0].code : data[0].storeCode }}
        </div>
      </div>
      <img
        src="@/assets/arrow5.svg"
        alt="more"
        style="width: 20px"
        @click="isStoreDetail = !isStoreDetail"
        :class="{ rotateImage: isStoreDetail }"
      />
    </div>
    <div class="storeInfoBottom" v-if="isStoreDetail">
      <div class="personFont" style="margin-bottom: 5px">
        {{ data[0].managerName ? data[0].managerName : data[0].storeManager }}
      </div>
      <div class="companyAddress">
        {{ data[0].addr ? data[0].addr : data[0].storeAddr }}
      </div>
      <div class="flex_row flex_between" style="margin-top: 20px">
        <div class="flex_row" style="gap: 6px">
          <div
            class="tagStyle"
            style="font-size: 14px; color: rgba(0, 0, 0, 0.32)"
          >
            {{ data[0].line ? data[0].line : data[0].storeLine }}
          </div>
          <div
            class="tagStyle"
            style="font-size: 14px; color: rgba(0, 0, 0, 0.32)"
          >
            {{ data[0].team ? data[0].team : data[0].storeTeam }}
          </div>
        </div>
        <!-- <div class="baseBtn line callBtn" @click="isCall=!isCall">
                    <img src="@/assets/call-icon.svg" alt="call" style="width:17px;margin-right:5px"/>
                    통화하기
                </div> -->
      </div>
    </div>
    <!-- <BottomOptionCall :display="isCall" :number="data[0].storeTel" @changeDisplay="getCallDisplay"></BottomOptionCall> -->
  </div>
</template>

<script>
// import BottomOptionCall from '@/components/global/option/BottomOptionCall.vue'
export default {
  components: {
    // BottomOptionCall
  },
  props: {
    data: { type: Array, require: true },
  },
  data() {
    return {
      isStoreDetail: false,
      isCall: false,
    };
  },
  methods: {
    getCallDisplay(value) {
      this.isCall = value;
    },
  },
  created() {
    //   console.log("매장정보",this.data)
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
@import "@/assets/scss/qsc.scss";
</style>
