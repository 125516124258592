<template>
  <div v-if="data" class="flex_col" style="width: 100%; gap: 12px">
    <div
      v-for="value in data"
      :key="value.id"
      class="flex_col listCard"
      @click="
        $router.push({
          path: `/qsc/detail/${value.id}`,
          params: {
            id: value.id,
          },
        })
      "
    >
      <div class="flex_row flex_between">
        <div class="flex_row">
          <div
            class="flex_col baseTagRound resize"
            :class="`score${value.rank.slice(0, 1)}`"
          >
            {{ value.rank.slice(0, 1) }}
          </div>
          <div class="flex_col">
            <div class="dayFont">
              {{ value.reportType }}
            </div>
            <div class="personFont">
              {{ value.templateTitle }} <span class="gray">|</span>
              {{ value.managerName }}
            </div>
          </div>
        </div>
        <img src="@/assets/arrow3.svg" alt="" style="width: 20px" />
      </div>
      <table class="table">
        <tr>
          <td class="border bg">중점 관리 사항</td>
          <td class="border bg">일반관리</td>
          <td class="border bg">문서관리</td>
        </tr>
        <tr>
          <td class="border">{{ value.law_score }}</td>
          <td class="border">{{ value.hygiene_score }}</td>
          <td class="border">{{ value.service_score }}</td>
        </tr>
      </table>
      <ScoreList2 :data="value"></ScoreList2>
    </div>
  </div>
</template>

<script>
import ScoreList2 from '@/components/inspection/ScoreList2.vue';
export default {
  props: {
    data: { type: Array, require: true },
  },
  components: { ScoreList2 },
  created() {
    // console.log("리포트내용", this.data);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.listCard {
  padding: 20px;
  background-color: $white100;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  justify-content: space-between;
  gap: 14px;
}
.dayFont {
  color: $black50;
  font-weight: 400;
  font-size: $fs12;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-bottom: 2px;
}
.personFont {
  color: $black100;
  font-weight: 500;
  font-size: $fs12;
  line-height: 14.4px;
  letter-spacing: -1.3%;
}
.titleStyle {
  color: $black100;
  font-size: $fs18;
  font-weight: 600;
  line-height: 21.6px;
  letter-spacing: -2%;
  margin: 0;
  margin-bottom: 4px;
}
.table {
  border-collapse: collapse;
  border: none;
  color: $black100;
  font-size: $fs10;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -2%;
  text-align: center;
}
.border {
  padding: 4px;
  border: 1px solid $black8;
}
.bg {
  background-color: $black4;
}
.resize {
  width: 32px;
  height: 32px;
  font-size: $fs16;
  line-height: 19.2px;
  margin-right: 10px;
}
.gray {
  font-size: $fs10;
  color: $black28;
}
</style>
