<template>
  <div id="scroll" class="flex_col scroll" @scroll="scrollHandler()">
    <div
      v-if="getData.length > 0"
      style="padding-bottom: 150px; height: fit-content"
    >
      <div
        v-for="data in getData"
        :key="data.id"
        class="flex_row flex_between wrap"
        @click="
          $router.push({
            path: `/notice/${data.id}`,
            params: {
              id: data.id,
            },
          })
        "
      >
        <div class="flex_col" style="width: 100%">
          <div class="flex_row flex_between">
            <div class="fontStyle1">{{ data.title }}</div>
            <img src="@/assets/arrow3.svg" alt="" width="20px" />
          </div>
          <div class="fontStyle2">
            {{ data.updateAt.slice(0, 16) }}{{ data.updateAt.slice(19) }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex_col" style="margin-top: 143px; align-items: center">
        <img src="@/assets/error-not.svg" alt="" style="width: 32px" />
        <div class="errorInfoFont1">공지사항이 없습니다.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNoticeList } from '@/api/DocumentAPI';

export default {
  name: 'notice-page',
  data() {
    return {
      curPage: 1,
      lastPage: 1,
      getData: [],
      isScrollRoading: false,
    };
  },
  computed: {
    params() {
      let parameter = Object.entries({
        curPage: this.curPage,
      })
        .map((e) => e.join('='))
        .join('&');
      return parameter;
    },
  },
  methods: {
    scrollHandler() {
      // TODO 무한스크롤 보완 필요 BE 변경 필요
      const scroller = document.getElementById('scroll');
      const isTotallyScrolled =
        Math.abs(
          scroller.scrollHeight - scroller.clientHeight - scroller.scrollTop
        ) < 1;

      if (isTotallyScrolled && !this.isScrollRoading) {
        this.curPage = this.curPage + 1;
        this.isScrollRoading = true;
        this.getNotiListScroll();
      }
    },
    getNotiNewList() {
      getNoticeList(this.params)
        .then((res) => {
          // console.log(res.data.result)
          this.getData = res.data.result.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNotiListScroll() {
      getNoticeList(this.params)
        .then((res) => {
          // console.log(res.data.result)
          this.getData.push(...res.data.result.list);
          this.isScrollRoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getNotiNewList();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.fontStyle1 {
  color: $black100;
  font-size: $fs16;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: -1.3%;
  margin-bottom: 4px;
}
.fontStyle2 {
  color: $black32;
  font-size: $fs10;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -2%;
}
.wrap {
  border-bottom: 1px solid $black8;
  padding: 16px;
}
</style>
