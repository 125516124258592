<template>
  <button type="submit" class="baseBtn" :class="type" :disabled="isDisable" @click.prevent="clickEvent" :style="{ width: width }">{{ text }}</button>
</template>

<script>
export default {
  name: 'default-header',
  components: {
  },
  props: {
    text: { type: String, required: true, default:'버튼' },
    isDisable: { type: Boolean, required: true, default:false },
    type: { type: String, required: true, default:'fill' },
    // button type = > fill or line
    width: { type: String, required: false, default:'100%' },
    clickEvent: {type: Function, required: false},
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variable.scss';
  @import '@/assets/scss/component.scss';
</style>
