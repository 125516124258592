<template>
  <div v-if="display" class="popUpBG">
    <a
      :href="data.link"
      target="_blank"
      style="height: 304px; width: 304px; overflow: hidden"
    >
      <img :src="data.thum" alt="" class="imgStyle" />
    </a>
    <div
      class="flex_row flex_between"
      style="background-color: white; border-radius: 0 0 22px 22px"
    >
      <div class="closeArea" @click="closePopUpToday">
        <img
          src="@/assets/check-icon-btn.svg"
          alt=""
          style="width: 20px; height: 20px; margin-right: 4px"
        />
        하루 동안 보지 않기
      </div>
      <div class="closeArea" @click="closePopUp">닫기</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: { type: Object, required: true },
    displayPopUp: { type: Array, required: true },
  },
  data() {
    return {
      display: true,
    };
  },
  methods: {
    closePopUp() {
      this.$emit('closePopUp', this.data.id);
    },
    closePopUpToday() {
      this.$emit('closePopUpToday', this.data.id);
    },
  },
  created() {
    // console.log(this.data);
    // console.log(this.displayPopUp);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.imgStyle {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 22px 22px 0 0;
}
.popUpBG {
  box-sizing: border-box;
  min-width: 304px;
  max-width: 304px;
  z-index: 50;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 27%;
  left: 50%;
  transform: translate(-50%);
  border-radius: 22px;
  z-index: 60;
}
.absoulte {
  position: absolute;
  bottom: 0;
  left: 0;
}
.fontStyle1 {
  font-size: $fs20;
  font-weight: 600;
  line-height: 24px;
  color: $white100;
  letter-spacing: -1.3%;
  margin: 0;
  margin-bottom: 6px;
  padding-left: 20px;
}
.fontStyle2 {
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  color: $white100;
  letter-spacing: -2%;
  margin: 0;
  padding-left: 20px;
  margin-bottom: 24px;
}
.fontStyle3 {
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  color: $black100;
  letter-spacing: -1.3%;
  margin: 0;
}
.closeArea {
  display: flex;
  padding: 16px;
  align-items: center;
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  color: $black100;
  letter-spacing: -2%;
  cursor: pointer;
}
</style>
