<template>
  <div v-if="data">
    <!-- point! 피드백 부분은 필수 입력이 아님
     제출은 모든 항목 체크 후에만 가능 -->
    <div class="flex_col" style="padding: 16px 0">
      <div class="flex_row flex_between" style="align-items: flex-start">
        <div class="inspectionQuestion" :class="{ strong: answer === false }">
          <div v-if="answer === false" class="badge">미흡</div>
          <!-- 행정 처분에서 미흡항목이 있는 경우 무조건 D등급이기때문에
           미흡 뱃지 노출과 문항 컬러 변경으로 알려주기 -->
          {{ `${index + 1}. ${data.question}` }}
        </div>
        <div class="flex_row" style="padding-top: 2px; padding-left: 24px">
          <div class="inspectionScore">
            <span :class="{ strong: answer === false, black: answer === true }"
              >{{ answer ? data.score : "0" }}점</span
            >/{{ data.score }}점
          </div>
          <img
            src="@/assets/arrow5.svg"
            alt="more"
            style="width: 16px"
            @click="isDetail = !isDetail"
            :class="{ rotateImage: isDetail }"
          />
        </div>
      </div>
      <div v-if="isDetail">
        <div
          class="flex_row"
          v-if="
            $route.name == 'qsc-inspection-detail' ||
            $route.name == 'qsc-inspection-feedback'
          "
        >
          <div class="flex_row" style="align-items: center; margin-top: 11px">
            <img
              v-if="answer === true"
              src="@/assets/qsc-true-dis.svg"
              alt="more"
              style="width: 20px; margin-right: 2px"
            />
            <img
              v-else
              src="@/assets/qsc-false-dis.svg"
              alt="more"
              style="width: 20px; margin-right: 2px"
            />
            <div class="inspectionAnswer resultDisable">준수</div>
          </div>
          <div class="flex_row" style="align-items: center; margin-top: 11px">
            <img
              v-if="answer === false"
              src="@/assets/qsc-true-dis.svg"
              alt="more"
              style="width: 20px; margin-right: 2px"
            />
            <img
              v-else
              src="@/assets/qsc-false-dis.svg"
              alt="more"
              style="width: 20px; margin-right: 2px"
            />
            <div class="inspectionAnswer resultDisable">미흡</div>
          </div>
        </div>
        <div class="flex_row" v-else>
          <div
            class="flex_row"
            style="align-items: center; margin-top: 11px"
            @click="answer = true"
          >
            <img
              v-if="answer === true"
              src="@/assets/qsc-true.svg"
              alt="more"
              style="width: 20px; margin-right: 2px"
            />
            <img
              v-else
              src="@/assets/qsc-false.svg"
              alt="more"
              style="width: 20px; margin-right: 2px"
            />
            <div class="inspectionAnswer">준수</div>
          </div>
          <div
            class="flex_row"
            style="align-items: center; margin-top: 11px"
            @click="answer = false"
          >
            <img
              v-if="answer === false"
              src="@/assets/qsc-true.svg"
              alt="more"
              style="width: 20px; margin-right: 2px"
            />
            <img
              v-else
              src="@/assets/qsc-false.svg"
              alt="more"
              style="width: 20px; margin-right: 2px"
            />
            <div class="inspectionAnswer">미흡</div>
          </div>
        </div>
        <div
          v-if="data.isDescription === 1"
          class="inspectionInfo"
          @click="infoView = !infoView"
          style="margin-top: 16px"
        >
          <div class="flex_row flex_between">
            <span class="info">참고사항</span>
            <img
              :class="{ rotateImage: infoView }"
              src="@/assets/arrow6.svg"
              alt="more"
              style="width: 16px"
            />
          </div>
          <div v-if="infoView" style="margin-top: 16px; white-space: pre">
            {{ data.description }}
          </div>
        </div>
        <InspectionFeedback
          :currentData="data"
          :imageArray="imageArray"
          :imageArrayFF="imageArrayFF"
          :index="data.question"
          @feedbackImgChange="getFeedbackImage"
          @feedbackImgChangeFF="getFeedbackImageFF"
          @feedbackContent="getFeedbackContent"
          @feedbackOfFeedbackContent="getFeedbackOfFeedbackContent"
        ></InspectionFeedback>
      </div>
    </div>
  </div>
</template>

<script>
import InspectionFeedback from "@/components/inspection/InspectionFeedback.vue";
export default {
  components: {
    InspectionFeedback,
  },
  props: {
    index: { type: Number, require: true },
    data: { type: Object, require: true },
  },
  data() {
    return {
      managerId: null,
      question: this.data,
      answer: null,
      isDetail: false,
      infoView: false,
      feedback: "",
      feedbackOfFeedback: "",
      feedbackDate: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1 < 10
          ? "0" + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1
      }-${
        new Date().getDate() < 10
          ? "0" + new Date().getDate()
          : new Date().getDate()
      }`,
      imageArray: [],
      imageArrayFF: [],
      qsc: null,
    };
  },
  methods: {
    getFeedbackImage(value) {
      this.imageArray = value;
      // console.log(this.imageArray)
    },
    getFeedbackImageFF(value) {
      this.imageArrayFF = value;
      // console.log(this.imageArrayFF)
    },
    getFeedbackContent(value) {
      this.feedback = value;
      // console.log(this.feedback)
    },
    getFeedbackOfFeedbackContent(value) {
      this.feedbackOfFeedback = value;
      // console.log(this.feedbackOfFeedback)
    },
  },
  watch: {
    feedback: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        this.qsc = { ...this.question, ...this.qsc, feedback: this.feedback };
        // console.log(this.qsc)
        this.$emit("getAnswer", this.index, this.qsc);
      },
    },
    feedbackOfFeedback: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        this.qsc = {
          ...this.question,
          ...this.qsc,
          feedbackOfFeedback: this.feedbackOfFeedback,
          feedbackOfFeedbackAuthorManagerId: this.managerId,
          feedbackOfFeedbackWriteDate: this.feedbackDate,
        };
        // console.log(this.qsc)
        this.$emit("getAnswer", this.index, this.qsc);
      },
    },
    imageArray: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        this.qsc = {
          ...this.question,
          ...this.qsc,
          feedbackImgList: this.imageArray,
        };
        this.$emit("getAnswer", this.index, this.qsc);
      },
    },
    imageArrayFF: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        this.qsc = {
          ...this.question,
          ...this.qsc,
          feedbackOfFeedbackImgList: this.imageArrayFF,
        };
        this.$emit("getAnswer", this.index, this.qsc);
      },
    },
    answer: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        this.qsc = {
          ...this.question,
          ...this.qsc,
          answerSelect: this.answer ? "준수" : "미흡",
          feedbackAuthorManagerId: this.managerId,
        };
        // console.log(this.qsc)
        this.$emit("getAnswer", this.index, this.qsc);
      },
    },
    $route: {
      handler: function () {
        this.isDetail = false;
      },
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (
      this.$route.name == "qsc-inspection-detail" ||
      this.$route.name == "qsc-inspection-feedback" ||
      this.$route.name == "qsc-inspection-edit" ||
      this.$route.name == "qsc-temporary-edit"
    ) {
      if (this.data.answerSelect === "미흡") {
        this.answer = false;
      } else {
        this.answer = true;
      }
    }
    if (this.$route.name == "qsc-inspection") {
      this.isDetail = true;
    }
  },
  created() {
    this.managerId = localStorage.getItem("managerId");
    // console.log(this.managerId)
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
@import "@/assets/scss/qsc.scss";
.badge {
  width: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $accent1;
  border-radius: 4px;
  padding: 2px 6px;
  color: $white100;
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-right: 4px;
}
.info {
  color: $black100;
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.black {
  color: $black100;
}
.resultDisable {
  color: $black32;
}
</style>
