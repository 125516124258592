<template>
  <div class="scroll flex_col" id="envTop">
    <div class="flex_col" style="padding: 20px 16px">
      <label class="passwordLabelStyle" style="margin-bottom: 14px">
        <h5 class="passwordInputFont">제목</h5>
        <div class="flex_row flex_between" style="position: relative">
          <input
            class="passwordInputStyle"
            type="text"
            placeholder="일정 제목"
            :value="title"
            @input="changeInput('title', $event.target.value)"
            style="width: 100%"
          />
        </div>
      </label>
      <label class="passwordLabelStyle">
        <h5 class="passwordInputFont">내용</h5>
        <textarea
          class="passwordInputStyle"
          placeholder="일정 내용"
          :value="content"
          ref="textareaRef"
          @input="changeInput('content', $event.target.value)"
          style="width: 100%; min-height: 20px; resize: none; max-height: 100%"
        ></textarea>
      </label>
      <div class="fontStyle3" :class="{ errorColor: content.length > 300 }">
        <span
          style="color: rgba(0, 0, 0, 1)"
          :class="{ errorColor: content.length > 300 }"
          >{{ content.length }}</span
        >/300자
      </div>
      <div class="passwordLabelStyle">
        <div class="flex_row flex_between" style="padding-bottom: 14px">
          <div class="fontStyle4">하루 종일</div>
          <div
            class="swicthBG"
            :class="{ swicthTrue: isAllDay }"
            @click="swicthChangeState"
          >
            <div
              class="swicthValue"
              :class="{ swicthValueTrue: isAllDay }"
            ></div>
          </div>
        </div>
        <div
          class="flex_row flex_between"
          style="border-top: 1px solid rgba(0, 0, 0, 0.28); padding-top: 16px"
        >
          <div
            class="flex_col"
            style="width: 50%; height: 45px"
            @click="dateOn"
          >
            <h5 class="passwordInputFont">날짜</h5>
            <div class="flex_row" style="position: relative">
              <div style="width: 120px">{{ workAt }}</div>
              <img
                src="@/assets/calendar-icon.svg"
                alt="date"
                style="width: 20px"
              />
            </div>
          </div>
          <CalenderLib
            v-if="isDateView"
            :isDateView="isDateView"
            :workAt="workAt"
            @selectedDate="getSelectedDate"
            @changeDate="getCalendarDisplay"
          ></CalenderLib>
          <div
            class="flex_col"
            style="width: 50%; margin-left: 20px; height: 45px"
            @click="isTimeView = true"
          >
            <h5 class="passwordInputFont">시간</h5>
            <div
              class="flex_row"
              style="position: relative"
              v-if="workTime.length !== 0"
            >
              <div style="width: 120px">
                {{ viewTime }}
                {{
                  Number(workTime.slice(0, 2)) === 24
                    ? "PM"
                    : Number(workTime.slice(0, 2)) > 12
                    ? "PM"
                    : "AM"
                }}
              </div>
              <img
                src="@/assets/time-icon.svg"
                alt="time"
                style="width: 20px"
              />
            </div>
          </div>
          <TimeSpinner
            v-if="isTimeView"
            :isTimeView="isTimeView"
            :workTime="workTime"
            @changeTime="getTimeData"
          ></TimeSpinner>
        </div>
      </div>
    </div>
    <button
      class="headerTextBtn saveBtn"
      :disabled="vailCheck"
      @click="saveSchedule"
      :style="`top:${envTopSize}px;margin-right: 0`"
    >
      저장
    </button>
    <div v-if="isShowDelBtn" class="bottomOptBtn" @click="isShowAlert = true">
      삭제하기
    </div>
    <CommonAlertTwoBtn
      :state="isShowAlert"
      @changeDisplay="getAlertDisplayState"
      @confirmAction1="confirmAction1"
      @confirmAction2="confirmAction2"
    >
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #content2>
        {{ alertContent2 }}
      </template>
      <template #btn1>
        {{ alertBtn1 }}
      </template>
      <template #btn2>
        {{ alertBtn2 }}
      </template>
    </CommonAlertTwoBtn>
    <ToastMsg :message="toastMassage" :display="toastDisplay"></ToastMsg>
  </div>
</template>

<script>
import {
  getScheduleDetail,
  postSchedule,
  putSchedule,
  delSchedule,
} from "@/api/SchedulAPI";
import TimeSpinner from "@/components/lib/TimeSpinner.vue";
import CalenderLib from "@/components/lib/CalenderLib.vue";
import CommonAlertTwoBtn from "@/components/global/alert/CommonAlertTwoBtn.vue";
import ToastMsg from "@/components/global/alert/ToastMsg.vue";

export default {
  name: "my-schedule-detail",
  components: {
    TimeSpinner,
    CalenderLib,
    CommonAlertTwoBtn,
    ToastMsg,
  },
  props: {},
  data() {
    return {
      envTopSize: 0,
      id: null,
      isShowDelBtn: false,
      title: "",
      content: "",
      workAt: "",
      workTime: "",
      viewTime: "",
      isAllDay: true,
      isDateView: false,
      isTimeView: false,
      isShowAlert: false,
      alertTitle: "일정 삭제",
      alertContent1: "한번 삭제된 일정은 복구하실 수 없습니다.",
      alertContent2: "정말로 삭제하시겠습니까?",
      alertBtn1: "취소",
      alertBtn2: "삭제",
      toastDisplay: false,
      toastMassage: "",
    };
  },
  methods: {
    textareaControll() {
      if (this.$refs.textareaRef.scrollHeight < 230) {
        this.$refs.textareaRef.style.height = `${this.$refs.textareaRef.scrollHeight}px`;
      }
    },
    dateOn() {
      this.isDateView = true;
    },
    getAlertDisplayState(value) {
      this.isShowAlert = value;
    },
    confirmAction1() {
      this.isShowAlert = false;
      this.optionState = null;
    },
    confirmAction2() {
      delSchedule(this.id)
        .then((res) => {
          if (res.data.isSuccessful) {
            this.toastDisplay = true;
            this.toastMassage = "삭제되었습니다";
            setTimeout(() => {
              this.toastDisplay = false;
              this.toastMassage = "";
              this.$router.replace({ name: "my-schedule" });
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.optionState = null;
        });
    },
    swicthChangeState() {
      this.isAllDay = !this.isAllDay;
      if (this.isAllDay) {
        this.workTime = "";
      } else {
        this.workTime = "09:00";
      }
    },
    getSelectedDate(value) {
      this.workAt = value;
    },
    getCalendarDisplay() {
      this.isDateView = !this.isDateView;
    },
    getTimeData(isView, value) {
      this.isTimeView = isView;
      this.workTime = value.slice(0, 5);
      this.isAllDay = false;
    },
    changeInput(type, data) {
      if (type === "title") {
        this.title = data;
      } else if (type === "content") {
        this.content = data;
        this.textareaControll();
      }
    },
    getSchedule() {
      // detail 불러오는 함수.
      // 수정 페이지에서 사용됨.
      getScheduleDetail(this.id)
        .then((res) => {
          if (res.data.isSuccessful) {
            // console.log(res.data.result);
            let curData = res.data.result;
            this.title = curData.title;
            this.content = curData.content;
            this.workAt = curData.workAt;
            this.workTime = curData.workTime;
            this.isAllDay = curData.workTime === "" ? true : false;
          } else {
            // console.log(res.data.isSuccessful);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveSchedule() {
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("content", this.content);
      formData.append("workAt", this.workAt);
      formData.append(
        "workTime",
        `${this.workTime ? this.workTime + ":00" : this.workTime}`
      );
      const scheduleData = formData;
      // for (let key of formData.keys()) {
      //   console.log(key, ":", formData.get(key));
      // }
      if (this.$route.name === "my-schedule-add") {
        // 등록
        postSchedule(scheduleData)
          .then((res) => {
            if (res.data.isSuccessful) {
              this.toastDisplay = true;
              this.toastMassage = "새로운 일정이 등록되었습니다";
              setTimeout(() => {
                this.toastDisplay = false;
                this.toastMassage = "";
                this.$router.replace({ name: "my-schedule" });
              }, 1000);
            } else {
              console.log("에러");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // 수정
        putSchedule(this.id, scheduleData)
          .then((res) => {
            if (res.data.isSuccessful) {
              this.toastDisplay = true;
              this.toastMassage = "수정되었습니다";
              setTimeout(() => {
                this.toastDisplay = false;
                this.toastMassage = "";
                this.$router.replace({ name: "my-schedule" });
              }, 1000);
            } else {
              console.log("에러");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  computed: {
    vailCheck() {
      if (
        this.title.length === 0 ||
        this.content.length === 0 ||
        this.workAt.length === 0
      ) {
        return true;
      } else {
        if (this.isAllDay) {
          return false;
        } else {
          if (this.workTime.length === 0) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
  },
  watch: {
    workTime: {
      handler: function () {
        if (Number(this.workTime.slice(0, 2)) > 12) {
          this.viewTime = `${
            Number(this.workTime.slice(0, 2) - 12) < 10
              ? "0" + Number(this.workTime.slice(0, 2) - 12)
              : Number(this.workTime.slice(0, 2) - 12)
          }:${this.workTime.slice(3, 5)}`;
        } else if (this.workTime.slice(0, 2) === 24) {
          this.viewTime = `12:${this.workTime.slice(3, 5)}`;
        } else {
          this.viewTime = `${
            Number(this.workTime.slice(0, 2)) < 10
              ? "0" + Number(this.workTime.slice(0, 2))
              : Number(this.workTime.slice(0, 2))
          }:${this.workTime.slice(3, 5)}`;
        }
      },
    },
  },

  mounted() {
    this.envTopSize =
      Number(
        getComputedStyle(document.getElementById("envTop"))
          .getPropertyValue("--safe-area-inset-top")
          .replace(/px/g, "")
      ) + 17;
  },
  created() {
    // 수정하기 페이지인 경우
    if (this.$route.name === "my-schedule-edit") {
      this.id = this.$route.params.id;
      this.isShowDelBtn = true;
      this.getSchedule();
    } else {
      if (this.workAt.length === 0) {
        const today = new Date();
        const date = `${today.getFullYear()}-${
          today.getMonth() + 1 > 9
            ? today.getMonth() + 1
            : "0" + (today.getMonth() + 1)
        }-${today.getDate() > 9 ? today.getDate() : "0" + today.getDate()}`;
        console.log(date);
        this.workAt = date;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/header.scss";
@import "@/assets/scss/component.scss";
.padding {
  padding: 4px 24px;
}
.line {
  height: 1px;
  width: 100%;
  border: none;
  background-color: $black8;
  margin: 20px 0;
}
.fontStyle1 {
  color: $black50;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.fontStyle2 {
  color: $black100;
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.fontStyle3 {
  color: $black50;
  font-size: $fs12;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  text-align: right;
  margin-top: 8px;
  margin-bottom: 12px;
}
.fontStyle4 {
  color: $black100;
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}

.saveBtn {
  font-family: "Pretendard";
  border: none;
  background-color: white;
  text-align: right;
  position: absolute;
  top: 0px;
  right: 16px;
  &:disabled {
    color: $gray2;
  }
}
.errorColor {
  color: $error !important;
}

#envTop {
  --safe-area-inset-top: env(safe-area-inset-top);
}
</style>
