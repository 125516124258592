<template>
  <div v-if="data" class="flex_col" style="width: 100%; gap: 12px">
    <div
      v-for="value in data"
      :key="value.id"
      class="flex_row listCard"
      @click="
        $router.push({
          path: `/qsc/detail/${value.id}`,
          params: {
            id: value.id,
          },
        })
      "
    >
      <div class="flex_col">
        <div class="dayFont">{{ value.reportType }}</div>
        <h3 class="titleStyle">{{ value.storeName }}</h3>
        <div class="reportFontStyle" style="margin-bottom: 4px">
          {{ value.templateTitle }}
        </div>
        <div class="reportFontStyle">{{ value.managerName }}</div>
        <ScoreList :data="value"></ScoreList>
        <div class="flex_row flex_between">
          <div class="flex_row" style="gap: 6px">
            <div class="tagStyle">{{ value.line }}</div>
            <div class="tagStyle">{{ value.team }}</div>
          </div>
        </div>
      </div>
      <ScoreTag :score="value.totalScore" :grade="value.rank"></ScoreTag>
    </div>
  </div>
</template>

<script>
import ScoreTag from '@/components/inspection/ScoreTag.vue';
import ScoreList from '@/components/inspection/ScoreList.vue';
export default {
  props: {
    data: {
      type: Array,
      require: true,
    },
  },
  components: { ScoreList, ScoreTag },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.listCard {
  padding: 20px;
  background-color: $white100;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  justify-content: space-between;
  align-items: flex-start;
}
.dayFont {
  color: $black50;
  font-weight: 400;
  font-size: $fs12;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-bottom: 12px;
}
.titleStyle {
  color: $black100;
  font-size: $fs18;
  font-weight: 600;
  line-height: 21.6px;
  letter-spacing: -2%;
  margin: 0;
  margin-bottom: 4px;
}
.reportFontStyle {
  color: $black100;
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-bottom: 12px;
}
.tagStyle {
  width: fit-content;
  padding: 6px 10px;
  color: $black32;
  font-size: $fs14;
  font-weight: 500;
  letter-spacing: -1.3%;
  border-radius: 100px;
  border: 1px solid $black8;
}
</style>
