<template>
  <div v-if="userData" class="menuBG" @click.self="changeDisplay">
    <div class="baseMenuWrap">
      <div class="sec1" style="padding-bottom: 0; border-bottom: none; text-align: right">
        <img src="@/assets/close2.svg" alt="close" @click="changeDisplay" />
      </div>
      <div class="sec1" style="padding-top: 0">
        <div class="flex_row" style="justify-content: space-between; align-items: start">
          <div class="greetingFont">
            <span style="color: rgba(115, 0, 1, 1)"
              >{{ userData.name }} {{ userData.managerType }}</span
            >
            님<br />반갑습니다.
          </div>
        </div>
        <div class="teamFont" v-if="userData.managerType === '팀장'">
          {{ userData.team }}
        </div>
        <div class="baseBtn line btn" @click="routerControll('password-change')">비밀번호 변경</div>
      </div>
      <div class="scroll">
        <div class="sec2" style="border-bottom: 1px solid rgba(0, 0, 0, 0.06)">
          <ul v-if="isMaster" style="display: flex; flex-direction: column; gap: 12px">
            <li
              v-for="(list, index) in menuList.master"
              :key="index"
              class="menuList"
              @click="routerControll(`${list.router}`)"
            >
              <img :src="list.icon" alt="" style="width: 20px; height: 20px" />
              <div class="listFont">{{ list.title }}</div>
            </li>
          </ul>
          <ul v-else style="display: flex; flex-direction: column; gap: 12px">
            <li
              v-for="(list, index) in menuList.manager"
              :key="index"
              @click="routerControll(`${list.router}`)"
            >
              <div
                class="menuList"
                :class="{ listBG: list.title === '교육' && isEdu }"
                style="padding-right: 16px"
              >
                <img :src="list.icon" alt="" style="width: 20px; height: 20px" />
                <div class="listFont">{{ list.title }}</div>
                <img
                  v-if="list.title === '교육'"
                  src="@/assets/arrow9.svg"
                  alt="more"
                  style="width: 16px; height: 16px; margin-left: auto"
                  :class="{ imgRotate: list.title === '교육' && isEdu }"
                />
              </div>
              <div v-if="list.title === '교육' && isEdu" style="padding-top: 12px">
                <li class="inList" @click="routerControll('learning-list')">본러닝</li>
                <li
                  class="inList"
                  @click="routerControll('certificate-list')"
                  style="padding-bottom: 6px"
                >
                  수료증
                </li>
              </div>
            </li>
          </ul>
        </div>
        <div class="sec2">
          <ul style="display: flex; flex-direction: column; gap: 12px">
            <li
              v-for="(list, index) in menuList.common"
              :key="index"
              class="menuList"
              @click="routerControll(`${list.router}`)"
            >
              <img :src="list.icon" alt="" style="width: 20px; height: 20px" />
              <div class="listFont">{{ list.title }}</div>
            </li>
          </ul>
        </div>
        <div class="sec3">
          <div class="menuList" @click="logout">
            <img src="@/assets/logout.svg" alt="" style="width: 20px; height: 20px" />
            <div class="listFont">로그아웃</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from '@/api/LoginAPI';
import { Capacitor } from '@capacitor/core';

export default {
  props: {
    menuState: Boolean,
  },
  data() {
    return {
      userData: null,
      menuDisplay: this.menuState,
      isMaster: false, //true: 팀장 & false: 점장
      isEdu: false,
      menuList: {
        master: [
          {
            icon: '/assets/dashboard.svg',
            title: '홈',
            router: 'main-page',
          },
          {
            icon: '/assets/store.svg',
            title: '지점',
            router: 'store-list',
          },
          {
            icon: '/assets/report.svg',
            title: '점검',
            router: 'inspection-list',
          },
          {
            icon: '/assets/paper.svg',
            title: '문서',
            router: 'document-list',
          },
        ],
        manager: [
          {
            icon: '/assets/dashboard.svg',
            title: '홈',
            router: 'main-page',
          },
          {
            icon: '/assets/store.svg',
            title: '내 지점',
            router: 'my-store-list',
          },
          {
            icon: '/assets/report.svg',
            title: '점검',
            router: 'inspection-list',
          },
          {
            icon: '/assets/edu.svg',
            title: '교육',
            router: '',
          },
          {
            icon: '/assets/paper.svg',
            title: '문서',
            router: 'document-list',
          },
        ],
        common: [
          {
            icon: '/assets/announce.svg',
            title: '공지사항',
            router: 'notice-list',
          },
          {
            icon: '/assets/calendar.svg',
            title: '나의 일정',
            router: 'my-schedule',
          },
        ],
      },
    };
  },
  methods: {
    routerControll(location) {
      if (location === '') {
        this.isEdu = !this.isEdu;
      } else if (location === 'inspection-list') {
        this.$router.push({ path: '/inspection/dashboard' });
        this.changeDisplay();
      } else {
        this.$router.push({ name: `${location}` });
        this.changeDisplay();
      }
    },
    logout() {
      // Capacitor Android/iOS app packaging의 경우
      const fcmToken = localStorage.getItem('fcmToken');

      if (Capacitor.isNativePlatform()) {
        console.log('LOGOUT', fcmToken);
        const formData = new FormData();
        formData.append('account', localStorage.getItem('account'));
        formData.append('pwd', localStorage.getItem('pwd'));
        formData.append('fcmToken', fcmToken);

        logout(formData)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.error(err);
          });
      }

      localStorage.clear();
      if (fcmToken) {
        localStorage.setItem('fcmToken', fcmToken);
      }

      this.$router.push({ name: `login-page` });
    },
    changeDisplay() {
      this.menuDisplay = false;
      this.$emit('changeDisplay', this.menuDisplay);
    },
  },
  created() {
    this.userData = {
      name: localStorage.getItem('name'),
      managerType: localStorage.getItem('managerType'),
      team: localStorage.getItem('team'),
    };
    // console.log(this.userData)
    if (localStorage.getItem('managerType') === '팀장') {
      this.isMaster = true;
    } else {
      this.isMaster = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.menuBG {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
}
.baseMenuWrap {
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  background-color: $white100;
  width: 312px;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  .sec1 {
    padding: 24px 24px 20px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .greetingFont {
      font-size: $fs20;
      font-weight: 600;
      line-height: 26.4px;
      letter-spacing: -1.3%;
      padding-bottom: 12px;
    }
    .teamFont {
      color: rgba(0, 0, 0, 0.5);
      font-size: $fs14;
      font-weight: 400;
      line-height: 16.8px;
      letter-spacing: -1.3%;
      margin-bottom: 18px;
    }
    .btn {
      font-size: $fs14;
      font-weight: 500;
      line-height: 16.8px;
      letter-spacing: -1.3%;
      padding: 6px 10px;
      width: fit-content;
      height: 29px;
    }
  }
  .sec2 {
    width: 100%;
    padding: 14px 16px 14px 0;
    margin-left: 16px;
    .inList {
      font-size: $fs14;
      font-weight: 500;
      line-height: 16.8px;
      letter-spacing: -1.3%;
      color: $black50;
      padding: 6px 40px;
    }
  }
  .menuList {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 12px 8px;
    width: 280px;
  }
  .listFont {
    display: flex;
    font-size: $fs16;
    font-weight: 500;
    line-height: 19.2px;
    letter-spacing: -1.3%;
    margin-left: 8px;
  }
  .listBG {
    padding: 12px 8px;
    background-color: $black4;
    border-radius: 6px;
  }
  .imgRotate {
    transform: rotate(180deg);
  }
  .sec3 {
    padding: 35px 25px 35px 16px;
    margin-top: auto;
  }
  .scroll {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    /* 가로스크롤이슈 */
    overflow-x: hidden;
  }
}
</style>
