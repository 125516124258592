import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectDate: `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 < 10
        ? '0' + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1
    }`,
    storeFilter: '최근 방문순',
    storeSelectFilter: [],
    docFilter: '최신순',
    docCategory: '전체',
    inspectionFilter: null,
  },
  mutations: {
    UPDATE_STORE_FILTER(state, payload) {
      return (state.storeFilter = payload);
    },
    RESET_STORE_FILTER(state) {
      return (state.storeFilter = '최근 방문순');
    },
    UPDATE_STORE_SERECT_FILTER(state, payload) {
      return (state.storeSelectFilter = payload);
    },
    RESET_STORE_SERECT_FILTER(state) {
      return (state.storeSelectFilter = []);
    },
    UPDATE_DOC_FILTER(state, payload) {
      return (state.docFilter = payload);
    },
    RESET_DOC_FILTER(state) {
      return (state.docFilter = '최신순');
    },
    UPDATE_DOC_CATEGORY(state, payload) {
      return (state.docCategory = payload);
    },
    RESET_DOC_CATEGORY(state) {
      return (state.docCategory = '전체');
    },
    UPDATE_SELECT_DATE(state, payload) {
      return (state.selectDate = payload);
    },
    RESET_SELECT_DATE(state) {
      return (state.selectDate = `${new Date().getFullYear()}-${
        new Date().getMonth() + 1 < 10
          ? '0' + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1
      }`);
    },
    SET_INSPECTION_FILTER(state, payload) {
      return (state.inspectionFilter = payload);
    },
  },
  actions: {
    updateStoreFilter({ commit }, value) {
      commit('UPDATE_STORE_FILTER', value);
    },
    resetStoreFilter({ commit }) {
      commit('RESET_STORE_FILTER');
    },
    updateStoreSelectFilter({ commit }, value) {
      commit('UPDATE_STORE_SERECT_FILTER', value);
    },
    resetStoreSelectFilter({ commit }) {
      commit('RESET_STORE_SERECT_FILTER');
    },
    updateDocFilter({ commit }, value) {
      commit('UPDATE_DOC_FILTER', value);
    },
    resetDocFilter({ commit }) {
      commit('RESET_DOC_FILTER');
    },
    updateDocCategory({ commit }, value) {
      commit('UPDATE_DOC_CATEGORY', value);
    },
    resetDocCategory({ commit }) {
      commit('RESET_DOC_CATEGORY');
    },
    updateSelectDate({ commit }, value) {
      commit('UPDATE_SELECT_DATE', value);
    },
    resetSelectDate({ commit }) {
      commit('RESET_SELECT_DATE');
    },
    setInspectionFilter({ commit }, value) {
      commit('SET_INSPECTION_FILTER', value);
    },
  },
  getters: {
    getStoreFilterData(state) {
      return state.storeFilter;
    },
    getStoreSelectFilterData(state) {
      return state.storeSelectFilter;
    },
    getDocFilterData(state) {
      return state.docFilter;
    },
    getDocCategoryData(state) {
      return state.docCategory;
    },
    getSelectDate(state) {
      return state.selectDate;
    },
    getInspectionFilter(state) {
      return state.inspectionFilter;
    },
  },
  modules: {},
});
