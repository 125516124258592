<template>
  <div class="listScore">
    {{ data.totalScore }}점
    <span class="gray">|</span>
    미흡 {{ data.insufficientCnt }}건
    <span class="gray">|</span>
    피드백 {{ data.feedbackCnt }}건
    <span class="gray">|</span>
    <span class="burgundy"> 처리 {{ data.feedbackOfFeedbackCnt }}건 </span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      qscData: this.data,
    };
  },
  watch: {
    data: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        this.qscData = this.data;
      },
    },
  },
  mounted() {
    // console.log("updated", this.data);
    // console.log("updated", this.qscData);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
.listScore {
  color: $black100;
  font-size: $fs12;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-top: 12px;
}
.gray {
  font-size: $fs10;
  color: $black8;
}
.burgundy {
  color: $burgundy;
}
</style>
