<template>
  <div v-if="data" class="flex_col">
    <div class="flex_col" style="padding: 24px 16px 0">
      <div class="storeDetailCard">
        <div class="storeDetailCardFont">
          최근 산업안전교육 수강 후
          <span class="strong">{{ data.betweenDate }}일</span>이 지났습니다.
        </div>
      </div>
    </div>
    <div v-if="data.list" class="flex_col" style="padding: 16px">
      <div
        v-for="(edu, index) in data.list"
        :key="index"
        class="flex_col"
        style="width: 100%; padding-top: 16px"
      >
        <div
          class="flex_row flex_between"
          style="width: 100%; align-items: flex-start"
        >
          <div class="flex_col">
            <h3 class="listTitle">{{ edu.title }}</h3>
            <div class="storeListStyle" style="line-height: 18px">
              교육자: {{ edu.teacher }}<br />
              교육장소: {{ edu.location }}<br />
              참석자: {{ edu.student }}
            </div>
          </div>
          <div class="storeListDate">{{ edu.completeAt }}</div>
        </div>
        <div v-if="data.list.length - 1 !== index" class="underBorder"></div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="flex_col"
    style="width: 100%; height: 100%; align-items: center"
  >
    <div class="flex_col" style="margin-top: 143px; align-items: center">
      <img src="@/assets/error-not.svg" alt="" style="width: 32px" />
      <div class="errorInfoFont1" style="color: rgba(0, 0, 0, 0.32)">
        등록된 교육내역이 없습니다.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, require: true },
  },
  created() {
    console.log('안전교육', this.data);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.fontStyle1 {
  color: $black50;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.red {
  color: $error;
}
.underBorder {
  width: 100%;
  border-bottom: 1px solid $black4;
  padding-top: 20px;
  margin-bottom: 4px;
}
</style>
