import API from "@/api/BaseUrl";

// 일정 목록 조회
export async function getScheduleList(rqDate) {
    return API.get(`calendar?workAt=${rqDate}`)
}
// 일정 상세 조회
export async function getScheduleDetail(id) {
    return API.get(`calendar/${id}`)
}
// 일정 등록
export async function postSchedule(scheduleData) {
    return API.post(`calendar`,scheduleData)
}
// 일정 수정
export async function putSchedule(id,scheduleData) {
    return API.put(`calendar/${id}`,scheduleData)
}
// 일정 삭제
export async function delSchedule(id) {
    return API.delete(`calendar/${id}`)
}