<template>
  <div class="passwordWrap">
    <h2 class="passwordFont" style="margin-bottom: 13px">비밀번호 찾기</h2>
    <label
      class="passwordLabelStyle"
      :class="{
        focusLabelStyle: accountFocus,
        errorLabelStyle: accountError,
        disabledLabelStyle: accountDisabled,
      }"
      style="max-height: 77px"
    >
      <h5 class="passwordInputFont">아이디(사번)</h5>
      <div class="flex_row" style="position: relative; gap: 12px">
        <input
          class="passwordInputStyle"
          type="text"
          :readonly="accountDisabled"
          placeholder="아이디(사번)입력"
          :value="account"
          name="account"
          @input="valiCheck('account', $event.target.value)"
          @focus="labelColorChange($event, 'focus')"
          @blur="labelColorChange($event, 'blur')"
          style="width: 100%"
        />
        <img
          v-if="accountFocus && account.length > 0 && !accountDisabled"
          src="@/assets/close1.svg"
          alt="delete"
          style="width: 18px"
          @click="focusControll('account')"
        />
      </div>
    </label>
    <label
      class="passwordLabelStyle"
      :class="{
        focusLabelStyle: emailFocus,
        errorLabelStyle: emailError,
        disabledLabelStyle: emailDisabled,
      }"
      style="max-height: 86px; min-height: 86px"
    >
      <h5 class="passwordInputFont">이메일</h5>
      <div
        class="flex_row"
        style="position: relative; gap: 12px; align-items: center"
      >
        <input
          class="passwordInputStyle"
          type="text"
          :readonly="emailDisabled"
          placeholder="예) ex@bongroup.co.kr"
          :value="email"
          name="email"
          @input="valiCheck('email', $event.target.value)"
          @focus="labelColorChange($event, 'focus')"
          @blur="labelColorChange($event, 'blur')"
          style="width: 100%"
        />
        <img
          v-if="emailFocus && !emailDisabled"
          :class="{ opa0: email.length < 1, opa1: email.length > 0 }"
          src="@/assets/close1.svg"
          alt="delete"
          style="width: 18px"
          @click="focusControll('email')"
        />
        <button
          class="baseBtn linesmall sendBtn"
          :disabled="!email.length > 0 || !account.length > 0"
          @click="sendcertificateCode"
        >
          {{ sendBtn }}
        </button>
      </div>
    </label>
    <label
      class="passwordLabelStyle"
      :class="{
        focusLabelStyle: certificateCodeFocus,
        errorLabelStyle: certificateCodeError,
      }"
      style="max-height: 86px; min-height: 86px"
    >
      <h5 class="passwordInputFont">인증번호</h5>
      <div
        class="flex_row"
        style="position: relative; gap: 12px; align-items: center"
      >
        <input
          class="passwordInputStyle"
          type="text"
          placeholder="6자리 입력"
          :value="certificateCode"
          name="certificateCode"
          @input="valiCheck('certificateCode', $event.target.value)"
          @focus="labelColorChange($event, 'focus')"
          @blur="labelColorChange($event, 'blur')"
          style="width: 100%"
        />
        <img
          v-if="certificateCodeFocus && certificateCode.length > 0"
          src="@/assets/close1.svg"
          alt="cloas"
          style="width: 18px"
          @click="focusControll('certificateCode')"
        />
        <div class="flex_row" style="align-items: center; gap: 10px">
          <div class="count">
            {{ minute }}:{{ second > 9 ? second : `0` + second }}
          </div>
          <button
            class="baseBtn linesmall sendBtn"
            :disabled="!isSent"
            @click="checkCertificateCode"
          >
            인증확인
          </button>
        </div>
      </div>
    </label>
    <div class="info">
      <!-- todo click시 해당 공지사항으로 이동 -->
      인증메일이 오지 않으셨나요?
    </div>
    <CommonAlert2
      :state="isShowAlert"
      @changeDisplay="getAlertDisplayState"
      @confirmAction="confirmAction"
    >
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #content2 v-if="alertContent2.length !== 0">
        {{ alertContent2 }}
      </template>
      <template #btn>
        {{ alertBtn }}
      </template>
    </CommonAlert2>
    <ToastMsg :message="toastMassage" :display="toastDisplay"></ToastMsg>
  </div>
</template>

<script>
import { sendEmail, certificateCodeCheck } from '@/api/LoginAPI';
import CommonAlert2 from '@/components/global/alert/CommonAlert2.vue';
import ToastMsg from '@/components/global/alert/ToastMsg.vue';

export default {
  name: 'password-find',
  components: {
    CommonAlert2,
    ToastMsg,
  },
  props: {},
  data() {
    return {
      account: '',
      accountFocus: false,
      accountError: false,
      accountDisabled: false,
      email: '',
      emailFocus: false,
      emailError: false,
      emailDisabled: false,
      token: '',
      certificateCode: '',
      certificateCodeFocus: false,
      certificateCodeError: false,
      isSent: false,
      sendBtn: '인증번호 발송',
      countDown: false,
      minute: 3,
      second: 0,
      isShowAlert: false,
      alertTitle: '',
      alertContent1: '',
      alertContent2: '',
      alertBtn: '',
      toastDisplay: false,
      toastMassage: '',
    };
  },
  methods: {
    focusControll(type) {
      if (type === 'account') {
        this.account = '';
        setTimeout(() => {
          this.accountFocus = true;
        }, 100);
      } else if (type === 'email') {
        this.email = '';
        setTimeout(() => {
          this.emailFocus = true;
        }, 100);
      } else {
        this.certificateCode = '';
        setTimeout(() => {
          this.certificateCodeFocus = true;
        }, 100);
      }
    },
    getAlertDisplayState(value) {
      this.isShowAlert = value;
    },
    confirmAction() {
      if (this.alertTitle === '인증확인') {
        this.$router.push({
          name: `password-reset`,
          params: { email: this.email, token: this.token },
        });
      }
    },
    valiCheck(type, data) {
      if (type === 'account') {
        this.account = data;
      } else if (type === 'email') {
        this.email = data;
      } else if (type === 'certificateCode') {
        this.certificateCode = data;
      }
    },
    sendcertificateCode() {
      const formData = new FormData();
      formData.append('account', this.account);
      formData.append('email', this.email);
      const memberData = formData;
      if (this.sendBtn === '인증번호 발송') {
        sendEmail(memberData)
          .then((res) => {
            if (res.data.isSuccessful) {
              // v CertificateCode 토큰 저장
              this.token = res.data.result;
              // v 인증번호 발송 버튼 재발송으로 변경
              this.sendBtn = '재발송';
              // v 인증확인 버튼 활성화
              this.isSent = true;
              // v 인증번호 3분 카운트다운시작
              this.countDown = true;
              this.countDownt();
              // v 인풋 disabled 처리
              this.accountDisabled = true;
              this.emailDisabled = true;
              // v Toast 띄우기
              this.toastMassage = '인증번호가 전송되었습니다.';
              this.toastDisplay = true;
              setTimeout(() => {
                this.toastDisplay = false;
                this.toastMassage = '';
              }, 1000);
            } else {
              // v Alert 일치 회원 없음
              this.alertTitle = '일치 회원 없음';
              this.alertContent1 = '작성하신 정보로 가입된 회원이 없습니다.';
              this.alertContent2 = '다시 한번 확인해 주세요.';
              this.alertBtn = '확인';
              this.isShowAlert = true;
              // v 아이디,이메일 부분 빨간 라인 처리
              this.accountError = true;
              this.emailError = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        sendEmail(memberData)
          .then((res) => {
            if (res.data.isSuccessful) {
              // v CertificateCode 토큰 저장
              this.token = res.data.result;
              // v 인증번호 발송 버튼 재발송으로 변경
              this.sendBtn = '재발송';
              // v 인증확인 버튼 활성화
              this.isSent = true;
              // v 인증번호 3분 카운트다운 리셋 후 재시작
              this.minute = 3;
              this.second = 0;
              // v 인풋 disabled 처리
              this.accountDisabled = true;
              this.emailDisabled = true;
              // v Toast 띄우기
              this.toastMassage = '인증번호가 전송되었습니다.';
              this.toastDisplay = true;
              setTimeout(() => {
                this.toastDisplay = false;
                this.toastMassage = '';
              }, 1000);
            } else {
              // v Alert 일치 회원 없음
              this.alertTitle = '일치 회원 없음';
              this.alertContent1 = '작성하신 정보로 가입된 회원이 없습니다.';
              this.alertContent2 = '다시 한번 확인해 주세요.';
              this.alertBtn = '확인';
              this.isShowAlert = true;
              // v 아이디,이메일 부분 빨간 라인 처리
              this.accountError = true;
              this.emailError = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    checkCertificateCode() {
      const formData = new FormData();
      formData.append('certificateCode', this.certificateCode);
      const code = formData;
      // console.log("checkCertificateCode",this.token)
      certificateCodeCheck(code, this.token)
        .then((res) => {
          if (res.data.isSuccessful) {
            // Alert : 성공적으로 인증되었습니다.
            this.alertTitle = '인증확인';
            this.alertContent1 = '성공적으로 인증되었습니다.';
            this.alertContent2 = '';
            this.alertBtn = '확인';
            this.isShowAlert = true;
            // 확인버튼클릭시 > password-reset page로 이동
            // 이동시 router parameter로 이메일 전달
          } else {
            // v 인증번호 부분 빨간 라인 처리
            this.certificateCodeError = true;
            if (res.data.msg === '만료된 JWT 입니다.') {
              // Alert : 인증시간초과.
              this.alertTitle = '인증 시간 초과';
              this.alertContent1 = '인증 시간을 초과하였습니다.';
              this.alertContent2 = '재발송 버튼을 눌러 다시 시도하여 주세요.';
              this.alertBtn = '확인';
              this.isShowAlert = true;
            } else if (res.data.msg === '인증번호가 다릅니다.') {
              // Alert : 인증번호불일치.
              this.alertTitle = '인증 번호 불일치';
              this.alertContent1 = '인증 번호가 일치하지 않습니다.';
              this.alertContent2 = '재발송 버튼을 눌러 다시 시도하여 주세요.';
              this.alertBtn = '확인';
              this.isShowAlert = true;
            } else {
              // Alert : 예기치 않은 오류.
              this.alertTitle = '오류';
              this.alertContent1 = '예기치 않은 오류가 발생했습니다.';
              this.alertContent2 = '어플을 다시 시작해주세요.';
              this.alertBtn = '확인';
              this.isShowAlert = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    labelColorChange(item, state) {
      // focus or blur border color change
      if (state === 'focus') {
        if (item.target.name === 'account') {
          this.accountFocus = true;
        } else if (item.target.name === 'email') {
          this.emailFocus = true;
        } else if (item.target.name === 'certificateCode') {
          this.certificateCodeFocus = true;
        }
      } else {
        if (item.target.name === 'account') {
          setTimeout(() => {
            this.accountFocus = false;
          }, 100);
        } else if (item.target.name === 'email') {
          setTimeout(() => {
            this.emailFocus = false;
          }, 100);
        } else if (item.target.name === 'certificateCode') {
          setTimeout(() => {
            this.certificateCodeFocus = false;
          }, 100);
        }
      }
    },
    countDownt() {
      console.log('countDownt');
      const addSecond = setInterval(() => {
        if (this.second === 0) {
          if (this.minute === 0) {
            clearInterval(addSecond);
            this.minute = 3;
            this.second = 0;
          } else {
            this.minute--;
            this.second = 59;
          }
        } else if (this.second <= 60) {
          this.second--;
        }
      }, 1000);
      return addSecond;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.sendBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  min-width: fit-content;
  max-width: fit-content;
  height: fit-content;
  font-size: $fs14;
  letter-spacing: -1.3%;
}
.count {
  color: $black50;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.info {
  text-align: center;
  color: $black50;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
  text-decoration: underline;
  height: 20px;
  margin-top: 143px;
}
.opa0 {
  opacity: 0;
}
.opa1 {
  opacity: 1;
}
</style>
