<template>
  <div v-if="state" class="BG" @click.self="changeDisplay">
    <div class="alertBG">
      <h3 class="fontStyle1">
        <slot name="title"></slot>
      </h3>
      <p class="fontStyle2">
        <slot name="content1"></slot>
      </p>
      <p class="fontStyle2">
        <slot name="content2"></slot>
      </p>
      <div
        class="fontStyle3"
        style="align-self: flex-end"
        @click="confirmAction"
      >
        <slot name="btn"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    state: { type: Boolean, required: true },
  },
  components: {},
  setup(props, { emit }) {
    const menuDisplay = ref(props.state);
    const changeDisplay = () => {
      menuDisplay.value = false;
      emit("changeDisplay", menuDisplay.value);
    };
    const confirmAction = () => {
      changeDisplay();
      emit("confirmAction");
    };
    return {
      changeDisplay,
      confirmAction,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
.fontStyle1 {
  font-size: $fs18;
  font-weight: 600;
  line-height: 22px;
  color: $black100;
  letter-spacing: -2%;
  margin: 0;
  margin-bottom: 9px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 말줄임 적용 */
}
.fontStyle2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 말줄임 적용 */
  font-size: $fs14;
  font-weight: 500;
  line-height: 20.72px;
  color: $black66;
  letter-spacing: -1.3%;
  margin: 0;
}
.fontStyle3 {
  font-size: $fs16;
  font-weight: 600;
  line-height: 19.2px;
  color: $burgundy;
  letter-spacing: -1.3%;
  margin: 0;
  margin-top: 23px;
}
</style>
