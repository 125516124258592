<template>
  <div class="flex_col">
    <label class="picker" for="monthpic">
      <div class="month-picker">
        {{ selectDate.replace('-', '.') }}
      </div>
      <img
        src="@/assets/fillarrow.svg"
        alt=""
        style="width: 16px; height: 16px; margin-left: 2px"
      />
      <input
        id="monthpic"
        type="month"
        v-model="selectDate"
        style="background: none"
        @change="changeDate($event.target.value)"
      />
    </label>
    <!-- 일정이 있을 경우 -->
    <div v-if="scheduleData.length > 0" class="schedule-list">
      <MyScheduleList
        :Scheduledata="scheduleData"
        :isReset="isReset"
        @changeResetState="getResetState"
      ></MyScheduleList>
    </div>
    <!-- 일정이 없을 경우 -->
    <div v-else style="padding-top: 138px">
      <div class="flex_col flex_center" style="width: 100%; padding-top: 177px">
        <img
          src="@/assets/error-not.svg"
          alt=""
          style="width: 32px; height: 32px"
        />
        <div class="errorInfoFont1">등록된 일정이 없습니다.</div>
        <div class="errorInfoFont2">
          상단 추가버튼을 눌러 일정을 추가하세요.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyScheduleList from '@/components/MyScheduleList.vue';
import { getScheduleList } from '@/api/SchedulAPI';

export default {
  name: 'my-schedule',
  components: {
    MyScheduleList,
  },
  props: {},
  data() {
    return {
      isShowPwd: false,
      isReset: false,
      // selectDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? '0'+(new Date().getMonth() + 1) : (new Date().getMonth() + 1)}`,
      scheduleData: [],
    };
  },
  methods: {
    changeDate(date) {
      this.$store.dispatch('updateSelectDate', date);
    },
    getResetState(value) {
      this.isReset = value;
      setTimeout(() => {
        this.isReset = false;
      }, 1000);
    },
    getScheduleLists() {
      // console.log(this.selectDate)
      getScheduleList(this.selectDate)
        .then((res) => {
          if (res.data.isSuccessful) {
            this.scheduleData = res.data.result.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    selectDate() {
      return this.$store.getters.getSelectDate;
    },
    isValid() {
      let value = null;
      if (this.valiPWD && this.valiAccount) {
        value = true;
      } else {
        value = false;
      }
      return value;
    },
  },
  watch: {
    selectDate: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        if (this.selectDate) {
          this.getScheduleLists();
          this.$store.dispatch('updateSelectDate', this.selectDate);
        }
      },
    },
    isReset: {
      //handler: function(바뀐값,바뀌기전 값)
      handler: function () {
        if (this.isReset) {
          this.getScheduleLists();
        }
      },
    },
  },
  created() {
    this.getScheduleLists();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.picker {
  z-index: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 76px;
  padding: 24px 16px;
  background-color: $white100;
  border-bottom: 1px solid $black8;
  position: fixed;
  top: calc(env(safe-area-inset-top) + 58px);
}
.month-picker {
  color: $black100;
  font-size: $fs24;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: -0.013em;
  border: none;
  outline: none;
  width: fit-content;
  background-color: $white100;
}
.flex_col {
  // max-height: calc(
  //   100vh - 58px - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  // );
  // overflow-y: scroll;
  padding: 134px 0 70vh;
}
</style>
