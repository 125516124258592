import Axios from 'axios';
import APIS from '@/api/BaseUrl';

// 로그인
export async function login(loginData) {
  return Axios.post('https://qscfs.bongroup.co.kr/api/app/signIn', loginData);
}
// 로그인 성공시 토큰 localStorage 저장
export function loginSuccess(userData, id, pw) {
  window.localStorage.setItem('token', userData.token);
  window.localStorage.setItem('name', userData.name);
  window.localStorage.setItem('managerType', userData.managerType);
  window.localStorage.setItem('managerId', userData.managerId);
  window.localStorage.setItem('departId', userData.departId);
  window.localStorage.setItem('team', userData.team);
  window.localStorage.setItem('account', id);
  window.localStorage.setItem('pwd', pw);
  // console.log("토큰저장완료")
  Axios.defaults.headers.common['Authorization'] = userData.token;
}
// 비밀번호 찾기 페이지 인증번호 발송 이메일 보내기
export async function sendEmail(memberData) {
  return Axios.post(
    'https://qscfs.bongroup.co.kr/api/app/sendEmail',
    memberData
  );
}
// 인증 확인
export async function certificateCodeCheck(code, token) {
  return Axios.post(
    'https://qscfs.bongroup.co.kr/api/app/checkCertificateCode',
    code,
    {
      headers: {
        CertificateCode: token,
      },
    }
  );
}
// 비밀번호 재설정
export async function resetPassword(password, token) {
  return Axios.post('https://qscfs.bongroup.co.kr/api/app/resetPwd', password, {
    headers: {
      CertificateCode: token,
    },
  });
}
// 비밀번호 변경
export async function changePassword(password) {
  return APIS.post('https://qscfs.bongroup.co.kr/api/app/changePwd', password);
}
// (앱) 로그아웃 - FCM TOKEN DB에서 지우는 목적
export async function logout(loginData) {
  return Axios.post('https://qscfs.bongroup.co.kr/api/app/signOut', loginData, {
    headers: {
      Authorization: window.localStorage.getItem('token'),
    },
  });
}
