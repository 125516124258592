import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { ScrollPicker, ScrollPickerGroup } from "vue-scroll-picker"
import "vue-scroll-picker/dist/style.css"
import store from './stores/store';

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(Calendar);
Vue.use(DatePicker);
Vue.use(ScrollPicker,ScrollPickerGroup);

new Vue({
    el: '#app',
    router,
    render: h => h(App),
    components: {App},
    template: '<App/>',
    store
})
