<template>
  <div v-if="data" class="flex_col" style="width: 100%; padding: 16px">
    <div
      v-for="(feedback, index) in data"
      :key="index"
      class="flex_row"
      style="padding-top: 16px"
    >
      <div class="flex_col" style="width: 100%">
        <div
          class="flex_row flex_between"
          style="align-items: flex-start; width: 100%"
        >
          <h3 class="listTitle">{{ feedback.title }}</h3>
          <div class="storeListDate">{{ feedback.submitAt }}</div>
        </div>
        <div class="fontStyle1">점검자: {{ feedback.managerName }}</div>
        <div class="fontStyle1" style="padding: 2px 0">
          피드백: {{ feedback.feedbackCnt }}건
        </div>
        <div class="fontStyle1 strong">
          처리: {{ feedback.feedbackOfFeedbackCnt }}건
        </div>
        <div
          v-html="trans(feedback.law, feedback.hygiene, feedback.service)"
          class="feedbackDetail"
        ></div>
        <div v-if="data.length - 1 !== index" class="underBorder"></div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="flex_col"
    style="width: 100%; height: 100%; align-items: center"
  >
    <div class="flex_col" style="margin-top: 143px; align-items: center">
      <img src="@/assets/error-not.svg" alt="" style="width: 32px" />
      <div class="errorInfoFont1" style="color: rgba(0, 0, 0, 0.32)">
        등록된 피드백이 없습니다.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Array, require: true },
  },
  created() {
    // console.log("피드백", this.data);
  },
  methods: {
    trans(law, hygiene, service) {
      let datasum = law + hygiene + service;
      let result = datasum.replaceAll("\n", "<br />");
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
.fontStyle1 {
  color: $black100;
  font-size: $fs12;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: -1.3%;
}
.feedbackDetail {
  background-color: $accent2;
  padding: 12px;
  color: $accent1;
  font-size: $fs12;
  font-weight: 500;
  line-height: 15.84px;
  letter-spacing: -1.3%;
  border-radius: 8px;
  margin-top: 12px;
  border: 1px solid $accent2;
}
.underBorder {
  width: 100%;
  border-bottom: 1px solid $black4;
  padding-top: 20px;
  margin-bottom: 4px;
}
.strong {
    color: $burgundy !important;
}
</style>
