<template>
  <div>
    <div
      v-if="storeData"
      class="flex_row_slide"
      style="padding: 0 16px 34px 16px; gap: 16px"
    >
      <div
        v-for="data in storeData"
        :key="data.id"
        class="mainCardBG"
        @click="
          $router.push({
            path: `/store/${data.id}`,
            params: {
              id: data.id,
            },
          })
        "
      >
        <div v-if="data.thum" class="imageArea">
          <img
            :src="data.thum"
            alt=""
            style="width: 100%; height: 150px; border-radius: 8px 8px 0 0"
          />
          <span v-if="isMaster" class="visitDate"
            >{{ data.submitAt }} 방문</span
          >
          <!-- <img
            v-if="data.tel"
            src="@/assets/call.svg"
            alt=""
            style="
              width: 42px;
              height: 42px;
              position: absolute;
              top: 9px;
              right: 10px;
            "
            @click.stop="callAction(data.tel)"
          /> -->
        </div>
        <div v-else class="imageArea noimage">
          <img
            src="@/assets/noimage.svg"
            alt=""
            style="width: 38px; height: 25px"
          />
          <span v-if="isMaster" class="visitDate"
            >{{ data.submitAt }} 방문</span
          >
          <!-- <img
            v-if="data.tel"
            src="@/assets/call.svg"
            alt=""
            style="
              width: 42px;
              height: 42px;
              position: absolute;
              top: 9px;
              right: 10px;
            "
            @click.stop="callAction(data.tel)"
          /> -->
        </div>
        <div class="infoArea">
          <div class="infoPlace">{{ data.name }}</div>
          <div class="infoAddress">{{ data.addr }}</div>
          <div class="flex_row" style="gap: 8px">
            <div class="tag">{{ data.line }}</div>
            <div class="tag">{{ data.team }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <BottomOptionCall
      :display="isCall"
      :number="tel"
      @changeDisplay="getCallDisplay"
    ></BottomOptionCall> -->
  </div>
</template>

<script>
import { getRecentStoreList } from "@/api/MainPageAPI";
import { getStoreLists } from "@/api/StoreAPI";
// import BottomOptionCall from "@/components/global/option/BottomOptionCall.vue";

export default {
  components: {
    // BottomOptionCall,
  },
  data() {
    return {
      listData: [],
      storeData: null,
      isCall: false,
      tel: "",
      isMaster: true,
    };
  },
  computed: {
    params() {
      let parameter = Object.entries({
        curPage: 1,
        keyword: "",
        orderBy: "",
        departId: "",
      })
        .map((e) => e.join("="))
        .join("&");
      return parameter;
    },
  },
  methods: {
    getStoreList() {
      getStoreLists(this.params)
        .then((res) => {
          // console.log("MyStore", res.data.result);
          this.storeData = res.data.result.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    callAction(tel) {
      // console.log(tel);
      this.tel = tel;
      this.isCall = !this.isCall;
    },
    getRecentStore() {
      getRecentStoreList()
        .then((res) => {
          // console.log("RecentStore", res.data.result);
          this.storeData = res.data.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCallDisplay(value) {
      this.isCall = value;
    },
  },
  created() {
    if (localStorage.getItem("managerType") === "팀장") {
      this.isMaster = true;
      this.getRecentStore();
    } else {
      this.isMaster = false;
      this.getStoreList();
    }
  },
  // watch: {
  //   $route() {
  //     this.getRecentStore();
  //   },
  // },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
@import "@/assets/scss/component.scss";
.mainCardBG {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 228px;
  max-width: 228px;
  box-shadow: 0px 9px 17px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.visitDate {
  position: absolute;
  top: 124px;
  left: 16px;
  color: rgba(255, 255, 255, 0.7);
  font-size: $fs12;
  font-weight: 400;
  letter-spacing: -0.013em;
}
.imageArea {
  position: relative;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
  height: 150px;
}
.noimage {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dac8bd;
}
.infoArea {
  box-sizing: border-box;
  padding: 16px;
  .infoPlace {
    color: rgba(0, 0, 0, 0.88);
    font-size: $fs16;
    font-weight: 600;
    line-height: 19.2px;
    letter-spacing: -1.3%;
    margin-bottom: 4px;
  }
  .infoAddress {
    color: $black50;
    font-size: $fs14;
    font-weight: 400;
    line-height: 20.72px;
    letter-spacing: -1.3%;
    margin-bottom: 14px;
  }
  .tag {
    border: 1px solid $black8;
    border-radius: 100px;
    width: fit-content;
    padding: 4px 8px;
    color: $black32;
    font-size: $fs12;
    font-weight: 5500;
    line-height: 14.4px;
    letter-spacing: -1.3%;
  }
}
</style>
