<template>
  <div
    id="scroll"
    class="flex_col scroll"
    style="padding: 16px; padding-bottom: 150px"
    @scroll="scrollHandler()"
  >
    <StoreList :dataList="listData"></StoreList>
  </div>
</template>

<script>
import StoreList from '@/components/store/StoreList.vue';
import { getStoreLists } from '@/api/StoreAPI';
export default {
  name: 'my-store-list',
  components: {
    StoreList,
  },
  props: {},
  data() {
    return {
      listData: [],
      curPage: 1,
      lastPage: 1,
      keyword: '',
      orderBy: '',
      departId: '',
      totalCnt: 0,
      isScrollRoading: false,
    };
  },
  computed: {
    params() {
      let parameter = Object.entries({
        curPage: this.curPage,
        keyword: this.keyword,
        orderBy: this.orderBy,
        departId: this.departId,
      })
        .map((e) => e.join('='))
        .join('&');
      return parameter;
    },
  },
  methods: {
    scrollHandler() {
      const scroller = document.getElementById('scroll');
      const isTotallyScrolled =
        Math.abs(
          scroller.scrollHeight - scroller.clientHeight - scroller.scrollTop
        ) < 1;

      if (
        isTotallyScrolled &&
        !this.isScrollRoading &&
        this.curPage < this.lastPage
      ) {
        this.curPage = this.curPage + 1;
        this.isScrollRoading = true;
        this.getScrollList();
      }
    },
    getStoreList() {
      getStoreLists(this.params)
        .then((res) => {
          // console.log('storeList', res.data.result);
          this.listData = res.data.result.list;
          this.totalCnt = res.data.result.totalCnt;
          this.lastPage = Math.ceil(Number(res.data.result.totalCnt) / 10);
          this.isScrollRoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getScrollList() {
      getStoreLists(this.params)
        .then((res) => {
          // console.log('storeList', res.data.result);
          this.listData.push(...res.data.result.list);
          this.totalCnt = res.data.result.totalCnt;
          this.lastPage = Math.ceil(Number(res.data.result.totalCnt) / 10);
          this.isScrollRoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getStoreList();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
</style>
