<template>
  <div v-if="data.list" class="flex_col">
    <div class="flex_col" style="padding: 24px 16px 0">
      <div class="storeDetailCard">
        <div class="storeDetailCardFont">
          최근 QSC점수가 <span class="strong">{{ data.rank }}</span
          >{{ `(${data.totalScore}점)` }}입니다.
        </div>
        <div class="fontStyle1">
          마지막 점검일 {{ data.submitAt
          }}<span class="strong"> ({{ data.betweenDate }}일 전)</span>
        </div>
      </div>
    </div>
    <div v-if="data.list" class="flex_col" style="padding: 16px">
      <div
        v-for="(qsc, index) in data.list"
        :key="index"
        class="flex_col"
        style="width: 100%; padding-top: 16px"
      >
        <div
          class="flex_row flex_between"
          style="width: 100%; align-items: flex-start"
        >
          <div class="flex_col">
            <h3 class="listTitle">{{ qsc.title }}</h3>
            <ScoreTag :rank="qsc.rank"></ScoreTag>
            <ScoreList :data="qsc"></ScoreList>
            <div class="storeListStyle">점검자: {{ qsc.managerName }}</div>
          </div>
          <div class="storeListDate">{{ qsc.submitAt }}</div>
        </div>
        <div v-if="data.list.length - 1 !== index" class="underBorder"></div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="flex_col"
    style="width: 100%; height: 100%; align-items: center"
  >
    <div class="flex_col" style="margin-top: 143px; align-items: center">
      <img src="@/assets/error-not.svg" alt="" style="width: 32px" />
      <div class="errorInfoFont1" style="color: rgba(0, 0, 0, 0.32)">
        점검내역이 없습니다.
      </div>
    </div>
  </div>
</template>

<script>
import ScoreTag from '@/components/store/ScoreTag.vue';
import ScoreList from '@/components/store/ScoreList.vue';

export default {
  props: {
    data: { type: Object, require: true },
  },
  components: {
    ScoreTag,
    ScoreList,
  },
  created() {
    console.log('qscData', this.data);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.fontStyle1 {
  color: $black50;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.underBorder {
  width: 100%;
  border-bottom: 1px solid $black4;
  padding-top: 20px;
  margin-bottom: 4px;
}
</style>
