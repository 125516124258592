<template>
  <div>
    <div
      v-if="state"
      class="BG"
      @click.self="
        changeDisplay();
        resetData();
      "
    >
      <div class="bottomOptionBG">
        <div class="handle"></div>
        <div class="flex_col">
          <div class="flex_row flex_between" style="margin-bottom: 16px">
            <h3 class="fontStyle1">필터</h3>
            <div class="fontStyle2" @click.stop="resetData">초기화</div>
          </div>
          <div>
            <!-- default period 6 month-->
            <h4 class="fontStyle3" style="margin-bottom: 10px">기간</h4>
            <div class="gridStyle">
              <label
                class="passwordLabelStyle"
                style="border-color: rgba(0, 0, 0, 0.06)"
                @click.stop="dateOnStart"
              >
                <div class="flex_col">
                  <h5 class="passwordInputFont">시작 날짜</h5>
                  <div class="flex_row flex_between" style="position: relative">
                    <div
                      class="dateStyle"
                      style="color: black; min-width: 95px"
                    >
                      {{ filterStartDate }}
                    </div>
                    <img
                      v-if="filterStartDate"
                      src="@/assets/calendar-icon.svg"
                      alt=""
                      style="width: 20px"
                    />
                  </div>
                </div>
              </label>
              <label
                class="passwordLabelStyle"
                for="end"
                style="border-color: rgba(0, 0, 0, 0.06)"
                @click.stop="dateOnEnd"
              >
                <div class="flex_col">
                  <h5 class="passwordInputFont">종료 날짜</h5>
                  <div class="flex_row flex_between" style="position: relative">
                    <div
                      class="dateStyle"
                      style="color: black; min-width: 95px"
                    >
                      {{ filterEndDate }}
                    </div>
                    <img
                      v-if="filterEndDate"
                      src="@/assets/calendar-icon.svg"
                      alt=""
                      style="width: 20px"
                    />
                  </div>
                </div>
              </label>
            </div>
            <h4
              class="fontStyle3"
              style="margin-bottom: 10px; margin-top: 24px"
            >
              점검표 종류
            </h4>
            <div
              class="flex_row"
              style="flex-wrap: wrap; gap: 8px; margin-bottom: 28px"
            >
              <div
                class="tagStyle"
                :class="{
                  tagActive: categoryType.find((v) => v === '본사 점검표'),
                }"
                @click.stop="addFilter('type', '본사 점검표')"
              >
                본사 점검표
              </div>
              <div
                class="tagStyle"
                :class="{
                  tagActive: categoryType.find((v) => v === '지점자가점검표'),
                }"
                @click.stop="addFilter('type', '지점자가점검표')"
              >
                지점자가점검표
              </div>
            </div>
            <h4 class="fontStyle3" style="margin-bottom: 10px">등급별</h4>
            <div
              class="flex_row"
              style="flex-wrap: wrap; gap: 8px; margin-bottom: 28px"
            >
              <div
                class="tagStyle"
                :class="{ tagActive: categoryGrade.find((v) => v === 'S등급') }"
                @click.stop="addFilter('grade', 'S등급')"
              >
                S등급
              </div>
              <div
                class="tagStyle"
                :class="{ tagActive: categoryGrade.find((v) => v === 'A등급') }"
                @click.stop="addFilter('grade', 'A등급')"
              >
                A등급
              </div>
              <div
                class="tagStyle"
                :class="{ tagActive: categoryGrade.find((v) => v === 'B등급') }"
                @click.stop="addFilter('grade', 'B등급')"
              >
                B등급
              </div>
              <div
                class="tagStyle"
                :class="{ tagActive: categoryGrade.find((v) => v === 'C등급') }"
                @click.stop="addFilter('grade', 'C등급')"
              >
                C등급
              </div>
              <div
                class="tagStyle"
                :class="{ tagActive: categoryGrade.find((v) => v === 'D등급') }"
                @click.stop="addFilter('grade', 'D등급')"
              >
                D등급
              </div>
            </div>
          </div>
          <ButtonBasic
            text="적용"
            type="fill"
            :isDisable="false"
            :clickEvent="submitClick"
          ></ButtonBasic>
        </div>
      </div>
    </div>
    <CalenderLib
      v-if="isStartDateView"
      :isDateView="isStartDateView"
      :workAt="filterStartDate"
      @selectedDate="getStartDate"
      @changeDate="getCalendarDisplay('start')"
    ></CalenderLib>
    <CalenderLib
      v-if="isEndDateView"
      :isDateView="isEndDateView"
      :workAt="filterEndDate"
      @selectedDate="getEndDate"
      @changeDate="getCalendarDisplay('end')"
    ></CalenderLib>
  </div>
</template>

<script>
import ButtonBasic from '../inputs/ButtonBasic.vue';
import CalenderLib from '@/components/lib/CalenderLib.vue';

export default {
  props: {
    filterState: { type: Boolean, required: true },
    startDate: { type: String, required: true },
    endDate: { type: String, required: true },
    qscType: { type: Array, required: true },
    qscGrade: { type: Array, required: true },
  },
  components: {
    ButtonBasic,
    CalenderLib,
  },
  data() {
    return {
      state: this.filterState,
      isStartDateView: false,
      isEndDateView: false,
      filterStartDate: '',
      filterEndDate: '',
      categoryType: [],
      categoryGrade: [],
    };
  },
  methods: {
    dateOnStart() {
      this.isStartDateView = true;
      if (this.filterStartDate.length === 0) {
        const today = new Date();
        const date = `${today.getFullYear()}-${
          today.getMonth() + 1 > 10
            ? today.getMonth() + 1
            : '0' + (today.getMonth() + 1)
        }-${today.getDate() > 10 ? today.getDate() : '0' + today.getDate()}`;
        this.filterStartDate = date;
      }
    },
    dateOnEnd() {
      this.isEndDateView = true;
      if (this.filterEndDate.length === 0) {
        const today = new Date();
        const date = `${today.getFullYear()}-${
          today.getMonth() + 1 > 10
            ? today.getMonth() + 1
            : '0' + (today.getMonth() + 1)
        }-${today.getDate() > 10 ? today.getDate() : '0' + today.getDate()}`;
        this.filterEndDate = date;
      }
    },
    addFilter(type, value) {
      if (type === 'type') {
        if (this.categoryType.findIndex((v) => v === value) > -1) {
          this.categoryType.splice(
            this.categoryType.findIndex((v) => v === value),
            1
          );
        } else {
          this.categoryType.push(value);
        }
      } else {
        if (this.categoryGrade.findIndex((v) => v === value) > -1) {
          this.categoryGrade.splice(
            this.categoryGrade.findIndex((v) => v === value),
            1
          );
        } else {
          this.categoryGrade.push(value);
        }
      }
    },
    getStartDate(value) {
      this.filterStartDate = value;
      this.isStartDateView = !this.isStartDateView;
    },
    getEndDate(value) {
      this.filterEndDate = value;
      this.isEndDateView = !this.isEndDateView;
    },
    getCalendarDisplay(type) {
      if (type === 'start') {
        this.isStartDateView = !this.isStartDateView;
      } else {
        this.isEndDateView = !this.isEndDateView;
      }
    },
    changeDisplay() {
      this.state = false;
      this.$emit('changeDisplay', false);
    },
    resetData() {
      this.categoryType = [];
      this.categoryGrade = [];
      this.filterStartDate = '';
      this.filterEndDate = '';
    },
    submitClick() {
      this.$emit(
        'selectFilter',
        this.filterStartDate,
        this.filterEndDate,
        Array(...this.categoryType),
        Array(...this.categoryGrade)
      );
      this.changeDisplay();
    },
  },
  watch: {
    filterState: {
      handler: function () {
        this.state = this.filterState;
        if (this.filterState) {
          this.filterStartDate = this.startDate;
          this.filterEndDate = this.endDate;
          this.categoryType = [...this.qscType];
          this.categoryGrade = [...this.qscGrade];
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.fontStyle1 {
  font-size: $fs20;
  font-weight: 600;
  line-height: 26.4px;
  color: $black100;
  letter-spacing: -1.3%;
  margin: 0;
}
.fontStyle2 {
  font-size: $fs13;
  font-weight: 600;
  line-height: 15.6px;
  color: $accent1;
  letter-spacing: -2%;
  padding: 5px 0;
}
.fontStyle3 {
  font-size: $fs16;
  font-weight: 500;
  line-height: 19px;
  color: $black100;
  letter-spacing: -0.013em;
}
.tagStyle {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 6px 10px;
  color: $black80;
  font-size: $fs14;
  font-weight: 500;
  border-radius: 100px;
  border: 1px solid $black8;
}
.tagActive {
  display: flex;
  align-items: center;
  color: $accent1;
  border: 1px solid $accent2;
  background-color: $accent2;
}
.dateStyle {
  color: $black100;
  font-size: $fs16;
  font-weight: 400;
  line-height: 19.2px;
  letter-spacing: -1.3%;
}
.gridStyle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
}
</style>
