<template>
  <div id="scroll" class="flex_col scroll" @scroll="scrollHandler()">
    <div class="flex_col" style="padding: 18px 16px 0px">
      <div class="relative">
        <input
          class="searchInputStyle"
          type="text"
          placeholder="예) 2022년 개정안"
          :value="keyword"
          @input="updateInput($event.target.value)"
        />
        <img
          src="@/assets/search.svg"
          alt="search"
          style="width: 16px; position: absolute; top: 10px; left: 16px"
        />
        <img
          v-if="keyword.length > 0"
          src="@/assets/remove.svg"
          alt="search"
          style="width: 18px; position: absolute; top: 10px; right: 16px"
          @click="keyword = ''"
        />
      </div>
    </div>
    <!-- 리스트 결과 -->
    <div class="flex_col" style="padding: 0px 16px">
      <div class="flex_row_slide" style="margin-top: 16px">
        <div
          class="tagStyle"
          :class="{ active: category === '전체' }"
          @click="categoryChange('전체')"
        >
          전체
        </div>
        <div
          class="tagStyle"
          :class="{ active: category === '법령' }"
          @click="categoryChange('법령')"
        >
          법령
        </div>
        <div
          class="tagStyle"
          :class="{ active: category === '교육자료' }"
          @click="categoryChange('교육자료')"
        >
          교육자료
        </div>
        <div
          class="tagStyle"
          :class="{ active: category === '위생문서' }"
          @click="categoryChange('위생문서')"
        >
          위생문서
        </div>
        <div
          class="tagStyle"
          :class="{ active: category === '위험식자재' }"
          @click="categoryChange('위험식자재')"
        >
          위험식자재
        </div>
        <div
          class="tagStyle"
          :class="{ active: category === '메뉴얼' }"
          @click="categoryChange('메뉴얼')"
        >
          메뉴얼
        </div>
      </div>
      <div
        class="flex_row flex_between"
        style="margin-top: 16px; margin-bottom: 12px"
      >
        <div class="resultInfoFont">
          총 {{ totalCnt }}개 문서가 조회되었습니다.
        </div>
        <div
          class="flex_row"
          style="align-items: center"
          @click="isArray = !isArray"
        >
          <div class="filterSelectFont">{{ orderBy }}</div>
          <img src="@/assets/arrowdown.svg" alt="" style="width: 16px" />
        </div>
        <BottomOptionList
          :displayState="isArray"
          type="정렬2"
          @changeOption="updateArray"
        ></BottomOptionList>
      </div>
    </div>
    <template v-if="getData.length > 0">
      <!-- 결과 있는 경우 -->
      <div
        v-for="data in getData"
        :key="data.id"
        class="flex_row flex_between wrap"
        @click="
          $router.push({
            path: `/document/${data.id}`,
            params: {
              id: data.id,
            },
          })
        "
      >
        <div class="flex_col" style="width: 100%">
          <div class="flex_row flex_between">
            <div class="fontStyle1">{{ data.title }}</div>
            <img src="@/assets/arrow3.svg" alt="" width="20px" />
          </div>
          <div class="fontStyle2">
            {{ data.updateAt.slice(0, 16) }}{{ data.updateAt.slice(19) }}
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- 결과 없는 경우 -->
      <div class="flex_col" style="margin-top: 143px; align-items: center">
        <img src="@/assets/error-not.svg" alt="" style="width: 32px" />
        <div class="errorInfoFont1">검색결과가 없습니다.</div>
        <div class="errorInfoFont2">
          검색어를 정확하게 입력했는지 확인해주세요.
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BottomOptionList from '@/components/global/option/BottomOptionList.vue';
import { getDocumentList } from '@/api/DocumentAPI';

export default {
  name: 'document-list',
  components: {
    BottomOptionList,
  },
  props: {},
  data() {
    return {
      isArray: false,
      keyword: '',
      curPage: 1,
      lastPage: 1,
      totalCnt: 0,
      getData: [],
      eventDAta: '',
      isScrollRoading: false,
    };
  },
  methods: {
    categoryChange(value) {
      this.$store.dispatch('updateDocCategory', value);
    },
    scrollHandler() {
      const scroller = document.getElementById('scroll');
      const isTotallyScrolled =
        Math.abs(
          scroller.scrollHeight - scroller.clientHeight - scroller.scrollTop
        ) < 1;

      if (
        isTotallyScrolled &&
        !this.isScrollRoading &&
        this.curPage < this.lastPage
      ) {
        this.curPage = this.curPage + 1;
        this.isScrollRoading = true;
        this.getDocListScroll();
      }
    },
    updateArray(value) {
      this.$store.dispatch('updateDocFilter', value);
      this.orderBy = value;
      this.isArray = false;
    },
    updateInput(value) {
      this.keyword = value;
    },
    getDocNewList() {
      getDocumentList(this.params)
        .then((res) => {
          this.getData = res.data.result.list;
          this.totalCnt = res.data.result.totalCnt;
          this.lastPage = Math.ceil(Number(res.data.result.totalCnt) / 10);
          this.isScrollRoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDocListScroll() {
      getDocumentList(this.params)
        .then((res) => {
          this.getData.push(...res.data.result.list);
          this.totalCnt = res.data.result.totalCnt;
          this.lastPage = Math.ceil(Number(res.data.result.totalCnt) / 10);
          this.isScrollRoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    orderBy() {
      return this.$store.getters.getDocFilterData;
    },
    category() {
      return this.$store.getters.getDocCategoryData;
    },
    params() {
      let parameter = Object.entries({
        curPage: this.curPage,
        category: this.category,
        keyword: this.keyword,
        orderBy: this.orderBy,
      })
        .map((e) => e.join('='))
        .join('&');
      return parameter;
    },
  },
  watch: {
    category: {
      handler: function () {
        // console.log("category변경감지",this.category)
        this.curPage = 1;
        this.getDocNewList();
      },
    },
    keyword: {
      handler: function () {
        // console.log("keyword변경감지")
        this.curPage = 1;
        this.getDocNewList();
      },
    },
    orderBy: {
      handler: function () {
        // console.log("orderBy변경감지")
        this.curPage = 1;
        this.getDocNewList();
      },
    },
  },
  created() {
    this.getDocNewList();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.fontStyle1 {
  color: $black100;
  font-size: $fs16;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: -1.3%;
  margin-bottom: 4px;
}
.fontStyle2 {
  color: $black32;
  font-size: $fs10;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -2%;
}
.wrap {
  border-bottom: 1px solid $black8;
  padding: 16px;
}
.tagStyle {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white100;
  min-height: 30px;
  max-height: 30px;
  min-width: fit-content;
  max-width: fit-content;
  padding: 6px 10px;
  color: $black80;
  font-size: $fs14;
  font-weight: 500;
  letter-spacing: -1.3%;
  border-radius: 100px;
  border: 1px solid $black8;
}
.active {
  color: $accent1;
  background-color: $accent2;
  border: 1px solid $accent2;
}
</style>
