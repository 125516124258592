<template>
  <div>
    <div v-if="data && data.length > 0" class="flex_col">
      <div
        v-for="date in data"
        :key="date.id"
        class="flex_col"
        style="padding: 16px"
        @click="
          $router.push({
            path: `/my-schedule/${date.id}`,
            params: {
              id: date.id,
            },
          })
        "
      >
        <div class="flex_row flex_between">
          <div class="flex_row" style="gap: 12px; align-items: center">
            <img
              v-if="date.workTime === ''"
              src="@/assets/ellipse-fill.svg"
              alt=""
              style="width: 8px; height: 8px"
            />
            <img
              v-else
              src="@/assets/ellipse-empty.svg"
              alt=""
              style="width: 8px; height: 8px"
            />
            <h4 class="dateStyle">
              {{ date.workAt }} {{ date.workTime.slice(0, 5) }}
              {{ date.workTime.slice(0, 1) === '0' ? 'AM' : 'PM' }}
            </h4>
          </div>
          <img
            src="@/assets/more-btn-dot.svg"
            alt="more"
            style="width: 20px; height: 20px"
            @click.stop="optionState = `${date.id}`"
          />
        </div>
        <div class="flex_col" style="padding: 5px 0 0 20px">
          <div class="titleStyle">
            {{ date.title }}
          </div>
          <div class="detailStyle">
            {{ date.content }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="errorStyle">향후 1개월 내 등록된 일정이 없습니다.</div>
    <BottomOptionList
      v-if="optionState"
      :displayState="optionState"
      :type="optionState"
      @modiAction="modiAction"
      @deleteAction="deleteAction"
    ></BottomOptionList>
    <CommonAlertTwoBtn
      :state="isShowAlert"
      @changeDisplay="getAlertDisplayState"
      @confirmAction1="confirmAction1"
      @confirmAction2="confirmAction2"
    >
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #content2>
        {{ alertContent2 }}
      </template>
      <template #btn1>
        {{ alertBtn1 }}
      </template>
      <template #btn2>
        {{ alertBtn2 }}
      </template>
    </CommonAlertTwoBtn>
    <ToastMsg :message="toastMassage" :display="toastDisplay"></ToastMsg>
  </div>
</template>

<script>
import { delSchedule } from '@/api/SchedulAPI';
import CommonAlertTwoBtn from '@/components/global/alert/CommonAlertTwoBtn.vue';
import BottomOptionList from '@/components/global/option/BottomOptionList.vue';
import ToastMsg from '@/components/global/alert/ToastMsg.vue';

export default {
  props: {
    data: { type: Array, require: true },
  },
  components: {
    BottomOptionList,
    CommonAlertTwoBtn,
    ToastMsg,
  },
  data() {
    return {
      optionState: null,
      isBottomOption: false,
      isShowAlert: false,
      alertTitle: '일정 삭제',
      alertContent1: '한번 삭제된 일정은 복구하실 수 없습니다.',
      alertContent2: '정말로 삭제하시겠습니까?',
      alertBtn1: '취소',
      alertBtn2: '삭제',
      toastDisplay: false,
      toastMassage: '삭제되었습니다.',
    };
  },
  methods: {
    modiAction() {
      this.$router.replace({
        path: `/my-schedule/edit/${this.optionState}`,
        params: { id: this.optionState },
      });
    },
    deleteAction() {
      this.isShowAlert = true;
    },
    getAlertDisplayState(value) {
      this.isShowAlert = value;
    },
    confirmAction1() {
      this.isShowAlert = false;
    },
    confirmAction2() {
      delSchedule(this.optionState)
        .then((res) => {
          if (res.data.isSuccessful) {
            this.toastDisplay = true;
            this.toastMassage = '삭제되었습니다';
            setTimeout(() => {
              this.toastDisplay = false;
              this.toastMassage = '';
            }, 1000);
          }
          location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    // console.log(this.data);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.mainScheduleList {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
}
.titleStyle {
  color: $black80;
  font-size: $fs16;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: -1.3%;
  margin: 0;
}
.detailStyle {
  color: $black50;
  font-size: $fs14;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -1.3%;
  margin: 0;
  margin-top: 4px;
}
.dateStyle {
  color: $accent1;
  font-size: $fs14;
  font-weight: 500;
  letter-spacing: -0.013em;
  margin: 0;
}
.errorStyle {
  color: $black16;
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  padding-left: 16px;
}
</style>
