var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('div',{staticClass:"flex_col",staticStyle:{"width":"100%","gap":"12px"}},_vm._l((_vm.data),function(value){return _c('div',{key:value.id,staticClass:"flex_col listCard",on:{"click":function($event){return _vm.$router.push({
        path: `/qsc/detail/${value.id}`,
        params: {
          id: value.id,
        },
      })}}},[_c('div',{staticClass:"flex_row flex_between"},[_c('div',{staticClass:"flex_row"},[_c('div',{staticClass:"flex_col baseTagRound resize",class:`score${value.rank.slice(0, 1)}`},[_vm._v(" "+_vm._s(value.rank.slice(0, 1))+" ")]),_c('div',{staticClass:"flex_col"},[_c('div',{staticClass:"dayFont"},[_vm._v(" "+_vm._s(value.reportType)+" ")]),_c('div',{staticClass:"personFont"},[_vm._v(" "+_vm._s(value.templateTitle)+" "),_c('span',{staticClass:"gray"},[_vm._v("|")]),_vm._v(" "+_vm._s(value.managerName)+" ")])])]),_c('img',{staticStyle:{"width":"20px"},attrs:{"src":require("@/assets/arrow3.svg"),"alt":""}})]),_c('table',{staticClass:"table"},[_vm._m(0,true),_c('tr',[_c('td',{staticClass:"border"},[_vm._v(_vm._s(value.law_score))]),_c('td',{staticClass:"border"},[_vm._v(_vm._s(value.hygiene_score))]),_c('td',{staticClass:"border"},[_vm._v(_vm._s(value.service_score))])])]),_c('ScoreList2',{attrs:{"data":value}})],1)}),0):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"border bg"},[_vm._v("중점 관리 사항")]),_c('td',{staticClass:"border bg"},[_vm._v("일반관리")]),_c('td',{staticClass:"border bg"},[_vm._v("문서관리")])])
}]

export { render, staticRenderFns }