<template>
  <div class="flex_col scroll" id="envTop">
    <div style="padding: 16px">
      <div style="padding-bottom: 24px">
        <BottomDropDown
          type="카테고리를"
          name="certificate"
          :value="category"
          @changeSelectValue="getSelectValue"
        ></BottomDropDown>
      </div>
      <BasicInput
        title="제목"
        placeholder="예) 2022년 11월 본그룹본사점 영양사식품위생교육"
        :propValue="title"
        @changeInputValue="getTitle"
      ></BasicInput>
      <BasicInput
        title="교육이수자"
        placeholder="예) 김본죽"
        :propValue="student"
        @changeInputValue="getTitle"
      ></BasicInput>
      <div class="titleFont">수료일</div>
      <div class="relative" @click="dateOn">
        <div class="basicInputStyle">
          <div class="completeAt">{{ completeAt }}</div>
          <img
            src="@/assets/calendar-icon.svg"
            alt="date picker"
            class="calenderIcon"
          />
        </div>
      </div>
      <CalenderLib
        v-if="isDateView"
        :isDateView="isDateView"
        :workAt="completeAt"
        @selectedDate="getSelectedDate"
        @changeDate="getCalendarDisplay"
      ></CalenderLib>
      <div class="flex_col">
        <div class="flex_row flex_between" style="margin-bottom: 13px">
          <div class="flex_col">
            <input
              v-if="isNotPackagingAndroidApp"
              type="file"
              id="dueComLoad"
              accept="image/*;camera"
              multiple
              @change="onFileEvent($event)"
            />
            <div class="titleFont">
              파일 업로드 <span class="burgundy">{{ fileList.length }}/5</span>
            </div>
            <div class="fontStyle1">500mb이하만 올릴 수 있습니다.</div>
          </div>
          <label class="loadBtn" for="dueComLoad" @click="uploadFile">
            <img
              src="@/assets/addfile.svg"
              alt="upload"
              style="width: 18px; margin-right: 8px"
            />
            파일찾기
          </label>
        </div>
        <template v-if="fileList.length > 0">
          <template v-if="$route.name === 'certificate-edit-page'">
            <div
              v-for="(file, index) in fileList"
              :key="file.name ? file.name : file.fileName"
              class="basicInputStyle"
              style="
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
              "
            >
              <div class="ellipsis">
                {{ file.name ? file.name : file.fileName }}
              </div>
              <div class="flex_row" style="gap: 8px">
                <a
                  v-if="$route.name === 'certificate-edit-page'"
                  :href="file.fileLink"
                >
                  <img
                    src="@/assets/load.svg"
                    alt="download"
                    style="width: 16px"
                  />
                </a>
                <img
                  src="@/assets/trash.svg"
                  alt="del"
                  style="width: 16px; padding-left: 16px"
                  @click="delFile(index, file)"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-for="(file, index) in fileList"
              :key="file.name"
              class="basicInputStyle"
              style="
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
              "
            >
              <div class="ellipsis">{{ file.name }}</div>
              <div class="flex_row" style="gap: 8px">
                <a
                  v-if="$route.name === 'certificate-edit-page'"
                  :href="file.fileLink"
                >
                  <img
                    src="@/assets/load.svg"
                    alt="download"
                    style="width: 16px"
                  />
                </a>
                <img
                  src="@/assets/trash.svg"
                  alt="del"
                  style="width: 16px; padding-left: 16px"
                  @click="delFile(index)"
                />
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <button
      class="headerTextBtn saveBtn"
      @click="saveEdu"
      :style="`top:${envTopSize}px`"
    >
      {{ btnText }}
    </button>
    <div v-if="isShowDelBtn" class="bottomOptBtn" @click="isShowAlert = true">
      삭제하기
    </div>
    <CommonAlert
      :state="isShowAlert"
      @changeDisplay="getAlertDisplayState"
      @confirmAction="confirmAction"
    >
      <template #title>
        {{ alertTitle }}
      </template>
      <template #content1>
        {{ alertContent1 }}
      </template>
      <template #content2>
        {{ alertContent2 }}
      </template>
      <template #btn>
        {{ alertBtn }}
      </template>
    </CommonAlert>
    <ToastMsg :message="toastMassage" :display="toastDisplay"></ToastMsg>
  </div>
</template>

<script>
import {
  postCertificate,
  putCertificate,
  getCertificateDetail,
} from '@/api/EducationAPI';
import BottomDropDown from '@/components/global/option/BottomDropDown.vue';
import BasicInput from '@/components/global/inputs/BasicInput.vue';
import CommonAlert from '@/components/global/alert/CommonAlert.vue';
import ToastMsg from '@/components/global/alert/ToastMsg.vue';
import CalenderLib from '@/components/lib/CalenderLib.vue';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType } from '@capacitor/camera';

export default {
  name: 'certificate-add-page',
  components: {
    BottomDropDown,
    BasicInput,
    CommonAlert,
    ToastMsg,
    CalenderLib,
  },

  data() {
    return {
      envTopSize: 0,
      id: null,
      isShowDelBtn: false,
      btnText: false,
      isArray: false,
      categoryId: null,
      category: '',
      title: '',
      student: '',
      completeAt: '',
      fileList: [],
      newFileList: [],
      deleteFile: [],
      emptyList: [],
      isDateView: false,
      isShowAlert: false,
      alertTitle: '확인 요청',
      alertContent1: '누락된 필수 항목이 있습니다.',
      alertContent2: '',
      alertBtn: '확인',
      toastDisplay: false,
      toastMassage: '',
    };
  },
  methods: {
    dateOn() {
      this.isDateView = true;
      if (this.completeAt.length === 0) {
        const today = new Date();
        const date = `${today.getFullYear()}-${
          today.getMonth() + 1 > 10
            ? today.getMonth() + 1
            : '0' + (today.getMonth() + 1)
        }-${today.getDate() > 10 ? today.getDate() : '0' + today.getDate()}`;
        // console.log(date);
        this.completeAt = date;
      }
    },
    onFileEvent(e) {
      let arr = [...this.fileList];
      let newfile = [...this.newFileList];
      let fileData = e.target.files;
      for (var i = 0; i < fileData.length; i++) {
        arr.push(fileData[i]);
        newfile.push(fileData[i]);
      }
      this.fileList = arr;
      this.newFileList = newfile;
      // console.log(this.fileList);
    },
    delFile(index, file) {
      if (this.$route.name === 'certificate-edit-page') {
        let del = [...this.deleteFile];
        del.push(file.fileLink);
        this.deleteFile = del;
        // console.log("삭제",this.deleteFile)
      }
      let prev = this.fileList;
      prev.splice(index, 1);
      this.fileList = prev;
    },
    getSelectedDate(value) {
      if (value) {
        if (new Date(value).getTime() > new Date().getTime()) {
          this.toastMassage = '미래의 날짜는 선택할 수 없습니다.';
          this.toastDisplay = true;
          setTimeout(() => {
            this.toastDisplay = false;
            this.toastMassage = '';
          }, 1000);
        } else {
          this.completeAt = value;
        }
      }
    },
    getCalendarDisplay() {
      this.isDateView = !this.isDateView;
    },
    getTitle(value, type) {
      // console.log(value, type);
      if (type === '제목') {
        this.title = value;
      } else if (type === '교육이수자') {
        this.student = value;
      }
      // console.log(this.title)
      // console.log(this.student)
    },
    getSelectValue(value) {
      // console.log(value);
      if (value === '영양사식품위생교육') {
        this.categoryId = 6;
      } else if (value === '조리사위생교육') {
        this.categoryId = 7;
      } else if (value === '신규/기존영업자위생교육') {
        this.categoryId = 8;
      }
    },
    getAlertDisplayState(value) {
      this.isShowAlert = value;
    },
    confirmAction() {
      this.isShowAlert = false;
    },
    saveEdu() {
      if (this.vailCheck()) {
        const formData = new FormData();
        formData.append('categoryId', this.categoryId);
        formData.append('title', this.title);
        formData.append('student', this.student);
        formData.append('completeAt', this.completeAt);
        if (this.$route.name === 'certificate-edit-page') {
          for (var f = 0; f < this.newFileList.length; f++) {
            formData.append('fileList', this.newFileList[f]);
          }
        } else {
          for (var i = 0; i < this.fileList.length; i++) {
            formData.append('fileList', this.fileList[i]);
          }
        }
        if (this.$route.name === 'certificate-edit-page') {
          if (this.deleteFile.length > 0) {
            for (var d = 0; d < this.deleteFile.length; d++) {
              formData.append('deleteFileList', this.deleteFile[d]);
            }
          } else {
            formData.append('deleteFileList', '');
          }
        }
        const EduData = formData;
        // for (let key of formData.keys()) {
        //   console.log(key, ":", formData.get(key));
        // }
        if (this.$route.name === 'certificate-add-page') {
          // 등록
          postCertificate(EduData)
            .then((res) => {
              if (res.data.isSuccessful) {
                this.toastDisplay = true;
                this.toastMassage = '수료증이 등록되었습니다';
                setTimeout(() => {
                  this.toastDisplay = false;
                  this.toastMassage = '';
                  this.$router.replace({ name: 'certificate-list' });
                }, 1000);
              } else {
                console.log('에러');
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          // 수정
          putCertificate(this.id, EduData)
            .then((res) => {
              if (res.data.isSuccessful) {
                this.toastDisplay = true;
                this.toastMassage = '수정되었습니다';
                setTimeout(() => {
                  this.toastDisplay = false;
                  this.toastMassage = '';
                  this.$router.replace({ name: 'certificate-list' });
                }, 1000);
              } else {
                console.log('에러');
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        // console.log("valuecheck", this.vailCheck());
        // console.log("emptyList", this.emptyList);
        this.alertContent2 = `-${this.emptyList.toString()}`;
        this.isShowAlert = true;
      }
    },
    vailCheck() {
      // 필수 항목 체크하기
      if (
        !this.categoryId ||
        !this.title ||
        !this.student ||
        !this.completeAt ||
        this.fileList.length === 0
      ) {
        let emptyArray = [];
        if (!this.categoryId) {
          emptyArray.push('카테고리');
        }
        if (!this.title) {
          emptyArray.push('제목');
        }
        if (!this.student) {
          emptyArray.push('교육이수자');
        }
        if (!this.completeAt) {
          emptyArray.push('수료일');
        }
        if (this.fileList.length === 0) {
          emptyArray.push('첨부파일');
        }
        this.emptyList = emptyArray;
        // console.log(this.emptyList);
        return false;
      } else {
        return true;
      }
    },
    getDetail() {
      getCertificateDetail(this.id)
        .then((res) => {
          if (res.data.isSuccessful) {
            // console.log(res.data.result);
            this.category = res.data.result.category;
            this.title = res.data.result.title;
            this.student = res.data.result.student;
            this.completeAt = res.data.result.completeAt;
            this.fileList = [...res.data.result.fileLinks];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async uploadFile() {
      if (!this.isNotPackagingAndroidApp) {
        const image = await Camera.getPhoto({
          quality: 90,
          source: 'PROMPT',
          resultType: CameraResultType.Base64,
          promptLabelHeader: '이미지 불러오기',
          promptLabelPhoto: '갤러리',
          promptLabelPicture: '사진 촬영',
        });

        let arr = [...this.fileList];
        let newfiles = [...this.newFileList];

        let base64 = await fetch(
          `data:image/jpeg;base64,${image.base64String}`
        );
        let blob = await base64.blob();
        let newFile = new File([blob], `${Date.now()}.jpeg`);
        arr.push(newFile);
        newfiles.push(newFile);

        this.fileList = arr;
        this.newFileList = newfiles;
      }
    },
  },
  mounted() {
    this.envTopSize =
      Number(
        getComputedStyle(document.getElementById('envTop'))
          .getPropertyValue('--safe-area-inset-top')
          .replace(/px/g, '')
      ) + 17;
  },
  computed: {
    isNotPackagingAndroidApp() {
      return !(
        Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android'
      );
    },
  },
  created() {
    if (this.$route.name === 'certificate-edit-page') {
      // 수정하기 페이지인 경우
      this.isShowDelBtn = true;
      this.id = this.$route.params.id;
      this.btnText = '수정';
      this.getDetail();
    }
    if (this.$route.name === 'certificate-add-page') {
      // 등록하기 페이지인 경우
      this.btnText = '저장';
    }
  },
  watch: {
    category: {
      handler: function () {
        this.getSelectValue(this.category);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
@import '@/assets/scss/header.scss';
.calenderIcon {
  width: 18px;
  position: absolute;
  top: 12px;
  right: 16px;
}
.titleFont {
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  color: $black70;
  letter-spacing: -1.3%;
  margin-bottom: 8px;
}
.fontStyle1 {
  font-size: $fs10;
  font-weight: 400;
  line-height: 12px;
  color: $black50;
  letter-spacing: -2%;
}
.loadBtn {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-size: $fs13;
  font-weight: 600;
  line-height: 15.6px;
  color: $black100;
  letter-spacing: -2%;
  border: 1px solid $black8;
  border-radius: 6px;
}
.saveBtn {
  position: absolute;
  font-family: 'Pretendard';
  border: none;
  background-color: white;
  text-align: right;
  position: absolute;
  right: 16px;
  &:disabled {
    color: $gray2;
  }
}
.completeAt {
  position: absolute;
  top: 12px;
  left: 16px;
  color: $black100;
  font-size: $fs14;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -1.3%;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 말줄임 적용 */
}

#envTop {
  --safe-area-inset-top: env(safe-area-inset-top);
}
</style>
