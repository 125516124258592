<template>
    <div class="flex_col">
        <div class="titleFont">{{ title }}</div>
        <input class="basicInputStyle" type="text" :placeholder="placeholder" :disabled="disabled" :value="value" @input="changeInput($event.target.value)"/>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        propValue: { type: String, required: false, default:'' },
        title: { type: String, required: true },
        placeholder: { type: String, required: false },
        disabled: { type: Boolean, required: false, default: false },
    },
    components: {
    },
    data(){
      return {
        value: this.propValue,
      }
    },
    methods: {
      changeInput(data){
        this.value = data;
        this.$emit('changeInputValue',this.value,this.title)
      },
    },
    watch: {
      propValue: {
        //handler: function(바뀐값,바뀌기전 값)
        handler: function () {
          this.value = this.propValue
        }
      }
    },
  } 
  </script>
  
  <style lang="scss" scoped>
    @import '@/assets/scss/variable.scss'; 
    @import '@/assets/scss/component.scss'; 
  .titleFont {
    font-size: $fs12;
    font-weight: 500;
    line-height: 14.4px;
    color: $black70;
    letter-spacing: -1.3%;
    margin-bottom: 8px;
  }
  </style>
  