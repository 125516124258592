<template>
  <div
    id="scroll"
    class="flex_col scroll"
    style="width: 100%; padding: 16px; padding-bottom: 150px"
    @scroll="scrollHandler()"
  >
    <div class="flex_row flex_between" style="gap: 14px">
      <div style="width: 100%; position: relative">
        <input
          class="searchInputStyle"
          type="text"
          placeholder="지점명, 지점코드, 점장 이름"
          :value="keyword"
          @input="updateInput($event.target.value)"
        />
        <img
          src="@/assets/search.svg"
          alt="search"
          style="width: 16px; position: absolute; top: 10px; left: 16px"
        />
        <img
          v-if="keyword.length > 0"
          src="@/assets/remove.svg"
          alt="search"
          style="width: 18px; position: absolute; top: 10px; right: 16px"
          @click="keyword = ''"
        />
      </div>
      <img
        src="@/assets/filter.svg"
        alt="filter"
        width="37px"
        height="37px"
        @click="isFilter = !isFilter"
      />
    </div>
    <div
      v-if="selectedFilterData.length > 0"
      class="flex_row_slide"
      style="margin-top: 16px"
    >
      <div
        class="tagStyle"
        v-for="(value, index) in selectedFilterData"
        :key="value.id"
        style="min-height: 33px; max-height: 33px"
      >
        {{ value?.line }} {{ value?.team }}
        <img
          src="@/assets/close5.svg"
          alt=""
          style="width: 16px; margin-left: 4px"
          @click="deleteTag(index)"
        />
      </div>
    </div>
    <div
      class="flex_row flex_between"
      style="margin-top: 20px; margin-bottom: 12px"
    >
      <div class="resultInfoFont">
        총 {{ totalCnt }}개 지점이 조회되었습니다.
      </div>
      <div
        class="flex_row"
        style="align-items: center"
        @click="isArray = !isArray"
      >
        <div class="filterSelectFont">{{ orderBy }}</div>
        <img src="@/assets/arrowdown.svg" alt="" style="width: 16px" />
      </div>
    </div>
    <div
      v-if="listData.length === 0"
      class="flex_col"
      style="width: 100%; height: 100%; align-items: center"
    >
      <div class="flex_col" style="margin-top: 143px; align-items: center">
        <img src="@/assets/error-not.svg" alt="" style="width: 32px" />
        <div class="errorInfoFont1">검색결과가 없습니다.</div>
        <div class="errorInfoFont2">
          검색어를 정확하게 입력했는지 확인해주세요.
        </div>
      </div>
      <div class="infoStyle" style="margin-top: 155px">
        • 담당 지점만 검색결과로 노출됩니다.<br />
        • 담당 지점이 노출되지 않는 경우, 서비스 관리자에게 문의하세요.
      </div>
    </div>
    <div v-else>
      <StoreList :dataList="listData"></StoreList>
    </div>
    <BottomOptionFilter
      :filterState="isFilter"
      :filterList="filterData"
      :selectedFilter="selectedFilterData"
      @changeDisplay="getFilterState"
      @selectFilter="getSelectedFilterData"
    ></BottomOptionFilter>
    <BottomOptionList
      type="정렬"
      :displayState="isArray"
      @changeOption="updateArray"
      @changeDisplay="getArrayState"
    ></BottomOptionList>
  </div>
</template>

<script>
import { getStoreLists, getStoreDepart } from '@/api/StoreAPI';
import StoreList from '@/components/store/StoreList.vue';
import BottomOptionFilter from '@/components/global/option/BottomOptionFilter.vue';
import BottomOptionList from '@/components/global/option/BottomOptionList.vue';

export default {
  name: 'store-list',
  components: {
    StoreList,
    BottomOptionFilter,
    BottomOptionList,
  },
  props: {},
  data() {
    return {
      isArray: false,
      isFilter: false,
      curPage: 1,
      lastPage: 1,
      departId: [],
      keyword: '',
      totalCnt: 0,
      listData: [],
      filterData: [],
      isScrollRoading: false,
    };
  },
  computed: {
    orderBy() {
      return this.$store.getters.getStoreFilterData;
    },
    selectedFilterData() {
      return this.$store.getters.getStoreSelectFilterData;
    },
    params() {
      let parameter = Object.entries({
        curPage: this.curPage,
        keyword: this.keyword,
        orderBy: this.orderBy,
      })
        .map((e) => e.join('='))
        .join('&');
      if (this.departId.length > 0) {
        for (var i = 0; i < this.departId.length; i++) {
          parameter = `${parameter}&departId=${this.departId[i]}`;
        }
        return parameter;
      } else {
        parameter = `${parameter}&departId=`;
        return parameter;
      }
    },
  },
  methods: {
    updateInput(value) {
      this.keyword = value;
    },
    scrollHandler() {
      const scroller = document.getElementById('scroll');
      const isTotallyScrolled =
        Math.abs(
          scroller.scrollHeight - scroller.clientHeight - scroller.scrollTop
        ) < 1;

      if (
        isTotallyScrolled &&
        !this.isScrollRoading &&
        this.curPage < this.lastPage
      ) {
        this.curPage = this.curPage + 1;
        this.isScrollRoading = true;
        this.getScrollList();
      }
    },
    deleteTag(index) {
      this.selectedFilterData.splice(index, 1);
      this.$store.dispatch('updateStoreSelectFilter', [
        ...this.selectedFilterData,
      ]);
      this.departId.splice(index, 1);
      this.getStoreList();
    },
    getSelectedFilterData(value) {
      this.departId = value.map((v) => {
        return v.id;
      });
      this.$store.dispatch('updateStoreSelectFilter', value);
    },
    getFilterState(value) {
      this.isFilter = value;
    },
    getResetState(value) {
      this.isReset = value;
      setTimeout(() => {
        this.isReset = false;
      }, 1000);
    },
    getStoreList() {
      getStoreLists(this.params)
        .then((res) => {
          if (res.data.isSuccessful) {
            // console.log('storeList', res.data.result);
            this.listData = res.data.result.list;
            this.totalCnt = res.data.result.totalCnt;
            this.lastPage = Math.ceil(Number(res.data.result.totalCnt) / 10);
            this.isScrollRoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getScrollList() {
      getStoreLists(this.params)
        .then((res) => {
          if (res.data.isSuccessful) {
            // console.log('storeList', res.data.result);
            this.listData.push(...res.data.result.list);
            this.totalCnt = res.data.result.totalCnt;
            this.lastPage = Math.ceil(Number(res.data.result.totalCnt) / 10);
            this.isScrollRoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFilterList() {
      getStoreDepart()
        .then((res) => {
          if (res.data.isSuccessful) {
            // console.log('필터소속리스트', res.data.result);
            this.filterData = res.data.result;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getArrayState(value) {
      this.isArray = value;
    },
    updateArray(value) {
      this.$store.dispatch('updateStoreFilter', value);
      this.orderBy = value;
      this.isArray = false;
    },
    selectedFilter() {
      let arr = [];
      let data = [...this.filterData];
      for (var i = 0; i < this.departId.length; i++) {
        arr.push(
          data.find((v) => {
            return v.id === this.departId[i];
          })
        );
      }
      // this.selectedFilterData = arr
      this.$store.dispatch('updateStoreSelectFilter', [...arr]);
    },
  },
  created() {
    this.getStoreList();
    this.getFilterList();
    if (this.selectedFilterData) {
      this.departId = [
        ...this.selectedFilterData.map((v) => {
          return v.id;
        }),
      ];
    }
  },
  watch: {
    orderBy: {
      handler: function () {
        this.curPage = 1;
        this.getStoreList();
      },
    },
    departId: {
      handler: function () {
        this.curPage = 1;
        this.getStoreList();
      },
    },
    keyword: {
      handler: function () {
        this.curPage = 1;
        this.getStoreList();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/component.scss';
.fontStyle2 {
  color: $black28;
  font-size: $fs12;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -1.3%;
  margin-top: 8px;
}
.infoStyle {
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 8px 16px 8px 10px;
  color: $black100;
  font-size: $fs10;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -2%;
  margin-top: auto;
  margin-bottom: 60px;
}
.tagStyle {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-width: fit-content;
  height: fit-content;
  padding: 6px 10px;
  color: $accent1;
  font-size: $fs14;
  font-weight: 500;
  border-radius: 100px;
  border: none;
  background-color: $accent2;
}
</style>
