<template>
  <div id="app">
    <Rview />
  </div>
</template>

<script>
import Rview from "@/components/routerView/Index.vue";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";

export default {
  name: "App",
  components: {
    Rview,
  },
  mounted() {
    if (Capacitor.isNativePlatform()) {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === "granted") {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      PushNotifications.addListener("registration", (token) => {
        // console.log('Push registration success, token: ' + token.value);
        window.localStorage.setItem("fcmToken", token.value);
      });

      PushNotifications.addListener("registrationError", (error) => {
        console.error("Error on registration: " + JSON.stringify(error));
      });
    }
  },
};
</script>
<style>
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css");
body,
html {
  box-sizing: border-box;
  position: relative;
  /* width: 100vw;
  max-width: 480px; */
  margin: 0;
  margin: auto;
}
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
button a,
ul,
li,
fieldset,
form,
label,
legend,
textarea,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
footer,
header,
nav,
section {
  margin: 0;
  font-family: "Pretendard";
}
#app {
  box-sizing: border-box;
  position: relative;
  /* width: 100vw;
  max-width: 480px; */
  margin: 0;
  margin: auto;
}
</style>
