import API from "@/api/BaseUrl";

// 본러닝 목록 조회
export async function getLearningList(rqListDate) {
    return API.get(`learning?${rqListDate}`)
}
// 본러닝 상세 조회
export async function getLearningDetail(id) {
    return API.get(`learning/${id}`)
}
// 본러닝 등록
export async function postLearning(learningData) {
    return API.post(`learning`,learningData)
}
// 본러닝 수정
export async function putLearning(id,learningData) {
    return API.put(`learning/${id}`,learningData)
}
// 본러닝 삭제
export async function delLearning(id) {
    return API.delete(`learning/${id}`)
}


// 다운
export async function downFile(link) {
    return API.get(`down?url=${link}`)
}





// 수료증 목록 조회
export async function getCertificateList(rqDate) {
    return API.get(`learningCom?${rqDate}`)
}
// 수료증 상세 조회
export async function getCertificateDetail(id) {
    return API.get(`learningCom/${id}`)
}
// 수료증 등록
export async function postCertificate(certificateData) {
    return API.post(`learningCom`,certificateData)
}
// 수료증 수정
export async function putCertificate(id,certificateData) {
    return API.put(`learningCom/${id}`,certificateData)
}
// 수료증 삭제
export async function delCertificate(id) {
    return API.delete(`learningCom/${id}`)
}