import API from "@/api/BaseUrl";

// 소속 조회 (점장/팀장) 점검intro부분에서 사용
export async function getDepart() {
    return API.get(`checking/depart`)
}
// 지점 조회 (점장/팀장) 점검intro부분에서 사용
export async function getStores(departId) {
    // 팀장일 경우 '소속조회 API'에서 선택한 id 값, 점장의 경우 0값
    return API.get(`checking/store?departId=${departId}`)
}
// 점검표 템플릿 조회 (점장/팀장) 점검intro부분에서 사용
export async function getInspectionTemplate() {
    return API.get(`checking/template`)
}
// 새 점검표 불러오기 (점장/팀장) 점검표 문항 불러올 때 사용
export async function getInspectionQAList(inspectionInfo) {
    // '지점 조회' 에서 선택한 id
    // '점검표(템플릿) 조회' 에서 선택한 id
    return API.get(`checking/report?${inspectionInfo}`)
}


// 이미지 등록시 주소 리턴 (점장/팀장) 모든 이미지 등록시 사용 하여 받은 링크로 보낼것.
export async function getImageLink(file) {
    return API.post(`img`,file)
}
// 점검 점수내기 (점장/팀장) 점검표에 대한 점검 점수 받을 수 있음.
export async function getScore(formData) {
    return API.post(`checking/score`,formData)
}
// 점검표 등록 (점장/팀장)
export async function addInspectionQA(qaData) {
    return API.post(`checking`,qaData)
}



// QSC 점검 목록 (점장/팀장)
export async function getInspectionList(params) {
    return API.get(`checking?${params}`)
}
// QSC 점검 상세결과 (점장/팀장)
export async function getInspectionResultDetail(id) {
    return API.get(`checking/${id}`)
}
// QSC 점검 삭제 (점장/팀장)
export async function deleteInspection(id) {
    return API.delete(`checking/${id}`)
}
// QSC 점검 수정 (점장/팀장)
export async function modiInspection(id,qaData) {
    return API.put(`checking/${id}`,qaData)
}
// 대시보드 (점장/팀장)
export async function getDashboard() {
    return API.get(`checking/dash`)
}




// 지점 리스트 조회 (점장/팀장)
export async function getStoreLists(params) {
    return API.get(`store?${params}`)
}
// 지점 상세 조회 (점장/팀장)
export async function getStoreDetail(id) {
    return API.get(`store/${id}`)
}
// 소속 조회 (필터시 사용)
export async function getStoreDepart() {
    return API.get(`depart`)
}