<template>
    <div v-if="menuDisplay" class="BG" @click="changeDisplay">
        <div class="alertBG">
            <h3 class="fontStyle1">
              <slot name="title"></slot>
            </h3>
            <p class="fontStyle2">
              <slot name="content1"></slot>
            </p>
            <p class="fontStyle2">
              <slot name="content2"></slot>
            </p>
            <div class="flex_row" style="align-self:flex-end;gap:12px">
              <div class="fontStyle3 black" @click.self="confirmAction1">
                <slot name="btn1"></slot>
              </div>
              <div class="fontStyle3" @click.self="confirmAction2">
                <slot name="btn2"></slot>
              </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      state: { type: Boolean, required: true }
    },
    data() {
      return {
        menuDisplay : this.state,
      }
    },
    methods: {
      changeDisplay() {
        // 닫기
        this.menuDisplay = false
        this.$emit('changeDisplay', false)
      },
      confirmAction1() {
        this.changeDisplay()
        this.$emit('confirmAction1')
      },
      confirmAction2() {
        this.changeDisplay()
        this.$emit('confirmAction2')
      }
    },
    watch: {
      state: {
        //handler: function(바뀐값,바뀌기전 값)
        handler: function () {
          this.menuDisplay = this.state
        }
      }
    }
  } 
  </script>
  
  <style lang="scss" scoped>
    @import '@/assets/scss/variable.scss'; 
    @import '@/assets/scss/component.scss'; 
  .fontStyle1 {
    font-size: $fs18;
    font-weight: 600;
    line-height: 22px;
    color: $black100;
    letter-spacing: -2%;
    margin: 0;
    margin-bottom: 9px;
  }
  .fontStyle2 {
    font-size: $fs14;
    font-weight: 500;
    line-height: 20.72px;
    color: $black66;
    letter-spacing: -1.3%;
    margin: 0;
  }
  .fontStyle3 {
    font-size: $fs16;
    font-weight: 600;
    line-height: 19.2px;
    color: $burgundy;
    letter-spacing: -1.3%;
    margin: 0;
    margin-top: 23px;
  }
  .black {
    color: $black100;
  }
  </style>
  