<template>
  <div id="page">
    <DefaultHeader />
    <Rview />
  </div>
</template>

<script>
import DefaultHeader from "@/components/global/DefaultHeader";
import Rview from "@/components/routerView/Index";

export default {
  name: "DefaultLayout",
  components: { Rview, DefaultHeader },
};
</script>
<style scoped>
#page {
  display: flex;
  flex-direction: column;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  /* overflow-y: hidden; */
}
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  #page {
    height: -webkit-fill-available;
    max-height: -webkit-fill-available;
  }
}
@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  #page {
    height: 100vh;
    max-height: calc(100vh);
    box-sizing: border-box;
  }
}
</style>
